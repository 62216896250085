import React, { createContext, useReducer, useEffect, useContext, useState, useCallback } from 'react';
import LoadingSpinner from './LoadingSpinner'; // Import the Spinner component
import PropTypes from 'prop-types';
import { queryDatasets, getOrganizationModelDate } from './ChatDatasets';

const ChatContext = createContext();

export const useChat = () => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};

const chatReducer = (state, action) => {
    switch (action.type) {
        case 'addMessage':
            return {
                ...state,
                messages: [...state.messages, action.payload]
            };
        default: 
            return state;
    }
}

// if (list) {
//     // Handle list queries
// } else if (page || event || outcome || PageFrom || PageTo) {
//     // Handle page/event/outcome queries
// }
// // Avoid processing if the message doesn't match any expected format

const list = (dataset, key) => {
    const items = Object.keys(dataset);
  
    if (items.length > 0) {
        return {
            data: items.join(", "),
            status: 'success'
        };
    } else {
        return {
            data: `No ${key} found`,
            status: 'failure'
        };
    }
};

function isValidQuery(query) {
    // Check if the query is not null and has at least one defined property relevant to your application
    if (!query) return false;

    // Check for at least one valid property - adjust these based on your application's requirements
    const queryKeys = ['page', 'list', 'event', 'outcome', 'PageFrom']; // Add other relevant keys
    return queryKeys.some(key => query[key] !== undefined && query[key] !== null);
}


export const ChatProvider = ({ children, datasets, error}) => {
    const initialState = { messages: [] };
    const [state, dispatch] = useReducer(chatReducer, initialState);
    const [pendingMessage, setPendingMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const processMessage = useCallback((message) => {

        if (!message || message.sender === 'bot' || !isValidQuery(message)) {
            // Ignore processing if no message, if it's a bot message, or query is invalid
            console.error("Invalid or empty query.");
            sendMessage({
                text: "Please enter a valid query.",
                sender: 'bot',
                type: 'error'
            });
            return;
        }

        if (!message || message.sender === 'bot') {
            // Ignore processing if no message or if it's a bot message
            return;
        }
        setIsLoading(true); // Set isLoading to true before processing

        
        const response = queryDatasets(message, datasets);
        const { results, logs } = response || {};
        setIsLoading(false); // Set isLoading to false after processing

        console.log("ProcessMessage Start Results:", results);
    
        if (results && Object.keys(results).length > 0) {
            const resultEntries = Object.entries(results).filter(([key, value]) => value.status === 'success');
            let index = 0;
    
            const sendMessageWithDelay = () => {
                if (index < resultEntries.length) {
                    const [key, value] = resultEntries[index];
                    console.log('Sending to formatResult:', key, value);
                    const formattedText = formatResult(key, value);
                    dispatch({ type: 'addMessage', payload: { text: formattedText, sender: 'bot' } });
                    index++;
                    setTimeout(sendMessageWithDelay, 600); // Delay of 600 ms
                }
            };
    
            sendMessageWithDelay();
        } else {
            console.error("Invalid or empty results from queryDatasets");
        }
        console.log("ProcessMessage logs:", logs);
        console.log("ProcessMessage Results:", results);
    }, [datasets]); // Add other dependencies as needed

    useEffect(() => {
        if (!isLoading && datasets && pendingMessage) {
            processMessage(pendingMessage);
            setPendingMessage(null);
        }
    }, [datasets, isLoading, pendingMessage, processMessage]);
    
    
    const formatResult = (key, value) => {
        // Custom headers and explanations for each key
        console.log('formatResult received:', key, value);
        const headlines = {
            UniqueURL: "Traffic Overview",
            UniqueEventDesc: "Event Mapping",
            MarkovChain: "Journey Forecast",
            FrequentPatterns: "Behavioral Patterns",
            FPGrowth: "Pattern Map", //  "Behavioral Association Mapping","User Behavior Blueprints",
            RandomForest: "Predictive Importance"
        };
        
        const modelDate = getOrganizationModelDate();
    
        const explanations = {
            UniqueURL: `User engagement to see traction of unique pages since ${modelDate}.`,
            UniqueEventDesc: "A comprehensive view of how users interact with elements on a page, revealing engagement hotspots.",
            MarkovChain: "Predict your user's next page - analyze user navigation through the product.",
            FrequentPatterns: "Identify frequently occurring activities to understand user habits.",
            FPGrowth: "Uncover customer use cases that patterns of user behaviors reveal.",
            RandomForest: "Analyze the attributes that significantly influence user choices."
        };

        const modelTooltip = {
            UniqueURL: "Unique Page visits summarized for all users included in the dataset.",
            UniqueEventDesc: "To reveal engagement hotspots, this is a count of how many users interact with elements of a page",
            MarkovChain: "<b>Probability:</b> Indicates the likelihood of transitioning from one page to another based on user behavior. Each percentage value represents the observed frequency of a user moving from a specific page to the next, providing insights into common navigation paths.",
            FrequentPatterns: "<b>Itemset Frequency:</b> Identifies sets of pages or events that frequently occur together within user sessions. This metric helps in understanding common combinations of user actions, revealing patterns that could inform website layout optimizations or feature enhancements.",
            FPGrowth: "<b>Antecedent Support:</b> Measures the prevalence of the initiating action (e.g., visiting /utm-builder/signup) within all user sessions. <br/><br/><b>Consequent Support:</b> Indicates how often the resulting action (like visiting /connections) occurs in the data. <br/><br/><b>Confidence:</b> Assesses the likelihood of the consequent happening given the antecedent, serving as a strong indicator of user behavior patterns. <br/><br/><b>Support:</b> Reflects the frequency of the antecedent and consequent occurring together, highlighting significant user journey correlations.<br/><br/><b>Conviction, Lift, Leverage, Zhang's Metric:</b> Advanced metrics providing deeper insights into the strength, directionality, and reliability of the identified patterns, crucial for strategic planning and prioritization.",
            RandomForest: "<b>Feature Importance:</b> Evaluates the relative significance of different features (like specific pages or user actions) in the prediction model. Higher importance scores suggest a greater impact of the feature on the model's decision-making process, guiding focused improvements and optimizations.",
        }
    
    //     let initialContent = '';
    //     let remainingContent = '';
    //     let messageContent = '';

    //     if (value) {
    //         // Check if initialData is present
    //         if (value.initialData) {
    //             // Join initialData with proper HTML structure
    //             initialContent = `<div class="initial-data">${value.initialData.join('')}</div>`;
    //         } else if (value.data) {
    //             // Fallback for any other type of data
    //             initialContent = `<p>${String(value.data)}</p>`;
    //         }
    
    //         // Check if remainingData is present
    //         if (value.remainingData) {
    //             // Join remainingData with proper HTML structure and initially hide it
    //             remainingContent = `<div class="remaining-data hidden">${value.remainingData.join('')}</div>`;
    //         }    
    //     }

    //     const tooltipHTML = `
    //         <div className="conversation-tooltip">
    //             <h3 className="ChatWithModel">${headlines[key] || key}</h3>
    //             <p className="model-explanation">${explanations[key] || ""}</p>
    //             <div className="conversation-tooltip-container">
    //                 <img src="/assets/icons/icon-info.svg" className='iconInfo-sm' alt='More Information'>
    //                 <span className="conversation-tooltip-text">${modelTooltip[key] || ""}</span>
    //             </div>
    //             ${initialContent}
    //             ${remainingContent}
    //             ${remainingContent ? '<button class="load-more" onclick="showMore(this)">+ More</button>' : ''}
    //         </div>
    //     `;

    //     return { text: tooltipHTML, type: key, sender: 'bot' };
    // };
    
    let initialContent = [];
    let remainingContent = value && value.remainingData ? value.remainingData : [];
    let hasMore = remainingContent.length > 0;

    console.log('ChatContext Key object:', key);
    console.log('ChatContext Value object:', value);

    if (value) {
        if (value.initialData) {
        // Use initialData if present
        initialContent = value.initialData;
    } else if (value.data) {
        // Use data if present
        initialContent = [value.data];
    } else if (typeof value === 'string') {
        // Handle the case where value is just a string
        initialContent = [value];
    }
} else {
        // Default message if no data is available
        initialContent = ['Value is not set'];
    }

    return {
        type: key,
        sender: 'bot',
        content: {
            initialContent,
            remainingContent,
            hasMore
        },
        tooltip: {
            headline: headlines[key] || key,
            explanation: explanations[key] || "",
            modelTooltip: modelTooltip[key] || ""
        }
    };
};

    const formatObject = (obj) => {
        return Object.entries(obj).map(([objKey, objValue]) => {
            return `<div>${objKey}: ${objValue}</div>`;
        }).join('');
    };

    
    const sendMessage = useCallback((message, key) => {
        console.log("sendMessage called with:", message);
    
        if (message.sender === 'user') {
            const userMessage = constructUserMessage(message);
            console.log("Formatted user message:", userMessage);
            dispatch({ type: 'addMessage', payload: { text: userMessage, sender: 'user' } });
            if (!isLoading && datasets) {
                processMessage(message);
            }
        } else if (message.sender === 'bot') {
            let formattedMessage;
            if (message.type) {
                formattedMessage = formatResult(message.type, message);
            } else {
                formattedMessage = { ...message };
            }
            console.log("Formatted bot message:", formattedMessage);
            dispatch({ type: 'addMessage', payload: formattedMessage });
        }
    }, [dispatch, isLoading, datasets, processMessage]);
    
    
    const constructUserMessage = (message) => {
        const { page, event, outcome, PageFrom, PageTo } = message;
        let messageParts = [];
    
        // Construct message based on the existence of each part
        if (PageFrom && PageTo) {
            messageParts.push(`from ${PageFrom} page to ${PageTo} page`);
        } else if (page) {
            messageParts.push(`about ${page} page`);
        }
        if (event) {
            messageParts.push(`with ${event} event`);
        }
        if (outcome) {
            messageParts.push(`and ${outcome} outcome`);
        }
    
        // Join the parts with commas and return the final message
        return `User requested information ${messageParts.join(', ')}.`;
    };

    if (isLoading) {
        return <LoadingSpinner />;
    } 

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <ChatContext.Provider value={{ ...state, sendMessage, isLoading }}>
            {children}
        </ChatContext.Provider>
    );
};

ChatProvider.propTypes = {
    children: PropTypes.node.isRequired,
    datasets: PropTypes.object,
    isLoading: PropTypes.bool,
    error: PropTypes.object
};