import React, { useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DraggableItem = React.memo(({ item, index }) => {
    return (
        <Draggable key={item} draggableId={item} index={index}>
            {(provided) => (
                <div
                    className="Draggable"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ 
                        ...provided.draggableProps.style,
                        margin: "8px", // Increased margin
                        // Other styles...
                    }}
                >
                    {item}
                </div>
            )}
        </Draggable>
    );
});


const DraggableItemList = ({ items, onDragEnd }) => {

    // useEffect(() => {
    //     console.log('Nonce in React component:', window.NONCE);  // Log the nonce value in the React component
    // }, []);

    const onDrop = (droppedItem, dropZoneIndex) => {
        // Update the items array based on the dropped item and the drop zone index
        // This might involve finding the original index of the dropped item,
        // removing it from that position, and inserting it at the new position
    };
    
    // using useCallback is optional
    const onBeforeCapture = useCallback(() => {
        /*...*/
    }, []);
    const onBeforeDragStart = useCallback(() => {
        /*...*/
    }, []);
    const onDragStart = useCallback(() => {
        /*...*/
    }, []);
    const onDragUpdate = useCallback((update) => {
        console.log('Drag update:', update);
        // You can add more logic here if needed
    }, []);

    // Function to reorder the list
    const reorder = useCallback((list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }, []);

    const DropZone = ({ onDrop, index }) => {

        // The DropZone component
        return (
            <div
            className="drop-zone"
            // Only visual styling here, no drop handling
        >
            {/* Visual representation of the drop zone */}
        </div>
        );
    };
    
    const handleDragEnd = useCallback((result) => {
        console.log('Drag result:', result);

        console.log('Source index:', result.source.index);
        console.log('Source droppableId:', result.source.droppableId);
        
        if (result.destination) {
            console.log('Destination index:', result.destination.index);
            console.log('Destination droppableId:', result.destination.droppableId);
        } else {
            console.log('Dropped outside the list');
        }
    
        const newItems = reorder(items, result.source.index, result.destination.index);
        console.log("New reordered items:", newItems); // Debugging log
    
        if (result.destination && result.destination.index !== result.source.index) {
            console.log('Item moved to a new position');
        }

        onDragEnd(newItems); // Ensure this updates the state or dispatches a Redux action
    }, [items, onDragEnd, reorder]);
    
    if (!items || !Array.isArray(items)) {
        console.error("Error in DraggableItemList: 'items' prop is missing or not an array");
        return null;
    }

    if (typeof onDragEnd !== "function") {
        console.error("Error in DraggableItemList: 'onDragEnd' prop is missing or not a function");
        return null;
    }
    return (
        <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={onDragUpdate}>
            <Droppable droppableId="droppableItemList">
                {(provided, snapshot) => (
                    <div 
                        ref={provided.innerRef} 
                        {...provided.droppableProps}
                        className="Droppable"
                    >
                        {items.map((item, index) => (
                            <DraggableItem key={item} item={item} index={index} />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableItemList;