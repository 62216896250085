import React, { useContext, useEffect } from 'react';
import { Bar } from '@nivo/bar';
import { patternDotsDef } from '@nivo/core'


import { useDataset } from '../components/DataFetcher';
import { DimensionContext } from '../components/ResponsiveWrapper';
import LoadingSpinner from '../components/LoadingSpinner';

const customTheme = {
    tooltip: {
        container: {
            color: '#ddd',
            fontSize: '14px',
            borderRadius: '4px',
            boxShadow: '0px 0px 9px -1px rgba(0, 0, 0, 0.75)',
            background: '#fff',
        }
    }
}

const customColors = [
    '#ebb844', // ltorange
    // '#e08a3c', // mdorange
    // '#de6736', // dkorange
    // '#c5316a',  // magenta
    // '#762861', // cranberry
    // '#2c294b', // dkpurple
    // '#3b3484', // mdpurple
    // '#7174b0' // ltpurple
];

const CustomTooltip = ({ id, label, value }) => (
    <div style={{
      background: '#fff',
      color: '#3b3484',
      padding: '10px',
      borderRadius: '4px',
      fontSize: '14px',    // Font size
    }}>
      {`${label} - ${value}`}
    </div>
  );

  const CustomLabelLayer = ({ bars }) => {
    console.log("CustomLabelLayer init");
    console.log("CustomLabelLayer bars:", bars);

    // Correctly map over 'bars', not 'parts', for a bar chart
    return bars.map(bar => ( // Ensure consistent use of 'bar' as the iterator variable
        <text
            key={bar.key}
            x={bar.x + bar.width / 2} // Center label within the bar
            y={bar.y - 10} // Position above the bar; adjust as needed
            textAnchor="middle"
            style={{
                fill: '#000', // Text color
                fontSize: '14px', // Adjust font size as needed
                // Note: SVG <text> elements do not support backgroundColor directly.
            }}
        >
            {bar.data.label}
        </text>
    ));
};


function SumChart() {
    const dimensions = useContext(DimensionContext);
    console.log("SumCharts Dimensions:", dimensions);

    const { data, status, error } = useDataset();  

    const structuredData = data ? getDataMapping(data) : {};

    if (status === 'loading') return <LoadingState />;
    if (status === 'error') return <ErrorState error={error} />;

    return (
        <div className="visualization-container">
            <h1 className="h1">Benchmarks</h1>
            <div className="chart-container">
                {Object.keys(titleMapping).reverse().map(datasetKey => ( 
                <div key={datasetKey} className="single-chart">
                    <h2 className="chart-headline">{titleMapping[datasetKey]}</h2>
                        <NivoBarChart 
                            dataObject={structuredData[datasetKey]}
                            label={datasetKey}
                            width={dimensions.width / 2} // Assuming you want each chart to be half the total width
                            height={(dimensions.height - 50) / 2 - 50} // Each chart is half the total height
                            keys={['value']}
                            indexBy="label"
                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                            padding={0.3}
                            theme={customTheme}
                            tooltip={CustomTooltip}
                            layers={['grid', 'bars', CustomLabelLayer, 'axes', 'legends']} 
                            colors={customColors}
                            motionConfig="slow"
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

const LoadingState = () => <LoadingSpinner />;

const ErrorState = ({ error }) => <div>Error: {error.message}</div>;

const titleMapping = {
    "UniqueReferrer": "Where are they coming from?",
    "UniqueQueryString": "What's driving them in?",
    "UniqueEventDesc": "What are they doing?",
    "UniqueURL": "Where are they going?",
};

function getDataMapping(data) {
    return {
        'UniqueURL': data.UniqueURL || {},
        'UniqueEventDesc': data.UniqueEventDesc || {},
        'UniqueQueryString': data.UniqueQueryString || {},
        'UniqueReferrer': data.UniqueReferrer || {},
    };
}

function obfuscateLabel(label) {
    if (label.includes('staging-app')) {
        label = label.replace('staging-', '');
    }
    if (label.includes('webPageUrl')) {
        label = label.replace('webPageUrl', 'URL');
    }
    if (label.includes('https://')) {
        label = label.replace('https://', '');
    }
    if (label.includes('?URL=example.com?')) {
        label = label.replace('?URL=example.com?', '?');
    }
    return label;
}

function truncateText(text, maxLength = 30) {
    return (text.length > maxLength) ? text.substr(0, maxLength - 3) + "..." : text;
}

function prepareNivoData(dataObject) {
    const sortedData = Object.entries(dataObject)
      .filter(([label, value]) => label !== null && label !== undefined && label !== "" && value !== null && value !== undefined)  // Skip any entry where label or value is null, undefined, or empty string
      .sort((a, b) => b[1] - a[1]);

    const top10 = sortedData.slice(0, 10);
    const otherSum = sortedData.slice(10).reduce((acc, entry) => acc + entry[1], 0);
    const combinedData = [...top10, ['Other', otherSum]];

    return combinedData.map(entry => ({
        label: truncateText(obfuscateLabel(entry[0]), 30),
        value: entry[1]
    }));
}

function NivoBarChart({ dataObject, label, width, height }) {
    console.log("dataObject", dataObject)
    console.log("label", label)
    const preparedData = prepareNivoData(dataObject);
    console.log("preparedData", preparedData)

    useEffect(() => {
        // Get the container of the Nivo chart. Make this selector as specific as needed.
        const chartContainer = document.querySelector('.chart-container');
        if (!chartContainer) return;

        // Find all text elements with the specific attributes
        const textElements = chartContainer.querySelectorAll('text[fill="rgb(51, 51, 51)"]');
        textElements.forEach(el => el.setAttribute('fill', 'white'));
    }, []);

    return (
        <Bar
            layers={['grid', 'axes', 'bars', 'markers']}
            width={width}
            height={height}
            data={preparedData}
            keys={['value']}
            indexBy="label"
            layout="vertical"
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            padding={0.2}
            motionConfig="slow"
            // groupMode="stacked"
            tooltip={CustomTooltip}
            colors={(bar) => {
                // bar.index is the index of the current bar
                return customColors[bar.index % customColors.length];
              }}
            theme={customTheme}
            borderColor="#fff"
            barAriaLabel={e=>e.indexValue+" : "+e.formattedValue}    
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            defs={[patternDotsDef('dots-pattern', {
            "size": 7,
            "padding": 17,
            "stagger": true,
            "background": "#ffffff",
            "color": "inherit"
            }),
            
            // plain object
            {
                "id": "dots-pattern",
                "type": "patternDots",
                "size": 7,
                "padding": 17,
                "stagger": true,
                "background": "#ffffff",
                "color": "inherit"
            } ]}
        />
    );
}
export default SumChart;