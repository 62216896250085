import React, { useState, useContext } from 'react';
import { useDataset } from '../components/DataFetcher';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the Spinner component
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';
import ModelMetaphor from '../assets/ModelMetaphor.png';


function ModelOutput() {
  const dimensions = useContext(DimensionContext);
  const { data, status, error, refetch } = useDataset();
  const [isLoading, setIsLoading] = useState(true);
  console.log('useDataset hook called');
  console.log('dimensions', dimensions);
  console.log('status:', status);
  console.log('data inside ModelOutput:', data);
  console.log('error inside ModelOutput:', error);

  console.log(`isLoading: ${isLoading}, status: ${status}`); // Debugging line

  if (status === 'loading') {
    return <LoadingSpinner />;
  }
  if (status === 'error') {
    console.error('Error fetching data:', error);
    return <div>Error: {error.message}</div>;
  }

  const uniqueQueryString =
    data && typeof data.UniqueQueryString === 'object'
      ? data.UniqueQueryString
      : {};
  const uniqueURL =
    data && typeof data.UniqueURL === 'object' ? data.UniqueURL : {};
  const uniqueReferrer =
    data && typeof data.UniqueReferrer === 'object' ? data.UniqueReferrer : {};
  const uniqueEventDesc =
    data && typeof data.UniqueEventDesc === 'object'
      ? data.UniqueEventDesc
      : {};

  return (
    <>
    <div className="visualization-container">
    {/* {isLoading ? <LoadingSpinner /> : null} */}
        <div className="flex-column-spacing">
        <h1 className="h1">Model Output</h1>
        {/* <div>
            <label className="block-label">URL:</label>
            <select className="full-width-border-select">
                {Object.keys(uniqueURL).map((key, index) => (
                    <option key={index} value={key}>
                        {key || 'No URLs'} - {uniqueURL[key]}
                    </option>
                ))}
            </select>
        </div>
        <div>
            <label className="block-label">QueryString:</label>
            <select className="full-width-border-select">
                {Object.keys(uniqueQueryString).map((key, index) => (
                    <option key={index} value={key}>
                        {key || 'No QueryStrings'} - {uniqueQueryString[key]}
                    </option>
                ))}
            </select>
        </div><div>
            <label className="block-label">Referrer:</label>
            <select className="full-width-border-select">
                {Object.keys(uniqueReferrer).map((key, index) => (
                    <option key={index} value={key}>
                        {key || 'No Referrers'} - {uniqueReferrer[key]}
                    </option>
                ))}
            </select>
        </div><div>
            <label className="block-label">EventDesc:</label>
            <select className="full-width-border-select">
                {Object.keys(uniqueEventDesc).map((key, index) => (
                    <option key={index} value={key}>
                        {key || 'No EventDescs'} - {uniqueEventDesc[key]}
                    </option>
                ))}
            </select>
        </div> */}
    
    <div className="mb-4">
        <pre className="small-text-preformatted">
            {Object.entries(data).map(([key, value]) => (
                <div key={key}>
                    <strong>{key}:</strong>
                    <div>{JSON.stringify(value, null, 2)}</div>
                    <br />
                </div>
            ))}
        </pre>
    <div className="mb-4">
    <img
      src={ModelMetaphor}
      alt="Metaphor for the Models used by Teraace"
      className="model-explanation"
    />
        <h1> Explainations of Models</h1>
    <h3> 1. FP Growth (Frequency Patterns)</h3>
    <p>- Identifying Frequent User Path: By analyzing antecedents and consequents, FP Growth helps in understanding common sequences of pages and events that users follow.</p>
<p>- Enhancing Recommendations: Metrics like confidence and lift can be used to suggest relevant pages or features, based on users' navigation patterns, enhancing their experience.</p>

<h3> 2. Markov Chains</h3>
<p>- Predicting User Behavior: Markov Chains model sequences of events, enabling prediction of the next page or event a user might interact with.</p>
<p>- Content Preloading: By predicting user behavior, you can preload content, making navigation smoother and faster.</p>

<h3> 3. Dijkstra's Path</h3>
<p>- Optimizing User Journeys: Dijkstra's Path can be used to find the most efficient paths between nodes (pages or events), ensuring that users can navigate with minimal effort.</p>
<p>- Identifying Critical Paths: By analyzing the shortest paths, you can identify critical user journeys that must be optimized for a better user experience.</p>

<h3>4. Random Forest</h3>
<p>- Personalized Insights: Random Forest can classify user behavior, enabling personalized recommendations or tailored content.</p>
<p>- Predicting User Behavior: Utilizing classification and regression, it can predict future user actions, enhancing user engagement.</p>

<h3>5. GreedyRules List</h3>
<p>- Real-time Decision Making: This method can be used to prioritize content, optimize load times, or make quick recommendations, ensuring responsive and efficient interactions.</p>
<p>- Heuristic-based Optimization: By creating rules based on heuristics, GreedyRules can quickly solve problems like content prioritization, enhancing the overall user experience.</p>

<h3>6. 3D Graph</h3>
<p>- Visualizing Complex Relationships: 3D graphs offer an intuitive way for Product Managers to explore complex relationships between pages, events, and user segments.</p>
<p>- Interactive Exploration: Enhanced visualizations provide a more engaging way to understand user behavior, patterns, and interactions within the application.</p>

<h2>Explaination of Models together</h2>
<p>- Understanding Patterns: FP Growth, Markov Chains, and 3D Graphs allow for deep insights into how users navigate through the application.</p>
<p>- Predicting and Personalizing: Markov Chains and Random Forest provide capabilities to predict user behavior and tailor content accordingly.</p>
<p>- Optimizing Experience: Dijkstra's Path and GreedyRules assist in making the user's journey smooth, efficient, and responsive.</p>
<p>- Visual Exploration: 3D Graphs offer a visual, interactive way to explore complex relationships, enhancing the interpretability of insights.</p>
    </div>
</div>
</div>
    <div>

    <SEO
      title="Model Output"
      description="The results of all our models."
      url="/modeloutput"
    />
    </div>
    </div>

    <InfoBox title={'Model Output'} description={"Humans can't understand the complexity of this dataset, it's temporarily shown for defensibility."} />

    </>
  );
}

export default ModelOutput;
