import React, { useState, useEffect } from 'react';
import { askOracle, tellOracle } from './AskOracle';  // Assuming the provided functions are in oracleApi.js
import { DimensionContext } from './ResponsiveWrapper';
import SanitizedInput from './SanitizedInput';

const TokenManager = () => {
  const [tokens, setTokens] = useState([]);
  const [newTokenName, setNewTokenName] = useState('');
  const [recentlyGeneratedToken, setRecentlyGeneratedToken] = useState('');

  const getOrgID = () => {
    // Retrieve orgID from your state manager or wherever it's stored
    // For this example, I'll assume it's in local storage:
    return localStorage.getItem('ActiveOrgID');
  }
  
  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const ActiveOrgID = getOrgID();
        if (!ActiveOrgID) {
          console.error('ActiveOrgID is not set. Cannot fetch tokens.');
          return;
        }
        const data = await askOracle('oracle/askTokens', { headers: { 'OrgID': ActiveOrgID } });
        if (data !== null && data !== undefined) {
          setTokens(data);
          console.log("fetchTokens:", data);
        } else {
          console.error('Data returned from askOracle is null or undefined.');
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };
  
    fetchTokens();
  }, [getOrgID()]);

  const addToken = async () => {
    const activeOrgID = getOrgID();
    if (!activeOrgID) {
      console.error('ActiveOrgID is not set. Cannot add token.');
      return;
    }
    try {
      const data = await tellOracle('oracle/tellTokens', { name: newTokenName }, { headers: { 'OrgID': activeOrgID } });
      setTokens([...tokens, data.tokenInfo]);
      setRecentlyGeneratedToken(data.plainToken);
      setNewTokenName('');
    } catch (error) {
      console.error('Error adding token:', error);
    }
  };  

  const deleteToken = async (tokenId) => {
    try {
      await tellOracle(`oracle/askTokens/${tokenId}`, { method: 'DELETE' });
      setTokens(tokens.filter(token => token.id !== tokenId));
    } catch (error) {
      console.error('Error deleting token:', error);
    }
  };

  return (
    <div>
      <h2>Token Manager</h2>
      <div>
        <SanitizedInput
          value={newTokenName}
          onChange={(e) => setNewTokenName(e.target.value)}
          placeholder="Token Name"
        />
        <button onClick={addToken}>Add Token</button>
      </div>
      {recentlyGeneratedToken && (
        <div>
        {console.log(`Token value: '${recentlyGeneratedToken}'`)}
          <p>Your newly generated token is:</p>
          <textarea readOnly value={recentlyGeneratedToken}></textarea>
          <p>Please copy and secure this token immediately. It will not be shown again.</p>
        </div>
      )}
     <ul>
      <h5>Active Tokens:</h5>
      {tokens && tokens.length === 0 ? (
        <li>No active tokens</li>
      ) : (
        tokens && tokens.map(token => (
          token && token.id && token.name ? (
            <li key={token.id}>
              {token.name}
              <button className="mx-4" onClick={() => deleteToken(token.id)}>&nbsp;&nbsp;Delete</button>
            </li>
          ) : null
        ))
      )}
    </ul>
    </div>
  );
};

export default TokenManager;
