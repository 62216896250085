import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import NivoTheme from '../components/NivoTheme'; // Import your Nivo theme
import { motion } from 'framer-motion';
import { Card, Title, Text, SegmentedControl } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import LoadingSpinner from '../components/LoadingSpinner';
import { format, isWithinInterval, parseISO, startOfWeek, startOfMonth, isValid } from 'date-fns';
import { askOracle } from '../components/AskOracle';

const WeekOverWeekAnalysis = ({ orgID }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([null, null]);
    const [isWeekly, setIsWeekly] = useState(true);

    const customColors = [
        '#ebb844', // ltorange
        // '#e08a3c', // mdorange
        '#de6736', // dkorange
        '#c5316a',  // magenta
        '#762861', // cranberry
        '#2c294b', // dkpurple
        '#3b3484', // mdpurple
        '#7174b0' // ltpurple
    ];

    const NivoTheme = {
        axis: {
          ticks: {
            text: {
              fontFamily: ['Manjari', 'sans-serif'],
              fontSize: '12px',
            },
          },
          legend: {
            text: {
              fontFamily: ['Manjari', 'sans-serif'],
              fontSize: '18px',
            },
          },
        },
        legends: {
          text: {
            fontFamily: ['Manjari', 'sans-serif'],
          },
        },
        labels: {
          text: {
            fontFamily: ['Manjari', 'sans-serif'],
          },
        },
        tooltip: {
          container: {
            fontFamily: ['Manjari', 'sans-serif'],
          },
        },
      };

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            console.log("Sent by-page to askOracle with params:", { orgID });
    
            askOracle('by-page', {
                params: { orgID },
            })
                .then(response => {
                    console.log("Received response from askOracle:", response);
    
                    const validData = response.filter(entry => (
                        entry.Timestamp && isValid(parseISO(entry.Timestamp)) &&
                        entry.URL && entry.URL.trim() !== '' &&
                        entry.HappyID && entry.HappyID.trim() !== '' &&
                        entry.SessionId && entry.SessionId.trim() !== '' &&
                        Array.isArray(entry.EventDescList) &&
                        entry.EventDescList.length > 0 &&
                        entry.EventDescList.every(event => event)
                    ));
    
                    // No filtering here, just set the full valid data
                    setData(validData);
                })
                .catch(err => {
                    console.error("Error fetching data:", err);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        fetchData();
    }, [orgID]); // Remove dateRange from dependencies

    const filteredData = dateRange[0] && dateRange[1] && isValid(dateRange[0]) && isValid(dateRange[1])
    ? data.filter(entry =>
        isWithinInterval(parseISO(entry.Timestamp), {
            start: dateRange[0],
            end: dateRange[1],
        })
    )
    : data;

    
    // useEffect(() => {
    //     const fetchData = () => {
    //         setLoading(true);
    //         console.log("Sent by-page to askOracle with params:", { orgID });

    //         askOracle('by-page', {
    //             params: { orgID },
    //         })
    //             .then(response => {
    //                 console.log("Received response from askOracle:", response);

    //                 const validData = response.filter(entry => (
    //                     entry.Timestamp && isValid(parseISO(entry.Timestamp)) &&
    //                     entry.URL && entry.URL.trim() !== '' &&
    //                     entry.HappyID && entry.HappyID.trim() !== '' &&
    //                     entry.SessionId && entry.SessionId.trim() !== '' &&
    //                     Array.isArray(entry.EventDescList) &&
    //                     entry.EventDescList.length > 0 &&
    //                     entry.EventDescList.every(event => event)
    //                 ));

    //                 if (dateRange[0] && dateRange[1] && isValid(dateRange[0]) && isValid(dateRange[1])) {
    //                     const filteredData = validData.filter((entry) =>
    //                         isWithinInterval(parseISO(entry.Timestamp), {
    //                             start: dateRange[0],
    //                             end: dateRange[1],
    //                         })
    //                     );
    //                     setData(filteredData);
    //                 } else {
    //                     setData(validData);
    //                 }
    //             })
    //             .catch(err => {
    //                 console.error("Error fetching data:", err);
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     };
    //     fetchData();
    // }, [orgID, dateRange]);

    if (loading) return <LoadingSpinner />;

    const safeNumber = (value, defaultValue = 0, entry = null) => {
        if (value === undefined || value === null || value === '' || isNaN(Number(value))) {
            return defaultValue === 0 ? 0.0001 : defaultValue;
        }
        return Number(value);
    };

    const cleanData = (filteredData) => {
        return filteredData.filter(d =>
            d &&
            Object.values(d).every(v =>
                v !== undefined &&
                v !== null &&
                (typeof v !== 'number' || !isNaN(v))
            )
        );
    };

    const getTimePeriodKey = (timestamp) => {
        if (isWeekly) {
            return format(startOfWeek(parseISO(timestamp)), 'yyyy-MM-dd');
        } else {
            return format(startOfMonth(parseISO(timestamp)), 'yyyy-MM');
        }
    };

    const pageVisits = filteredData.reduce((acc, entry) => {
        if (isValid(parseISO(entry.Timestamp))) {
            const periodKey = getTimePeriodKey(entry.Timestamp);
            acc[periodKey] = (acc[periodKey] || 0) + 1;
        }
        return acc;
    }, {});

    const pageVisitsData = cleanData(
        Object.entries(pageVisits)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([period, count]) => ({
                period: `${period.replace(/-/g, '/')}`,
                count: safeNumber(count, 0, { period, count }),
            }))
    );

    const averagePageDuration = filteredData.reduce((acc, entry) => {
        if (entry.PageDuration !== undefined && entry.PageDuration !== null && !isNaN(entry.PageDuration)) {
            if (!acc[entry.URL]) {
                acc[entry.URL] = { totalDuration: 0, count: 0 };
            }
            acc[entry.URL].totalDuration += entry.PageDuration;
            acc[entry.URL].count += 1;
        }
        return acc;
    }, {});

    const formatDuration = (durationInSeconds) => {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.round(durationInSeconds % 60);
        return `${minutes}m ${seconds}s`;
    };

    const averagePageDurationData = cleanData(
        Object.entries(averagePageDuration)
            .map(([url, { totalDuration, count }]) => {
                const avgDuration = count > 0 ? totalDuration / count : 0;
                return {
                    url: url || 'No Data',
                    averageDurationNumeric: avgDuration,
                    averageDuration: formatDuration(safeNumber(avgDuration, 0, { url, totalDuration, count }))
                };
            })
            .sort((a, b) => b.averageDurationNumeric - a.averageDurationNumeric)
    );

    const usersPerPeriod = filteredData.reduce((acc, entry) => {
        if (isValid(parseISO(entry.Timestamp))) {
            const periodKey = getTimePeriodKey(entry.Timestamp);
            acc[periodKey] = acc[periodKey] || new Set();
            acc[periodKey].add(entry.HappyID);
        }
        return acc;
    }, {});

    const usersPerPeriodData = cleanData(
        Object.entries(usersPerPeriod)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([period, users]) => ({
                period: `${period.replace(/-/g, '/')}`,
                users: safeNumber(users.size, 0, { period, userCount: users.size }),
            }))
    );

    const sessionsPerPeriod = filteredData.reduce((acc, entry) => {
        if (isValid(parseISO(entry.Timestamp))) {
            const periodKey = getTimePeriodKey(entry.Timestamp);
            acc[periodKey] = acc[periodKey] || new Set();
            acc[periodKey].add(entry.SessionId);
        }
        return acc;
    }, {});

    const sessionsPerPeriodData = cleanData(
        Object.entries(sessionsPerPeriod)
            .sort(([a], [b]) => new Date(a) - new Date(b))
            .map(([period, sessions]) => ({
                period: `${period.replace(/-/g, '/')}`,
                sessions: safeNumber(sessions.size, 0, { period, sessionCount: sessions.size }),
            }))
    );

    const eventDescCounts = filteredData.reduce((acc, entry) => {
        if (Array.isArray(entry.EventDescList)) {
            entry.EventDescList.forEach(event => {
                if (event) {
                    acc[event] = (acc[event] || 0) + 1;
                }
            });
        }
        return acc;
    }, {});

    const top10EventsData = cleanData(
        Object.entries(eventDescCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10)
            .map(([event, count]) => ({
                event: event || 'N/A',
                count: safeNumber(count, 0, { event, count }),
            }))
    );

    return (
        <div className="visualization-container">

        <div className="week-analysis-page">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                <h1 order={2}>{isWeekly ? 'Week Over Week Analysis' : 'Month Over Month Analysis'}</h1>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <SegmentedControl
                        data={[
                            { label: 'Weekly', value: 'weekly' },
                            { label: 'Monthly', value: 'monthly' }
                        ]}
                        value={isWeekly ? 'weekly' : 'monthly'}
                        onChange={(value) => setIsWeekly(value === 'weekly')}
                        style={{ marginLeft: '1rem' }}
                    />      
                    <DatePickerInput
                        type="range"
                        label=""
                        placeholder="Filter date range"
                        value={dateRange}
                        onChange={setDateRange}
                        style={{ marginLeft: '2rem' }}
                    />
                      {/* Reset Button */}
                      {dateRange[0] && dateRange[1] && (
                            <button
                            onClick={() => setDateRange([null, null])} // Reset the date range
                            className="form-button"
                            style={{ marginLeft: '1rem', maxWidth: '200px' }}
                            >
                            Reset Dates
                            </button>
                        )}
                </div>
            </motion.div>

            <div className="overview-chart">
                <Card shadow="sm" padding="lg" className="my-4">
                    <Title order={3}>{isWeekly ? 'Weekly Page Visits' : 'Monthly Page Visits'}</Title>
                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                            data={pageVisitsData}
                            keys={['count']}
                            indexBy="period"
                            indexScale={{ type: 'band', round: true }}
                            valueScale={{ type: 'linear' }}
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            layout="vertical"
                            colors={customColors[0]}
                            axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
                            axisLeft={{ legend: 'Page Visits', legendPosition: 'middle', legendOffset: -60 }}
                            enableLabel={false}
                            animate={true}
                            theme={NivoTheme} 
                            tooltip={({ value, indexValue }) => (
                                <div className="nivoTool">
                                    {indexValue} count: <strong>{value}</strong>
                                </div>
                            )}
                        />
                    </div>
                </Card>

                <Card shadow="sm" padding="lg" className="my-4">
                    <Title order={3}>{isWeekly ? 'Number of Users per Week' : 'Number of Users per Month'}</Title>
                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                            data={usersPerPeriodData}
                            keys={['users']}
                            indexBy="period"
                            indexScale={{ type: 'band', round: true }}
                            valueScale={{ type: 'linear' }}
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            layout="vertical"
                            colors={customColors[1]}
                            axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
                            axisLeft={{ legend: 'Number of Users', legendPosition: 'middle', legendOffset: -60 }}
                            enableLabel={false}
                            animate={true}
                            theme={NivoTheme} 
                            tooltip={({ value, indexValue }) => (
                                <div className="nivoTool">
                                    {indexValue} user count: <strong>{value}</strong>
                                </div>
                            )}
                        />
                    </div>
                </Card>

                <Card shadow="sm" padding="lg" className="my-4">
                    <Title order={3}>{isWeekly ? 'Number of Sessions per Week' : 'Number of Sessions per Month'}</Title>
                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                            data={sessionsPerPeriodData}
                            keys={['sessions']}
                            indexBy="period"
                            indexScale={{ type: 'band', round: true }}
                            valueScale={{ type: 'linear' }}
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            layout="vertical"
                            colors={customColors[2]}
                            axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
                            axisLeft={{ legend: 'Number of Sessions', legendPosition: 'middle', legendOffset: -60 }}
                            enableLabel={false}
                            animate={true}
                            theme={NivoTheme} 
                            tooltip={({ value, indexValue }) => (
                                <div className="nivoTool">
                                    {indexValue} session count: <strong>{value}</strong>
                                </div>
                            )}
                        />
                    </div>
                </Card>

                <Card shadow="sm" padding="lg" className="my-4">
                    <Title order={3}>Average Page Duration</Title>
                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                            data={averagePageDurationData}
                            keys={['averageDurationNumeric']}
                            indexBy="url"
                            indexScale={{ type: 'band', round: true }}
                            valueScale={{ type: 'linear' }}
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            layout="vertical"
                            colors={customColors[3]}
                            axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
                            axisLeft={{ legend: 'Average Duration (seconds)', legendPosition: 'middle', legendOffset: -60 }}
                            tooltip={({ value, indexValue }) => (
                                <div className="nivoTool">
                                    {indexValue} duration: <strong>{formatDuration(value)}</strong>
                                </div>
                            )}
                            enableLabel={false}
                            animate={true}
                            theme={NivoTheme} 
                        />
                    </div>
                </Card>

                <Card shadow="sm" padding="lg" className="my-4">
                    <Title order={3}>Top 10 Events by Count</Title>
                    <div style={{ height: 400 }}>
                        <ResponsiveBar
                            data={top10EventsData}
                            keys={['count']}
                            indexBy="event"
                            indexScale={{ type: 'band', round: true }}
                            valueScale={{ type: 'linear' }}
                            margin={{ top: 20, right: 20, bottom: 80, left: 80 }}
                            padding={0.3}
                            layout="vertical"
                            colors={customColors[4]}
                            axisBottom={{ legend: '', legendPosition: 'middle', legendOffset: 40, format: (d) => String(d), tickRotation: 45 }}
                            axisLeft={{ legend: 'Count', legendPosition: 'middle', legendOffset: -60 }}
                            enableLabel={false}
                            animate={true}
                            theme={NivoTheme} 
                            tooltip={({ value, indexValue }) => (
                                <div className="nivoTool">
                                    {indexValue} count: <strong>{value}</strong>
                                </div>
                            )}
                        />
                    </div>
                </Card>
            </div>
        </div>
        </div>
    );
}

export default WeekOverWeekAnalysis;
