import axios from 'axios';

axios.defaults.withCredentials = true;

// const csrfToken = localStorage.getItem('csrfToken');

// axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

const GO_BASE = process.env.REACT_APP_GO_BASE;

export const askOracle = (endpoint, config = {}) => {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  console.log("Sent askOracle to:", endpoint)
  console.log("Sent askOracle for OrgID:", OrgID)
  const headers = {
    'Authorization': `Bearer ${token}`,
    'OrgID': `${OrgID}`,
    ...config.headers
  };
  return axios.get(`${GO_BASE}/${endpoint}`, { ...config, headers }, { withCredentials: true })
    .then(res => res.data)
    .catch(error => { throw error; });
};

export const tellOracle = (endpoint, data, config = {}) => {
  const token = localStorage.getItem('authToken');
  const OrgID = localStorage.getItem('ActiveOrgID');
  const headers = {
    'Authorization': `Bearer ${token}`,
    'OrgID': `${OrgID}`,
    ...config.headers
  };
  console.log("tellOracle headers", headers)
  return axios.post(`${GO_BASE}/${endpoint}`, data, { ...config, headers }, { withCredentials: true })
    .then(res => res.data)
    .catch(error => { throw error; });
};


  // const headers = { ...defaultHeaders, ...config.headers };
  // return (
  //   axios
  //     .post(`${GO_BASE}/${endpoint}`, data, { ...config, headers }, { withCredentials: true })
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     })
  // );
// };

// export const askOracle = (endpoint, headers = {}) => {
//   console.log(`Asking the Oracle about ${endpoint}...`);
//   console.log(headers)
//   return (
//     axios
//       .get(`${GO_BASE}/${endpoint}`, { headers: { ...headers, 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }})
//       .then((res) => {
//         console.log('The Oracle responded successfully:', res.data);
//         return res.data;
//       })
//       .catch((error) => {
//         console.log('Error asking Oracle a question:', error);
//         throw error;
//       })
//   );
// };

// export const tellOracle = (endpoint, data, headers = {}) => {
//   console.log(`Posting data to Oracle ${endpoint}...`);
//   console.log(headers)
//   return (
//     axios
//       .post(`${GO_BASE}/${endpoint}`, data, { headers: { ...headers, 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }})
//       .then((res) => {
//         console.log('Talked to Oracle successfully:', res.data);
//         return res.data;
//       })
//       .catch((error) => {
//         console.log('Error discussing data with Oracle:', error);
//         throw error;
//       })
//   );
// };

// import axios from 'axios';

// const GO_BASE = process.env.REACT_APP_GO_BASE;

// export const askOracle = (endpoint) => {
//   console.log(`Asking the Oracle about ${endpoint}...`);
//   return (
//     axios
//       .get(`${GO_BASE}/${endpoint}`)
//       .then((res) => {
//         console.log('The Oracle responded successfully:', res.data);
//         return res.data;
//       })
//       .catch((error) => {
//         console.log('Error asking Oracle a question:', error);
//         throw error;
//       })
//   );
// };

// export const tellOracle = (endpoint, data) => {
//     console.log(`Posting data to Oracle ${endpoint}...`);
//     return (
//       axios
//         .post(`${GO_BASE}/${endpoint}`, data)
//         .then((res) => {
//           console.log('Talked to Oracle successfully:', res.data);
//           return res.data;
//         })
//         .catch((error) => {
//           console.log('Error discussing data with Oracle:', error);
//           throw error;
//         })
//     );
//   };
  