// FunnelPage.jsx
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { ResponsiveFunnel } from '@nivo/funnel';
import LoadingSpinner from '../components/LoadingSpinner';
import { DimensionContext } from '../components/ResponsiveWrapper';
import { useDataset } from '../components/DataFetcher';
import FunnelConfig from '../components/FunnelConfig'; 
import { Button } from '@mantine/core';
import { askOracle } from '../components/AskOracle';


const FunnelPage = () => {
    const { width, height } = useContext(DimensionContext);
    const { data: dataset, isLoading } = useDataset(); // Correctly included
    const [funnelStages, setFunnelStages] = useState([]);
    const [isConfigOpen, setIsConfigOpen] = useState(false);

    const toggleConfigModal = () => setIsConfigOpen(!isConfigOpen);
    const saveFunnelConfig = (stages) => {
        setFunnelStages(stages);
        toggleConfigModal();
    };

    useEffect(() => {
        askOracle('oracle/askFunnelConfig')
          .then(data => {
            // Assuming 'data' contains an array of stages with 'key' and 'label'
            const stagesFromServer = data.map(stage => ({
              value: stage.key, // Adjust according to your data structure
              label: stage.label,
            }));
            setFunnelStages(stagesFromServer);
          })
          .catch(error => {
            console.error("Failed to fetch funnel configuration:", error);
          });
      }, []); // Empty dependency array ensures this runs once on mount
      
    const funnelData = useMemo(() => {
        if (!dataset || !funnelStages.length) return [];
        const stagesData = funnelStages.map((stage, index) => ({
            id: `stage${index + 1}`,
            value: dataset.UniqueURL[stage.label] || 0,
            label: stage.label,
        }));
        console.log('funnelData stagesData:', stagesData);
        return stagesData;
    }, [dataset, funnelStages]);

    return (
        <div style={{ width: '100%', height: '90%' }}>
            <Button className="config-button" onClick={toggleConfigModal}>Configure</Button>
            <h1>Conversion Funnel</h1>
            {isLoading ? (
                <LoadingSpinner />
            ) : funnelData.length > 0 ? (
            <MyResponsiveFunnel data={funnelData} height={height} />
            ) : (
                <div>Please configure the funnel stages to view the visualization.</div>
            )}
            <FunnelConfig
                isOpen={isConfigOpen}
                onClose={toggleConfigModal}
                onSave={saveFunnelConfig}
                initialStages={funnelStages}
            />
        </div>
    );    
};

const CustomLabelLayer = ({ parts }) => {
    // Generate SVG text elements for each part of the funnel
    return parts.map(part => (
        <text
            key={part.id}
            // x={part.x + part.width / 2} // Center label in the part
            // y={part.y + part.height / 2} // Middle of the part
            x={part.x} // Center label in the part
            y={part.y - 25} // Middle of the part
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fill: '#fff',
                fontSize: '20px',
                backgroundColor: 'white',
            }}
        >
            {/* {`${part.data.label}: ${part.data.value}`} */}
            {`${part.data.label}`}
        </text>
    ));
};

const conversionRateLayer = (funnelData, height) => {
        console.log('conversionRateLayer funnelData:', funnelData);
        console.log('conversionRateLayer height:', height);

    return ({ labelTextColor }) => {
      // Adjusting the total height to be 90% of the provided height for equidistant distribution
      const adjustedHeight = height * 0.9;
      return funnelData.map((stage, i) => {
        if (i === funnelData.length - 1) return null; // No conversion rate after the last stage
  
        const rate = ((funnelData[i + 1].value / stage.value) * 100).toFixed(2);
        // Use adjustedHeight for segment height calculation
        const segmentHeight = adjustedHeight / (funnelData.length - 1); // Adjust for n-1 segments
        const yPosition = segmentHeight * i + (segmentHeight / 2); // Positioning rate text in the middle of each segment
        const xPosition = 100; // Fixed offset from the left, adjust as needed
  
        return (
          <text
            key={stage.id}
            x={xPosition}
            y={yPosition}
            textAnchor="middle"
            style={{ fill: labelTextColor || 'black', fontSize: '32px' }}
          >
            {rate}%
          </text>
        );
      }).filter(element => element !== null); // Filter out the nulls (last stage)
    };
  };

  const theme = {
    labels: {
      text: {
        fontSize: 24, // Adjust the font size as needed
      }
    }
  };
  
  
const MyResponsiveFunnel = ({ data, height }) => (
  <div className="visualization-container">
    <ResponsiveFunnel
        data={data}
        theme={theme}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        layers={['grid', 'axis', 'bars', 'separators', 'parts', 'labels', 'annotations', CustomLabelLayer, conversionRateLayer(data, height)]}
        shapeBlending={0.5}
        // spacing={4}
        // valueFormat=" ^-.4r"
        valueFormat="d" // Updated to use "d" for integer formatting
        colors={[ '#de6736', '#e08a3c', '#ebb844',  '#7174b0', '#3b3484', '#762861', '#2c294b', '#80ba55', '#c5316a', '#ebe5df', '#958e86', '#5d3c23' ]}
        borderWidth={20}
        borderOpacity={0.65}
        labelColor={{ from: 'color', modifiers: [['brighter', 3]] }}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={24}
        currentBorderWidth={85}
        motionConfig="molasses"
    />
    </div>
);

export default FunnelPage;

