import React, { useRef, useEffect, useContext } from 'react';
import * as d3 from 'd3';
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';

const HappyPath = () => {
  const canvasRef = useRef(null);
  const dimensions = useContext(DimensionContext);
  // console.log('dimensions', dimensions);

  useEffect(() => {
    console.log('useEffect in Happy Path');
  const sidebarWidth = 200; // Define based on your layout
    const width = window.innerWidth - sidebarWidth;
    const height = window.innerHeight;
    const margin = 50;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    const mainHappyPath = ['/happypath', '/pathanalyzer', '/admin'];
    const lazyPath = [
      [
        '/happypath',
        '/pathanalyzer',
        'click - node box',
        'click - path node',
        '/admin',
      ],
      ['/happypath', '/benchmarks', '/funnel', '/admin'],
      ['/happypath', '/conversations', '/admin'],

    ];
    const disjointedPath = [
      ['/happypath', '/treeexplorer', '/admin'],      
      [
        '/happypath',
        '/benchmarks',
        'click - bar chart',
        'click - line value',
        '/admin',
      ],
      [
        '/happypath',
        '/conversations',
        '/journeyflow',
        '/pathanalyzer',
        '/featureinsights',
        'click - Sparks',
        '/admin',
      ],
    ];

    const data = [mainHappyPath, ...lazyPath, ...disjointedPath];
    const sortedPaths = data.sort((a, b) => a.length - b.length);

    const startNode = sortedPaths[0][0];
    const endNode = sortedPaths[0][sortedPaths[0].length - 1];
    const pathsWithoutStartEnd = sortedPaths.map((path) => path.slice(1, -1));
    const middlePath = [startNode, ...pathsWithoutStartEnd[0], endNode];

    // Create rows with the middle path in the center
    const rows = new Array(7).fill(null).map(() => []);
    rows[3] = middlePath;

    // Distribute other paths around the Happy Path
    let rowIndex = 0;
    [...lazyPath, ...disjointedPath].forEach((path) => {
      // Exclude the middle row (index 3) and ensure distribution above and below the Happy Path
      if (rowIndex === 3) rowIndex++;
      // Include only the middle part of the path, excluding the start and end nodes
      rows[rowIndex] = path.slice(1, -1);
      rowIndex++;
    });

    // Determine max nodes for scaling
    // const maxNodes = Math.max(...rows.map((r) => r.length));
    const maxNodes = Math.max(
      mainHappyPath.length,
      ...lazyPath.map((p) => p.length + 2),
      ...disjointedPath.map((p) => p.length + 2),
    );

    // Define scales
    const nodeWidth = 125; // Width of the box
    const nodeHeight = 50; // Height of the box
    const xScale = d3
      .scaleLinear()
      .domain([0, maxNodes - 1])
      .range([margin, width - margin - nodeWidth]);
    const yScale = d3
      .scaleLinear()
      .domain([0, rows.length - 1])
      .range([margin, height - margin - nodeHeight]);

    // Coordinates of the common start and end nodes on the fourth row
    const commonStartX = xScale(0);
    const commonStartY = yScale(3); // Row index 3 for the fourth row
    const commonEndX = xScale(maxNodes - 1);

    // Draw nodes and lines
    rows.forEach((row, rowIndex) => {
      // Calculate starting position to center-align nodes within the row
      const emptySpaces = maxNodes - row.length;
      const startPosition = emptySpaces / 2;
      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        // Skip drawing start and end nodes if they are not in the middle path (row index 3)
        if ((node === startNode || node === endNode) && rowIndex !== 3) return;
        // Determine x and y coordinates for the current node
        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        // Special handling for start and end nodes
        if (node === startNode) {
          x = xScale(0); // Place at the far left
        } else if (node === endNode) {
          x = xScale(maxNodes - 1); // Place at the far right
        }
        // Determine x and y coordinates for drawing lines
        // const lineX = node === startNode ? commonStartX : node === endNode ? commonEndX : xScale(nodeIndex);
        // const lineY = node === startNode || node === endNode ? commonStartY : yScale(rowIndex);

        // Styling the line
        context.lineWidth = 3; // Making the line thicker
        context.strokeStyle = 'gray'; // Changing the line color to blue
        context.setLineDash([10, 5]); // Making the line dashed
        // context.stroke();                     // Apply the line stroke

        // Draw horizontal lines connecting nodes within the path
        if (
          nodeIndex > 0 &&
          node !== endNode &&
          row[nodeIndex - 1] !== startNode
        ) {
          context.beginPath();
          context.moveTo(xScale(startPosition + nodeIndex - 1), y + 25); // Start from the previous node
          context.lineTo(x, y + 25); // Connect to the current node
          context.lineWidth = 3;
          context.strokeStyle = 'gray';
          context.setLineDash([10, 5]); // Making the line dashed
          context.stroke();
        }
        // Draw vertical lines connecting to common start and end nodes
        if (rowIndex !== 3) {
          if (nodeIndex === 0) {
            context.beginPath();
            context.moveTo(commonStartX + 125, commonStartY + 25);
            context.lineTo(x, y + 25); // Connect to the first node in the row
            context.strokeStyle = 'gray';
            context.stroke();
          }

          if (nodeIndex === row.length - 1) {
            context.beginPath();
            context.moveTo(x + 125, y + 25); // Start from the last node in the row
            context.lineTo(commonEndX, commonStartY + 25); // Connect to common end node
            context.setLineDash([10, 5]); // Making the line dashed
            context.strokeStyle = 'gray';
            context.stroke();
          }
        }
      });
    });
    if (middlePath.length > 2) {
      context.beginPath();
      context.moveTo(commonStartX + 125, commonStartY + 25); // Start from the common start node
      context.lineTo(commonEndX, commonStartY + 25); // Connect to common end node
      context.lineWidth = 10;
      context.strokeStyle = '#ebb844';
      context.setLineDash([20, 25]);
      context.stroke();
    }

    rows.forEach((row, rowIndex) => {
      const emptySpaces = maxNodes - row.length;
      const startPosition = emptySpaces / 2;
      row.forEach((node, nodeIndex) => {
        const adjustedIndex = startPosition + nodeIndex;

        // Skip drawing start and end nodes if they are not in the middle path (row index 3)
        if ((node === startNode || node === endNode) && rowIndex !== 3) return;
        // Determine x and y coordinates for the current node
        let x = xScale(adjustedIndex);
        const y = yScale(rowIndex);

        // Special handling for start and end nodes
        if (node === startNode) {
          x = xScale(0); // Place at the far left
        } else if (node === endNode) {
          x = xScale(maxNodes - 1); // Place at the far right
        }
        context.setLineDash([]);
        // Draw node (box with label)
        const nodeWidth = 125; // Width of the box
        const nodeHeight = 50; // Height of the box

        context.beginPath();
        context.rect(x, y, nodeWidth, nodeHeight);

        // Set fill color and fill the box
        context.fillStyle = 'white';
        context.fill();

        // Set stroke width for the box and draw the stroke
        context.lineWidth = 1; 
        context.strokeStyle = '#958e86';
        context.stroke();

        // Text styling
        context.font = '12px Arial'; // Example font styling
        context.fillStyle = 'black'; // Set text color
        if (node.length > 20) {
          let breakIndex = 20;
          for (let i = breakIndex; i >= 0; i--) {
            if (node[i] === '#' || node[i] === '/') {
              breakIndex = i;
              break;
            }
          }

          // Split the label into two lines at the last # or /
          const line1 = node.substring(0, breakIndex);
          const line2 = node.substring(breakIndex);

          context.fillText(line1, x + 10, y + 15); // Draw the first part of the label
          context.fillText(line2, x + 10, y + 30); // Draw the second part of the label
        } else {
          context.fillText(node, x + 10, y + 20); // Draw label inside the box
        }

        // Set line width for the node path lines (different from the box stroke)
        context.lineWidth = 3; // Example line width for the node path lines
        context.setLineDash([]); // Reset to solid line after drawing all lines
      });
    });

  }, []);

  return (
    <div className="p-4 bg-white bg-opacity-80">
      <h1 className="h1 banner">Happy Path</h1>
      <InfoBox title={"YourBiz's Happy Path"} description={'The shortest route, that the most people take, to your most desired outcome'} />
      <canvas ref={canvasRef}></canvas>
      <div>
    <SEO
      title="Happy Path"
      description="The fastest routes through your product to get to activation."
      url="/happypath"
    />
    </div>
    </div>
  );
};

export default HappyPath;
