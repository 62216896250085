
// Sankey Functions
// nodeHeight = ƒ(node)
// nodeFill = ƒ(node)
// linkStroke = ƒ(link)
// linkFill = ƒ(link)
// linkStrokeWidth = ƒ(link)
// nodeWidth = ƒ(node)
// sankey = ƒ(…)
// sankeyLink = ƒ()

// Dimensions
// rotate = true
// dx = 15
// svgWidth = 1000
// svgHeight = 600
// chartPadding = 75
// chartHeight = 450
// chartWidth = 850

// JS libraries and functions
// d3 = Object {format: ƒ(t), formatPrefix: ƒ(t, n), timeFormat: ƒ(t), timeParse: ƒ(t), utcFormat: ƒ(t), utcParse: ƒ(t), FormatSpecifier: ƒ(t), active: ƒ(t, n), arc: ƒ(), area: ƒ(), areaRadial: ƒ(), ascending: ƒ(t, n), autoType: ƒ(t), axisBottom: ƒ(t), axisLeft: ƒ(t), axisRight: ƒ(t), axisTop: ƒ(t), bisect: ƒ(…), bisectLeft: ƒ(…), bisectRight: ƒ(…), …}
// import {form} from "@mbostock/form-input"
// import {colorPicker} from "@shaunlebron/color-picker"
// clone = ƒ(f, s, a, h, c)
// import {toc} from "@tomshanley/indented-toc"
// import {tweet} from "@mbostock/tweet"

import React, { useState, useEffect, useMemo } from 'react';
import { scaleOrdinal } from "d3-scale";
import { select } from "d3-selection"; 
import * as d3 from 'd3';  // Continues to import the main D3 library
import { sankeyCircular as d3Sankey, sankeyJustify } from 'd3-sankey-circular';
// import { sankey as d3Sankey, sankeyLinkHorizontal, sankeyJustify } from 'd3-sankey';
// import * as d3 from 'd3'; // This imports the main D3 library
import { useDataset } from '../components/DataFetcher';
import LoadingSpinner from '../components/LoadingSpinner';
import SEO from '../components/SEO';

function SankeyDiagramComponent() {
  const { data, status, error } = useDataset();

  const [sankeyData, setSankeyData] = useState(null);

  const markovChainData = useMemo(() => {
    if (data && data.MarkovChain) {
      return parseMarkovChainData(data.MarkovChain);
    }
  }, [data?.MarkovChain]);

  const width = window.innerWidth * .8
  const height = window.innerHeight * 2

  useEffect(() => {
    if (markovChainData) {
      console.log('markovChainData:', markovChainData);
      const transitions = transformDataForSankey(markovChainData);
      console.log('transitions:', transitions);
      const aggregatedTransitions = aggregateTransitions(transitions);
      console.log('aggregatedTransitions:', aggregatedTransitions);
      const { filteredTransitions, lowProbabilityTransitions } = filterLowProbabilityTransitions(aggregatedTransitions, 0.5);
      console.log('filteredTransitions:', filteredTransitions);
      console.log('lowProbabilityTransitions:', lowProbabilityTransitions);
      const stateNames = mapStateNames(Object.keys(aggregatedTransitions));
      // console.log('stateNames:', stateNames);
      // const probabilities = scaleProbabilities(filteredTransitions, lowProbabilityTransitions); // Adjusted call
      scaleProbabilities(filteredTransitions, lowProbabilityTransitions); // Adjusted call
      // console.log('probabilities:', probabilities);
      console.log('scaled filteredTransitions:', filteredTransitions);
      console.log('scaled lowProbabilityTransitions:', lowProbabilityTransitions);

      const { nodes, links } = prepareSankeyData(filteredTransitions, lowProbabilityTransitions);
      console.log('nodes:', nodes);
      console.log('links:', links);
      // const filteredLinks = findAndRemoveWeakestLink(links);
      // console.log('filteredLinks returned:', filteredLinks);
      // console.log('final link maps:', filteredLinks.map(filteredLinks => `${filteredLinks.source} -> ${filteredLinks.target}`));
      // const uncircularLinks = removeCircularLinks(filteredLinks);
      // console.log('uncircularLinks returned:', uncircularLinks);

      const activeNodes = filterActiveNodes(nodes, links);
      console.log('Active nodes:', activeNodes);
      
      
      if (Array.isArray(activeNodes) && Array.isArray(links)) {
      const { sankeyGenerator, graph} = createSankeyData(activeNodes, links, width, height);
      console.log('sankeyGenerator:', sankeyGenerator);
      console.log('graph:', graph);
      const adjustedGraph = adjustNodePositions(graph);
      updateLinkCoordinates(adjustedGraph);
      const pathGraph = calculatePathData(adjustedGraph);
      const finalGraph = calculateExitPathways(pathGraph, width, height);

      updateSankeyGenerator(sankeyGenerator, finalGraph)

      const colorGraph = applyColorsToGraph(finalGraph)

      const percentageLinks = formatPercentage(colorGraph.links)
      console.log('percentageLinks:', percentageLinks);

      const percentageGraph = {
        nodes: finalGraph.nodes,
        links: percentageLinks
      };

      drawSankey(percentageGraph, window.innerWidth * 0.8, window.innerHeight * 2.5)
      } else {
        console.error("Data not ready or invalid");
      }
    }
  }, [markovChainData]);


  if (status === 'loading') return <LoadingSpinner />;

  if (error) return <div>Error loading data!</div>;

  return (
    <svg width="100%" height="600" style={matchStyling()}>
      {sankeyData && sankeyData.nodes.map((node, i) => (
        <g key={node.name}>
          <rect
            x={node.x0}
            y={node.y0}
            width={node.x1 - node.x0}
            height={node.y1 - node.y0}
            fill="#9b59b6"
            onMouseOver={() => debounceHoverInteractions(node)}
            onClick={() => handleClickInteractions(node)}
          />
          <text x={(node.x0 + node.x1) / 2} y={node.y0 - 10} textAnchor="middle">
            {node.name}
          </text>
        </g>
      ))}
      {sankeyData && sankeyData.links.map((link, i) => (
        <path
          key={i}
          d={d3Sankey()(link)}
          style={{ fill: "none", strokeOpacity: 0.5, stroke: "#3498db", strokeWidth: Math.max(1, link.width) }}
        />
      ))}
    </svg>
  );
}

export default SankeyDiagramComponent;

// Define the color scale
const colorScale = d3.scaleLinear()
  .domain([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]) // Percentage breakpoints
  .range([
    '#2c294b', // dkpurple
    '#3b3484', // mdpurple
    '#7174b0', // ltpurple
    '#de6736', // dkorange
    '#e08a3c', // mdorange
    '#ebb844', // ltorange
    '#762861', // cranberry
    '#c5316a', // magenta
    '#ebe5df', // ltgray
    '#958e86', // mdgray
    '#80ba55'  // mdgreen
  ])
  .interpolate(d3.interpolateRgb);

// Assign colors based on the percentage for both nodes and links
function applyColorsToGraph(graph) {
  // Process links
  graph.links.forEach(link => {
    const percentage = parseFloat(link.value);
    link.color = isNaN(percentage) ? '#000000' : colorScale(percentage);
  });

  // Process nodes
  graph.nodes.forEach(node => {
    const percentage = parseFloat(node.value);
    node.color = isNaN(percentage) ? '#000000' : colorScale(percentage);
  });

  return graph;
}

function parseMarkovChainData(dataset) {
  if (typeof dataset !== 'string') {
    console.error('Invalid or missing MarkovChain data:', dataset);
    return null;
  }

  try {
    const parsedData = JSON.parse(dataset);
    if (!parsedData || !Array.isArray(parsedData.data) || !Array.isArray(parsedData.columns)) {
      console.error('Invalid MarkovChain data structure:', parsedData);
      return null;
    }
    return parsedData;
  } catch (error) {
    console.error('Failed to parse MarkovChain data:', error);
    return null;
  }
}

function transformDataForSankey(markovChainData) {
  if (!markovChainData || !Array.isArray(markovChainData.data)) {
    console.error('Invalid or missing MarkovChain data:', markovChainData);
    return [];  // Return an empty array if data is not structured as expected
  }

  const columnIndices = {
    source: markovChainData.columns.indexOf("From"),
    target: markovChainData.columns.indexOf("To"),
    value: markovChainData.columns.indexOf("Probability")
  };

  if (columnIndices.source === -1 || columnIndices.target === -1 || columnIndices.value === -1) {
    console.error('Missing required columns in MarkovChain data:', markovChainData.columns);
    return [];
  }

  const transitions = markovChainData.data.map(row => ({
    source: row[columnIndices.source],
    target: row[columnIndices.target],
    value: row[columnIndices.value]
  }));

  return transitions;
}

function aggregateTransitions(transitions) {
  const aggregatedTransitions = {};
  transitions.forEach((transition) => {
    if (transition.source !== transition.target) { 
      if (!aggregatedTransitions[transition.source]) {
        aggregatedTransitions[transition.source] = [];
      }
      aggregatedTransitions[transition.source].push({
        to: transition.target,
        value: transition.value
      });
    }
  });
  return aggregatedTransitions;
}

function filterLowProbabilityTransitions(aggregatedTransitions, percentage) {
  const filteredTransitions = {}; // Store transitions above the calculated cutoff
  const lowProbabilityTransitions = {}; // Store transitions below the calculated cutoff

  for (const source in aggregatedTransitions) {
    if (aggregatedTransitions.hasOwnProperty(source)) {
      const allTransitions = aggregatedTransitions[source];
      
      // Calculate the cutoff based on the desired percentage
      const cutoffIndex = Math.floor(allTransitions.length * (percentage / 100));
      const sortedTransitions = [...allTransitions].sort((a, b) => a.value - b.value);
      const cutoffValue = sortedTransitions[cutoffIndex]?.value || 0;
      
      filteredTransitions[source] = allTransitions.filter(transition => transition.value > cutoffValue);
      lowProbabilityTransitions[source] = allTransitions.filter(transition => transition.value <= cutoffValue);
    }
  }

  return { filteredTransitions, lowProbabilityTransitions };
}

function prepareSankeyData(filteredTransitions, lowProbabilityTransitions) {
  const nodeSet = new Set();

  // Helper function to add nodes from a transitions object
  const addNodesFromTransitions = (transitions) => {
    Object.keys(transitions).forEach(key => {
      nodeSet.add(key);  // Add the source path
      transitions[key].forEach(transition => {
        nodeSet.add(transition.to);  // Use 'to' as the target path
      });
    });
  };

  // Add nodes from both sets of transitions
  addNodesFromTransitions(filteredTransitions);
  addNodesFromTransitions(lowProbabilityTransitions);

  const nodes = Array.from(nodeSet).map((node, index) => ({
    id: index,  // IDs are unique and index-based
    name: node  // Node name is the path
  }));

  // Function to create links from transitions
  const createLinks = (transitions) => {
    const links = [];
    Object.keys(transitions).forEach(source => {
      transitions[source].forEach(transition => {
        const target = transition.to;  // Extracting 'to' as target
        const value = transition.value;  // Ensuring 'value' is directly accessed
        if (value === undefined) {
          console.error('Undefined transition value from:', source, 'to:', target);
        }
        links.push({
          source: source,  // Use the source name directly
          target: target,  // Use the target name directly
          value: value
        });
      });
    });
    return links;
  };

  // Create links only from filteredTransitions
  const links = createLinks(filteredTransitions);

  return { nodes, links };
}

function formatPercentage(links) {
  return links.map(link => {
    if (typeof link.value === 'number') {
      const percentage = Math.round(link.value * 1000) / 10;
      link.value = percentage.toFixed(1) + '%';
    } else {
      console.error('Invalid value type for link:', link);
      link.value = 'N/A';  // Handle non-numeric values gracefully
    }
    return link;
  });
}


function removeCircularLinks(links) {
  let uniqueLinks = [];
  let linkPairs = {};

  // Store all links and reverse links in an object to easily find pairs
  links.forEach(link => {
      const forwardKey = `${link.source.id}->${link.target.id}`;
      const reverseKey = `${link.target.id}->${link.source.id}`;

      if (linkPairs[reverseKey]) {
          // There is a reverse link, determine which to keep
          if (linkPairs[reverseKey].value < link.value) {
              // Keep the current link, remove the weaker reverse link from uniqueLinks
              uniqueLinks = uniqueLinks.filter(l => l !== linkPairs[reverseKey]);
              uniqueLinks.push(link);
          } else {
              // Keep the existing stronger reverse link, do not add the current link
          }
          // Remove the pair from tracking as it's resolved now
          delete linkPairs[reverseKey];
      } else {
          // No reverse link yet, add this link to tracking
          linkPairs[forwardKey] = link;
          uniqueLinks.push(link);
      }
  });

  return uniqueLinks;
}

function filterActiveNodes(nodes, links) {
  console.log("filterActiveNodes nodes:", nodes);
  console.log("filterActiveNodes nodes:", links);

  const activeNodeNames = new Set();

  links.forEach(link => {
    activeNodeNames.add(link.source);
    activeNodeNames.add(link.target);
  });

  const activeNodes = nodes.filter(node => activeNodeNames.has(node.name));
  console.log("Active nodes:", activeNodes);

  return activeNodes;
}

// function updateSankeyGenerator(sankeyGenerator, graph) {
  
//   sankeyGenerator
//     .nodes(graph.nodes)
//     .links(graph.links);

//   sankeyGenerator();  // Recompute the layout with the new data

//   return sankeyGenerator;
// }
function createSankeyData(nodes, links, width, height) {
  if (!Array.isArray(nodes) || !Array.isArray(links)) {
    console.error("Invalid input: 'nodes' or 'links' is not an array.");
    return;
  }

  // Create a mapping of node names to their existing indices
  const nodeNameToIndex = {};
  nodes.forEach(node => {
    // Ensure each node has an `index` property
    if (typeof node.index !== "number") {
      console.error(`Node '${node.name}' is missing an index.`);
      return;
    }
    nodeNameToIndex[node.name] = node.index;
  });

  // Log the mappings to verify correctness
  console.log("Node Name to Index Mapping:", nodeNameToIndex);
  console.log("Nodes with Existing Index:", nodes);

  // Process links using the pre-existing indices
  const validLinks = links.map(link => {
    const sourceIndex = nodeNameToIndex[link.source];
    const targetIndex = nodeNameToIndex[link.target];

    // Log issues if the source or target isn't found in the mapping
    if (sourceIndex === undefined) {
      console.error(`Source node not found: ${link.source}`);
    }
    if (targetIndex === undefined) {
      console.error(`Target node not found: ${link.target}`);
    }

    // If either source or target index is undefined, discard the link
    if (sourceIndex === undefined || targetIndex === undefined) {
      return null;
    }

    // Ensure the link value is numeric
    const value = parseFloat(link.value);
    if (isNaN(value)) {
      console.error(`Invalid value for link from ${link.source} to ${link.target}: ${link.value}`);
      return null;
    }

    return {
      source: sourceIndex,
      target: targetIndex,
      value: value
    };
  }).filter(link => link !== null); // Remove any invalid links

  // Log valid links for verification
  console.log("Final check before Sankey layout validLinks:", validLinks);
  console.log("Final check before Sankey layout nodes:", nodes);

  // Initialize the Sankey generator with indices
  const sankeyGenerator = d3Sankey()
    .nodeWidth(30)
    .nodePadding(30)
          //.nodePaddingRatio(0.5) overrides nodepadding
    .nodeAlign(sankeyJustify)
    .size([width, height])
    .nodeId(function (d) {
      return d.index;
    })
    .iterations(16)
    .circularLinkGap(4)
    .sortNodes("value");

  // Pass the nodes and links to the generator, which will update node positions
  const graph = sankeyGenerator({
    nodes: nodes,
    links: validLinks
  });

  console.log("createSankeyData updatedGraph after layout:", graph);
  console.log("createSankeyData updatedGraph.Nodes after layout:", graph.nodes);
  console.log("createSankeyData updatedGraph.Links after layout:", graph.links);

  // Return the fully processed graph data
  return {
    sankeyGenerator,
    graph: {
      nodes: graph.nodes, // Nodes should have layout properties set
      links: graph.links  // Links should have layout properties set
    }
  };
}

// function createSankeyData(nodes, links, width, height) {
//   if (!Array.isArray(nodes) || !Array.isArray(links)) {
//     console.error("Invalid input: 'nodes' or 'links' is not an array.");
//     return; // Exit the function or handle the error as needed
//   }

//   // Assign indices to nodes explicitly
//   console.log("createSankeyData Nodes before processing:", nodes);
//   const indexedNodes = nodes.map((node, index) => ({ ...node, index }));

//   // Calculate node values by summing the values of links for each node
//   indexedNodes.forEach(node => {
//     node.value = links.reduce((acc, link) => {
//       if (link.source === node.name || link.target === node.name) {
//         return acc + parseFloat(link.value); // Ensure link.value is a number
//       }
//       return acc;
//     }, 0);
//   });

//   console.log("createSankeyData indexedNodes:", indexedNodes);

//   const indexedLinks = links.map(link => {
//     const sourceIndex = indexedNodes.findIndex(n => n.name === link.source);
//     const targetIndex = indexedNodes.findIndex(n => n.name === link.target);
//     if (sourceIndex === -1 || targetIndex === -1) {
//       console.error("Invalid link due to missing node:", link);
//     }
//     return {
//       source: sourceIndex,
//       target: targetIndex,
//       value: parseFloat(link.value) // Ensure link.value is treated as a number
//     };
//   });

//   if (indexedLinks.some(link => link.source === link.target)) {
//     console.error("Self-referencing link detected", indexedLinks.find(link => link.source === link.target));
//   }

//   console.log("createSankeyData indexedLinks:", indexedLinks);

//   const validLinks = indexedLinks.filter(link => link.source !== -1 && link.target !== -1 && link.source !== link.target);
//   console.log("Final check before Sankey layout validLinks:", validLinks);

//   const sankeyGenerator = d3Sankey()
//     .nodeWidth(30)
//     .nodePadding(30)
//     .nodeAlign(sankeyJustify)
//     .size([width, height])
//     .nodes(indexedNodes)
//     .links(validLinks)
//     .nodeId(d => d.index)  // Explicitly use node index for identifying nodes
//     .circularLinkGap(5)  // Adjust gap between adjacent circular links
//     .sortNodes(null);

//   // const sankeyGenerator = d3Sankey()
//   //   .nodeWidth(30)
//   //   .nodePadding(30)
//   //   .nodeAlign(sankeyJustify)  // Use sankeyJustify for node alignment
//   //   .size([width, height])  // Sets the size of the Sankey diagram
//   //   .nodes(indexedNodes)  // Pass indexed nodes
//   //   .links(validLinks)   // Pass indexed links
//   //   .nodeId(d => d.index);  // Explicitly use node index for identifying nodes

//   const updatedGraph = sankeyGenerator();  // This computes the layout based on nodes and links provided
//   console.log("createSankeyData updatedGraph after layout:", updatedGraph);
//   console.log("createSankeyData updatedGraph.Nodes after layout:", updatedGraph.nodes);
//   console.log("createSankeyData LupdatedGraph.inks after layout:", updatedGraph.links);

//   // Return both the generator and the fully processed graph data
//   return {
//     sankeyGenerator,
//     graph: {
//       nodes: updatedGraph.nodes, // This should retrieve the nodes with layout properties set
//       links: updatedGraph.links  // This should retrieve the links with layout properties set
//     }
//   };
// }

// function createSankeyData(nodes, links, width, height) {
//   if (!Array.isArray(nodes) || !Array.isArray(links)) {
//     console.error("Invalid input: 'nodes' or 'links' is not an array.");
//     return null;
//   }

//   console.log("Input Nodes:", nodes);
//   console.log("Input Links:", links);

//   // Assign indices explicitly while ensuring nodes are unique
//   const indexedNodes = nodes.map((node, index) => ({ ...node, index }));
//   console.log("Indexed Nodes:", indexedNodes);

//   // Calculate node values based on linked values
//   indexedNodes.forEach(node => {
//     node.value = links.reduce((acc, link) => {
//       if (link.source === node.name || link.target === node.name) {
//         const linkValue = parseFloat(link.value);
//         if (isNaN(linkValue)) {
//           console.warn(`Invalid link value for node ${node.name}:`, link.value);
//           return acc;
//         }
//         return acc + linkValue;
//       }
//       return acc;
//     }, 0);
//   });

//   console.log("Node Values:", indexedNodes);

//   // Map links using node names to source and target indices
//   const indexedLinks = links.map(link => {
//     const sourceIndex = indexedNodes.findIndex(n => n.name === link.source);
//     const targetIndex = indexedNodes.findIndex(n => n.name === link.target);

//     if (sourceIndex === -1 || targetIndex === -1) {
//       console.error("Invalid link due to missing node:", link);
//       return null;
//     }

//     return {
//       source: sourceIndex,
//       target: targetIndex,
//       value: parseFloat(link.value),
//       circular: link.circular || false
//     };
//   }).filter(link => link !== null);

//   console.log("Indexed Links:", indexedLinks);

//   // Initialize the circular Sankey generator with proper mapping
//   const sankeyGenerator = d3Sankey()
//     .nodeWidth(30) // Fixed value for troubleshooting
//     .nodePadding(20) // Fixed value for troubleshooting
//     .nodeAlign(sankeyJustify) // Justify alignment
//     .size([width, height])
//     .nodeId(d => d.index) // Use node indices explicitly
//     .nodes(indexedNodes)
//     .links(indexedLinks)
//     .circularLinkGap(4) // Example gap value between circular links
//     .iterations(64); // Increased iterations to refine node positioning

//   try {
//     const updatedGraph = sankeyGenerator();
//     console.log("Generated Graph Nodes:", updatedGraph.nodes);
//     console.log("Generated Graph Links:", updatedGraph.links);

//     // Verify coordinates for NaN values
//     updatedGraph.nodes.forEach(node => {
//       if (isNaN(node.x0) || isNaN(node.y0) || isNaN(node.x1) || isNaN(node.y1)) {
//         console.warn(`Node ${node.name} has invalid coordinates. x0: ${node.x0}, x1: ${node.x1}, y0: ${node.y0}, y1: ${node.y1}`);
//       }
//     });

//     updatedGraph.links.forEach(link => {
//       if (isNaN(link.y0) || isNaN(link.y1)) {
//         console.warn(`Link has invalid coordinates. y0: ${link.y0}, y1: ${link.y1}`);
//       }
//     });

//     return {
//       sankeyGenerator,
//       graph: {
//         nodes: updatedGraph.nodes,
//         links: updatedGraph.links
//       }
//     };
//   } catch (error) {
//     console.error("Error during Sankey layout generation:", error);
//     return null;
//   }
// }





// Function to debug and fix Sankey links
function validateSankeyLinks(graph) {
  console.log(`Started validating Sankey links:`);

  graph.links.forEach(link => {
    // Destructure properties from the current link object
    const { source, target, value, index, circular, d, path, y0, y1 } = link;

    console.log(`Validating link [Index ${index}]:`, link);

    // Check if the source and target nodes exist in the graph
    if (!source || !target) {
      console.error(`Missing source or target for link:`, link);
      return;
    }

    // Check if the `d` attribute is explicitly undefined
    if (typeof d === 'undefined') {
      console.warn(`Missing 'd' attribute for link [Index ${index}]:`, link);
    } else {
      console.log(`Link 'd' attribute found [Index ${index}]:`, d);
    }

    // Check if the source and target exist in the graph
    if (value === undefined) {
      console.error(`Missing value for link:`, link);
      return;
    }
     
    // Check if the source and target exist in the graph
    if (index === undefined) {
      console.error(`Missing value for index:`, link);
      return;
    }

    // Log detailed info about the source and target nodes
    console.log(`Source node:`, source);
    console.log(`Target node:`, target);

    // Log link details
    console.log(`Link details - circular: ${circular}, value: ${value}, y0: ${y0}, y1: ${y1}`);
    console.log(`Link path: ${path}`);
    console.log(`Link d attribute: ${d}`);

    // Log circular-specific information
    if (circular) {
      // console.log(`Circular Link [Index ${index}]:`);
      console.log(`Circular Link ${index}`);
      console.log(`Circular Source Node: ${source.name}, Target Node: ${target.name}`);
      console.log(`Circular Path Data: ${path}`);
      console.log(`Circular Link Value: ${value}`);
    }

    // Check for possible NaN coordinates or invalid width
    if (isNaN(y0) || isNaN(y1) || isNaN(parseFloat(value)) || isNaN(link.width)) {
      console.warn(`Link contains invalid coordinates or value:`, link);
    }
  });
}

function updateSankeyGenerator(sankeyGenerator, graph) {
  // Check for any links with invalid source or target indices
  const validLinks = graph.links.filter(link => {
    const sourceExists = graph.nodes[link.source] !== undefined;
    const targetExists = graph.nodes[link.target] !== undefined;
    if (!sourceExists || !targetExists) {
      console.error("Invalid link detected:", link);
      return false;
    }
    return true;
  });

  // Reassign the valid links back to the graph
  const updatedGraph = {
    ...graph,
    links: validLinks
  };

  // Update the nodes and links for the Sankey generator
  sankeyGenerator
    .nodes(updatedGraph.nodes)
    .links(updatedGraph.links);

  try {
    // Recompute the layout with the new data
    sankeyGenerator();
    return sankeyGenerator;
  } catch (error) {
    console.error("Error updating the Sankey generator:", error);
    return null;
  }
}

function findCycles(node, path, globalVisited, localVisited, nodesMap) {
  if (localVisited.has(node)) {
    return path.slice(path.indexOf(node));
  }
  globalVisited.add(node);
  localVisited.add(node);
  const targets = nodesMap.get(node) || [];
  for (const link of targets) {
    const cycle = findCycles(link.target, path.concat(link), globalVisited, new Set(localVisited), nodesMap);
    if (cycle) return cycle;
  }
  return null;
}

function findAndRemoveWeakestLink(links) {
  const nodesMap = new Map();

  links.forEach(link => {
    if (!nodesMap.has(link.source)) {
      nodesMap.set(link.source, []);
    }
    nodesMap.get(link.source).push(link);
  });

  console.log('Initial nodesMap:', nodesMap);

  let cycleChanges;
  do {
    cycleChanges = false;
    const allCycles = [];
    const globalVisited = new Set();

    nodesMap.forEach((_, node) => {
      if (!globalVisited.has(node)) {
        const cycle = findCycles(node, [], globalVisited, new Set(), nodesMap);
        if (cycle) {
          allCycles.push(cycle);
        }
      }
    });

    console.log('Detected Cycles:', allCycles);
    allCycles.forEach(cycle => {
      const weakest = cycle.reduce((min, link) => link.value < min.value ? link : min, cycle[0]);
      const index = links.indexOf(weakest);
      if (index !== -1) {
        links.splice(index, 1);
        cycleChanges = true;
        console.log('Removed Link:', weakest);
      }
    });
  } while (cycleChanges);

  console.log('Final links:', links);
  return links;
}

function adjustNodePositions(graph) {
  const bottomGapX = 2;
  const throughlineStart = 2;

  graph.nodes.forEach(node => {
      if (node.depth === throughlineStart) {
          // Ensure y0 and y1 are adjusted based on a computed 'y' value
          let newY = updateNodeY(node, node.dy); // Assume this function properly calculates a new 'y'
          let height = node.y1 - node.y0;
          node.y0 = newY - height / 2;
          node.y1 = newY + height / 2;
      } else if (node.targetLinks.length === 0) {  // Adjust x for exit nodes
          node.x0 += bottomGapX;
          node.x1 += bottomGapX;
      }
  });

  return graph;
}

function updateNodeY(node, dy) {
  // Custom logic to update y based on dy or other criteria
  return node.y + dy;  // Example modification
}

function updateLinkCoordinates(graph) {
  console.log("updateLinkCoordinates init:");

  // Map nodes by their IDs for quick lookup
  const nodeMap = new Map(graph.nodes.map(node => [node.id, node]));

  // Update each link's y0 and y1 based on its source and target node positions
  graph.links.forEach(link => {
      const sourceNode = nodeMap.get(link.source.id);
      const targetNode = nodeMap.get(link.target.id);

      if (sourceNode && targetNode) {
          // Calculate vertical center of source and target nodes
          link.y0 = sourceNode.y0 + (sourceNode.y1 - sourceNode.y0) / 2;
          link.y1 = targetNode.y0 + (targetNode.y1 - targetNode.y0) / 2;

          // Assign the curved path to link.d using the updated positions
          link.d = createCurvedPath(link, sourceNode, targetNode);
      } else {
          console.error("Source or target node not found for link", link);
      }
  });
}

function createCurvedPath(link, sourceNode, targetNode) {
  const curvature = 0.5;
  const x0 = sourceNode.x1, y0 = link.y0;
  const x1 = targetNode.x0, y1 = link.y1;
  const xi = d3.interpolateNumber(x0, x1);
  const x2 = xi(curvature), x3 = xi(1 - curvature);
  return `M${x0},${y0} C${x2},${y0} ${x3},${y1} ${x1},${y1}`;
}

function createLargeLinkPath(link) {
  console.log("Creating large link path for link from", link.source.name, "to", link.target.name);

  // Calculate midpoints for the cubic Bezier curve control points
  const midX = (link.source.x1 + link.target.x0) / 2;
  const controlX1 = link.source.x1 + (midX - link.source.x1) / 3;
  const controlX2 = link.target.x0 - (link.target.x0 - midX) / 3;

  // Construct the SVG path using a cubic Bezier curve
  const path = `M${link.source.x1},${link.source.y1} C${controlX1},${link.source.y1} ${controlX2},${link.target.y0} ${link.target.x0},${link.target.y0}`;
  console.log("Generated path:", path);

  return path;
}

function calculatePathData(sankeyData) {
  console.log("Starting path data calculation");

  if (!sankeyData || !Array.isArray(sankeyData.links)) {
    console.error("Invalid or missing sankeyData links");
    return sankeyData;
  }

  const largeValueThreshold = 100; // Threshold to decide if a link is 'large'
  sankeyData.links.forEach(link => {
    if (!link.source || !link.target) {
      console.error("Link missing source or target", link);
      return;
    }

    // console.log(`Processing link from ${link.source.name} to ${link.target.name} with width ${link.width}`);
    if (link.width > largeValueThreshold) {
      console.log("Applying large link path...");
      link.d = createLargeLinkPath(link);
    } else if (!link.d) {
      console.warn("Missing path data for link, applying fallback:", link.d);
      link.d = createCurvedPath(link, link.source, link.target);
      // link.d = `M${link.source.x1},${link.source.y1}L${link.target.x0},${link.target.y0}`;
    }
  });

  console.log("Path data calculation completed");
  return sankeyData;
}

 function mapStateNames(states) {
   const stateNames = {};
   states.forEach((state) => {
     stateNames[state] = `State ${state}`;
   });
   return stateNames;
 }
 
 function scaleProbabilities(filteredTransitions) {
  let allProbabilities = [];
  // Iterate over each source node to access each array of transitions
  for (const source in filteredTransitions) {
    if (filteredTransitions.hasOwnProperty(source)) {
      // Extract probabilities and scale them
      const sourceProbabilities = filteredTransitions[source].map(transition => transition.value * 100);
      allProbabilities = allProbabilities.concat(sourceProbabilities);
    }
  }
  return allProbabilities;
}

 function debounceHoverInteractions() {
  // Debounce hover interactions
  return setTimeout(() => {}, 100);
}

 function handleClickInteractions(node) {
   // Highlight the selected band and dimming others using CSS pseudo-classes (:hover, :active)
   return node.fill = "rgba(0, 255, 0, 0.5)";
 }

 function matchStyling() {
  // Match the styling to the pseudo-3D aesthetic applying necessary CSS and SVG styles
  return {
    ".sankey-diagram": {
      position: "relative",
      width: "100%",
      height: "500px"
    },
    ".node": {
      fill: "#fff",
      stroke: "#333",
      strokeWidth: 2
    }
  };
}

function truncateText(text, maxLength) {
  return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
}

// function drawSankey(finalGraph, width, height) {
//   const nodeWidth = 30; // This should be the same width set in your sankeyGenerator

//   const svg = d3.select('svg')
//     .attr('width', width)
//     .attr('height', height)
//     .style('overflow', 'visible');

//   svg.append('defs').html(subtleGridOrLinePattern());

//   // Drawing nodes
//   const nodes = svg.append('g')
//   .selectAll('rect')
//   .data(finalGraph.nodes)
//   .enter().append('rect')
//     .attr('x', d => d.x0)
//     .attr('y', d => d.y0)
//     .attr('height', d => d.y1 - d.y0)
//     .attr('width', nodeWidth)  // Use a constant value for width
//     .style('fill', d => d.color)

// nodes.append('title').text(d => `${d.name}\n${d.value}`);

// svg.append('g').selectAll('text')
//   .data(finalGraph.nodes)
//   .enter().append('text')
//     .attr('x', d => (d.x0 + d.x1) / 2)
//     .attr('y', d => (d.y0 + d.y1) / 2)
//     .attr('text-anchor', 'middle')
//     .attr('dominant-baseline', 'central')
//     .attr('transform', d => `rotate(-90, ${(d.x0 + d.x1) / 2}, ${(d.y0 + d.y1) / 2})`)
//     .text(d => truncateText(d.name, 20))  // Use the truncateText function here
//     .style('fill', 'white')
//     .style('font-size', '12px');  // Set the font size smaller

//     const links = svg.append('g')
//     .attr('fill', 'none')
//     .attr('stroke-opacity', 0.5)
//     .selectAll('path')
//     .data(finalGraph.links)
//     .enter().append('path')
//     .attr("d", function(d) {
//       // Debugging the path attribute
//       console.log(`Drawing path for link: source=${d.source.name}, target=${d.target.name}`);
//       console.log(`Computed path data (d): ${d.d}`);
//       return d.d; // Set the 'd' attribute for drawing the path
//     })
//     .style('stroke', d => {
//       // Debugging the color style
//       console.log(`Setting stroke color for link: ${d.source.name} → ${d.target.name}, color=${d.color}`);
//       return d.color;
//     })
//     .style('stroke-width', d => {
//       // Debugging the stroke width
//       console.log(`Setting stroke width for link: ${d.source.name} → ${d.target.name}, width=${d.width}`);
//       return Math.max(1, d.width);
//     });
  
//   // Add titles to links for tooltip-like behavior
//   links.append('title').text(d => `${d.source.name} → ${d.target.name}\n${d.value}`);
  
// }

// function subtleGridOrLinePattern() {
//   // Create a subtle grid or line pattern in the background using SVG patterns for spatial orientation assistance
//   return `<pattern id="grid" width="10" height="10">
//      <rect x="0" y="0" width="10" height="10" fill="#fff" stroke="#333" strokeWidth="1"></rect>
//    </pattern>`;
// }

function drawSankey(finalGraph, width, height) {
  const nodeWidth = 30; // This should be consistent with sankeyGenerator

  // Configure the main SVG
  const svg = d3.select('svg')
    .attr('width', width)
    .attr('height', height)
    .style('overflow', 'visible');

  // Add subtle grid or line pattern
  svg.append('defs').html(subtleGridOrLinePattern());

  // Create a zoomable group to hold the diagram
  const zoomGroup = svg.append('g').attr('class', 'zoom-group');

  // Configure the zoom behavior
  const zoom = d3.zoom()
    .scaleExtent([0.5, 10]) // Minimum and maximum zoom scales
    .on('zoom', (event) => {
      zoomGroup.attr('transform', event.transform); // Apply zoom transformation
    });

  // Attach the zoom behavior to the main SVG, but transform only the zoom group
  svg.call(zoom).on('wheel.zoom', null); // Disable default wheel zoom for better control

  // Draw the nodes inside the zoom group
  const nodes = zoomGroup.append('g')
    .selectAll('rect')
    .data(finalGraph.nodes)
    .enter().append('rect')
    .attr('x', d => d.x0)
    .attr('y', d => d.y0)
    .attr('height', d => d.y1 - d.y0)
    .attr('width', nodeWidth)
    .style('fill', d => d.color);

  nodes.append('title').text(d => `${d.name}\n${d.value}`);

  // Add node labels
  zoomGroup.append('g').selectAll('text')
    .data(finalGraph.nodes)
    .enter().append('text')
    .attr('x', d => (d.x0 + d.x1) / 2)
    .attr('y', d => (d.y0 + d.y1) / 2)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'central')
    .attr('transform', d => `rotate(-90, ${(d.x0 + d.x1) / 2}, ${(d.y0 + d.y1) / 2})`)
    .text(d => truncateText(d.name, 20))
    .style('fill', 'white')
    .style('font-size', '12px');

  // Draw the links inside the zoom group
  const links = zoomGroup.append('g')
    .attr('fill', 'none')
    .attr('stroke-opacity', 0.5)
    .selectAll('path')
    .data(finalGraph.links)
    .enter().append('path')
    .attr("d", d => d.d)
    .style('stroke', d => d.color)
    .style('stroke-width', d => Math.max(1, d.width));

  links.append('title').text(d => `${d.source.name} → ${d.target.name}\n${d.value}`);
}

function subtleGridOrLinePattern() {
  return `<pattern id="grid" width="10" height="10">
    <rect x="0" y="0" width="10" height="10" fill="#fff" stroke="#333" strokeWidth="1"></rect>
  </pattern>`;
}


 function defineInteractiveStates() {
   // Define hover and click effects
   return {
     hover: (node) => {
       node.fill = "rgba(255, 0, 0, 0.5)";
     },
     click: (node) => {
       node.fill = "rgba(0, 0, 255, 0.5)";
     }
   };
 }
 
 function structureDiagram(sankey, width, height) {
   // Structure the Sankey diagram with panning up and down while maintaining a fixed left-right position
   return sankey;
 }
 
 function beginVisualizationFromTop() {
   // Simulate a flow starting at the center top (0, 50%) of the screen and flowing down
   return {
     x: 0,
     y: 50
   };
 }
 

 function calculateExitPathways(finalGraph, width, height) {
  // Determine the exit point coordinates (position off the main graph)
  const exitPadding = 20; // Padding outside the visible area for exit paths

  // Adjust nodes to have an off-chart exit position and create exit links
  finalGraph.nodes.forEach(node => {
    // Check if the node has no outgoing links or is otherwise an exit point
    const outgoingLinks = finalGraph.links.filter(link => link.source === node.index);

    if (outgoingLinks.length === 0) {
      // Adjust node's x1 and y1 coordinates to be off the visible area
      node.x0 += exitPadding;
      node.x1 = node.x0 + exitPadding;
      node.y0 = (node.y0 + node.y1) / 2;
      node.y1 = node.y0;

      // Create an exit link leading to an imaginary node off the chart
      finalGraph.links.push({
        source: node.index,
        target: -1, // Indicates an exit link
        value: node.value, // Use the remaining node value
        d: `M${node.x1},${node.y1} C${node.x1 + exitPadding},${node.y1} ${width + exitPadding},${height / 2}`, // Cubic Bezier path
        opacity: 0.2 // Reduced opacity to signify fading out
      });
    }
  });

  return finalGraph;
}


 
 // re-render the diagram on window resize events
 function ensureResponsiveness() {
   // Ensure the page and Sankey diagram are fully responsive and interactive
   return {
     width: "100%",
     height: "500px",
     overflowY: "auto"
   };
 }