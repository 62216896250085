import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from 'react';
import * as d3 from 'd3';
import { useDataset } from '../components/DataFetcher';
import LoadingSpinner from '../components/LoadingSpinner'; // Import the Spinner component
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import InfoBox from '../components/InfoBox';
import NodeBox from '../components/NodeBox';
import DijkstraPath from '../components/DijkstraPath';



const isGraphDataLoaded = () => {
  return !!document.querySelector('script[data-graph-positions]');
};

const replaceInvalidValues = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      replaceInvalidValues(obj[key]);
    } else if (
      Number.isNaN(obj[key]) ||
      obj[key] === null ||
      obj[key] === undefined
    ) {
      obj[key] = `node_${Math.random()}`;
    }
  }
};

const PathAnalyzer = ({ data }) => {
  const dimensions = useContext(DimensionContext);
  console.log('dimensions', dimensions);
  const svgRef = useRef();
  const simulationRef = useRef();
  const nodesRef = useRef([]);
  const linksRef = useRef([]);
  const [nodesState, setNodesState] = useState([]);
  const rScaleRef = useRef(null);
  const isDataFetchedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBaseDataLoaded, setIsBaseDataLoaded] = useState(false);
  const [selectedNodeIds, setSelectedNodeIds] = useState([]);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity);
  const [nodeBoxContent1, setNodeBoxContent1] = useState({ title: '', description: '' });
  const [nodeBoxContent2, setNodeBoxContent2] = useState({ title: '', description: '' });
  const [isNodeBox1Visible, setIsNodeBox1Visible] = useState(false);
  const [isNodeBox2Visible, setIsNodeBox2Visible] = useState(false);
  

  useEffect(() => {
    if (!isDataFetchedRef.current) {
      console.log("Fetching dataset...");
      // Fetch dataset here using useDataset
      setIsBaseDataLoaded(isGraphDataLoaded()); // This should now work correctly
      isDataFetchedRef.current = true;
    }
  }, []);

  const { data: dataset, status } = useDataset(!isDataFetchedRef.current);

  useEffect(() => {
    if (status === 'success') {
      isDataFetchedRef.current = true;
    }
  }, [status]);

  const { graphData, graphNodes, graphEdges, sources, targets } = useMemo(() => {
    if (!dataset || status !== 'success') {
      // Data isn't ready yet, return default values or handle as needed
      return {
        graphData: {},
        graphNodes: [],
        graphEdges: [],
        sources: [],
        targets: []
      };
    }
    // Process the dataset as before
    replaceInvalidValues(dataset.GraphPositions);
  
    let parsedData;

    if (typeof dataset.GraphPositions === 'string') {
      parsedData = JSON.parse(dataset.GraphPositions);
    } else {
      parsedData = dataset.GraphPositions;
    }
  
    console.log('Type of parsedData:', typeof parsedData);
    // Explicitly parse if parsedData is a string
    if (typeof parsedData === 'string') {
      parsedData = JSON.parse(parsedData);
    }

    const nodes = (parsedData?.nodes ?? []).filter((node) => node.id !== "Empty" && node.id !== "" && node.id !== null && node.id !== undefined
   );
    const edges = (parsedData?.edges ?? []).filter((edge) => 
    edge.source !== "Empty" && edge.source !== "" && edge.source !== null && edge.source !== undefined &&
    edge.target !== "Empty" && edge.target !== "" && edge.target !== null && edge.target !== undefined &&
    edge.source !== edge.target 
);


    // Extract and filter source and target from edges
    const sources = edges.map(edge => edge.source);
    const targets = edges.map(edge => edge.target);

    console.log('parsedData:', parsedData);
    console.log('nodes:', nodes);
    console.log('edges:', edges);
    console.log('sources:', sources);
    console.log('targets:', targets);
  
    return { 
      graphData: parsedData || {}, 
      graphNodes: nodes, 
      graphEdges: edges,
      sources,
      targets
    };
    
}, [dataset, status]);  

  const UniqueURL = useMemo(() => {
    return dataset && typeof dataset.UniqueURL === 'object' ? dataset.UniqueURL : {};
  }, [dataset]);

  const UniqueEventDesc = useMemo(() => {
    return dataset && typeof dataset.UniqueEventDesc === 'object' ? dataset.UniqueEventDesc : {};
  }, [dataset]);


  useEffect(() => {
    // When nodesRef.current is populated, update nodesState to trigger a re-render
    if (nodesRef.current.length > 0) {
      setNodesState(nodesRef.current);
    }
  }, [nodesRef.current.length]); // Trigger only when nodesRef.current.length changes
  
  // useEffect(() => {
  //     console.log('Current nodesState in LinkRef create:', nodesState);
  //     // console.log('Current nodesState length in LinkRef create:', nodesState.current.length);
  //   if (nodesState && nodesState.length > 0) {
  //     linksRef.current = graphEdges.map(d => ({
  //       source: nodesState.find(node => node.id === d.source),
  //       target: nodesState.find(node => node.id === d.target),
  //     }));
  //     console.log('After populating LinksRef - linksRef.current:', linksRef.current);
  //   }
  // }, [nodesState]);

  useEffect(() => {
    console.log('Current nodesState in LinkRef create:', nodesState);
  
    if (nodesState && nodesState.length > 0) {
      // Calculate weights and find min and max weights
      let minWeight = Infinity;
      let maxWeight = -Infinity;
  
      const linksWithWeights = graphEdges.map(d => {
        const sourceNode = nodesState.find(node => node.id === d.source);
        const targetNode = nodesState.find(node => node.id === d.target);
  
        let weight = Math.sqrt(
          Math.pow(targetNode.x - sourceNode.x, 2) +
          Math.pow(targetNode.y - sourceNode.y, 2)
        );
        // console.log('sourceNode', sourceNode);
        // console.log('targetNode', targetNode);
        // console.log('weight', weight);

        minWeight = Math.min(minWeight, weight);
        maxWeight = Math.max(maxWeight, weight);
  
        return {
          ...d,
          source: sourceNode,
          target: targetNode,
          weight: weight
        };
      });
  
      // Normalize weights to a 1-5 scale
      const normalizedLinks = linksWithWeights.map(link => {
        const distancerating = 1 + 4 * (link.weight - minWeight) / (maxWeight - minWeight);
        return {
          ...link,
          distancerating: Math.round(distancerating), // Round to the nearest integer
          weight: Math.round(link.weight) // Round to the nearest integer
        };
      });
  
      linksRef.current = normalizedLinks;
      console.log('After populating LinksRef - linksRef.current:', linksRef.current);
    }
  }, [nodesState]);
  
  
const handleNodeClick = useCallback((event, clickedNode, links) => {
  console.log('onClick fired with node:', clickedNode.id);

  const simulation = simulationRef.current;
  const nodes = nodesRef.current;

  if (!simulation || !nodes) {
    console.error('Simulation or nodes are not initialized');
    return;
  }

  // Helper to fix node position
  const fixNodePosition = (node, x, y) => {
    console.log(`Fixing node position for node ${node.id} to [${x}, ${y}]`);
    node.fx = x;
    node.fy = y;
  };

  // Helper to release node position
  const releaseNodePosition = (node) => {
    console.log(`Releasing node position for node ${node.id}`);
    node.fx = null;
    node.fy = null;
  };

  // Helper to restart simulation
  const restartSimulation = (alpha = 0.3) => {
    console.log(`Restarting simulation with alpha: ${alpha}`);
    simulation.alpha(alpha).restart();
  };

  // Safe check before using 'links'
  if (!Array.isArray(links)) {
    console.error('Invalid or undefined links data');
    return;
  }
  console.log('Just links:', links);
  console.log('Just linksRed:', linksRef);
  console.log('Just linksRed.current:', linksRef.current);

  const connectedLinks = links.filter(link => 
    link.source.id === clickedNode.id || link.target.id === clickedNode.id
  );

  const getColorForRating = (rating) => {
    switch(rating) {
      case 1: return '#2c294b'; // dkpurple - 1 stars
      case 2: return '#762861'; // cranberry - 2 star
      case 3: return '#c5316a'; // magenta - 3 stars
      case 4: return '#3b3484'; // mdpurple - 4 stars
      case 5: return '#7174b0'; // ltpurple - 5 stars
      default: return 'black'; // default color if rating is not 1-5
    }
  };

  // // Calculate the weights for each link
  // const linksWithWeights = connectedLinks.map(link => {
  //   let weight = Math.sqrt(
  //     Math.pow(link.target.x - link.source.x, 2) +
  //     Math.pow(link.target.y - link.source.y, 2)
  //   );
  //   return {
  //     ...link,
  //     weight: weight.toFixed(2) // Keeping the weight to two decimal places for readability
  //   };
  // });

  console.log('Connected links:', connectedLinks);

  console.log('Current simulation forces:', simulation.force('charge'), simulation.force('link'));

  switch (selectedNodeIds.length) {
    case 0: // First node clicked
    console.log('First node clicked:', clickedNode.id);

      setSelectedNodeIds([clickedNode.id]);
      fixNodePosition(clickedNode, 100, 100);
      const filteredLinksForFirstNode = connectedLinks.filter(link => link.source.id === clickedNode.id);
      setNodeBoxContent1({
        title: clickedNode.id,
        description: filteredLinksForFirstNode
          .sort((a, b) => a.weight - b.weight)
          .map(link => {
            const color = getColorForRating(link.distancerating);
            return (
              <span key={link.source?.id + link.target?.id} style={{ color: color }}>
                ({link.distancerating}) to → {link.target?.id} is {link.weight} away
              </span>
            );
            // return `• ${link.source?.id} → ${link.target?.id} || ${link.weight}`;
          // }).join("\n") || 'No connected links' // Fallback text if there are no connected links
        }) || 'No connected links'
        });
      console.log('NodeBox1 set to:', nodeBoxContent1);

      setIsNodeBox1Visible(true);
      break;

    case 1: // Second unique node clicked
    console.log('Second node clicked:', clickedNode.id);

      if (selectedNodeIds[0] !== clickedNode.id) {
        setSelectedNodeIds([selectedNodeIds[0], clickedNode.id]);
        fixNodePosition(clickedNode, dimensions.width - 100, dimensions.height - 100);
        const filteredLinksForSecondNode = connectedLinks.filter(link => link.target.id === clickedNode.id);

        setNodeBoxContent2({
          title: clickedNode.id,
          // Assuming links is an array of link objects { source, target, ... }
          // description: links.filter(link => link.source.id === clickedNode.id || link.target.id === clickedNode.id)
          description: filteredLinksForSecondNode
          .sort((a, b) => a.weight - b.weight) // Sort links by weight in ascending order
          .map(link => {
            const color = getColorForRating(link.distancerating);
            return (
              <span key={link.source?.id + link.target?.id} style={{ color: color }}>
                ({link.distancerating}) from → {link.source?.id} is {link.weight} away
              </span>
            )
            // return `• ${link.source?.id} → ${link.target?.id} || ${link.weight}`;
          // }).join("\n")
        })
        });
        console.log('NodeBox2 set to:', nodeBoxContent2);
        console.log('Dijkstra links:', links);
        console.log('Dijkstra linksRef:', linksRef);
        const path = DijkstraPath(linksRef, selectedNodeIds[0], clickedNode.id);
        console.log('Dijkstra Path set to:', path);

        setIsNodeBox2Visible(true);
        // setTimeout(() => console.log('setIsNodeBox2Visible set to:', isNodeBox2Visible), 0);
      } else {
        // Clicked on the same node again, hide NodeBox and release node
        console.log('Same node clicked again:', clickedNode.id);
        console.log('Changing NodeBox1 to False');
        setIsNodeBox1Visible(false);
        setTimeout(() => console.log('setIsNodeBox1Visible set to:', isNodeBox1Visible), 0);
        releaseNodePosition(clickedNode);
        setSelectedNodeIds([]);
      }
      break;

    case 2: // Third click, regardless of the node
    console.log('Third click detected, clearing selections and NodeBoxes');

      selectedNodeIds.forEach(id => {
        const node = nodes.find(d => d.id === id);
        releaseNodePosition(node);
      });
      setSelectedNodeIds([]);
      console.log('Changing NodeBox1 to False');
      setIsNodeBox1Visible(false);
      // console.log('setIsNodeBox1Visible set to:', isNodeBox1Visible);
      console.log('Changing NodeBox2 to False');
      setIsNodeBox2Visible(false);
      // console.log('setIsNodeBox2Visible set to:', isNodeBox2Visible);

      break;

      default:
      // No default action required, or add any default logic if necessary
      console.log('No additional action needed for the click event');
  }
      // Log final node positions and pinned states
      console.log('Node positions and fixed states after click:', nodes.map(d => ({
        id: d.id, 
        x: d.x, 
        y: d.y, 
        fx: d.fx, 
        fy: d.fy
      })));

  restartSimulation();
}, [selectedNodeIds, dimensions, nodeBoxContent1, nodeBoxContent2, isNodeBox1Visible, isNodeBox2Visible]);

  useEffect(() => {

      if (status !== 'success' || !sources || !isDataFetchedRef.current) {
        console.log("Data not ready yet.");
        return;
      }
  
    if (graphNodes.length === 0 || graphEdges.length === 0) {
      console.error('Edges and Nodes are not properly defined in graphData.');
      return;
    }
    
    // Deep copy of links and nodes
    const nodes = graphNodes.map(d => ({ ...d }));
    const links = graphEdges.map(d => ({
      source: nodes.find(node => node.id === d.source),
      target: nodes.find(node => node.id === d.target),
    }));
    
    // Create SVG container
    const svg = d3.select(svgRef.current)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .attr('viewBox', [0, 0, dimensions.width, dimensions.height])
      .attr('style', 'max-width: 100%; height: auto;');

      // const zoomedContainer = svg.select('g').empty() ? svg.append('g') : svg.select('g')
      // .attr('transform', `translate(${zoomTransform.x}, ${zoomTransform.y}) scale(${zoomTransform.k})`);
// Select or append a 'g' element to the SVG only once
const zoomedContainer = svg.select('g').empty() ? svg.append('g') : svg.select('g');

// Apply the zoom transform to the container
// zoomedContainer.attr('transform', `translate(${zoomTransform.x}, ${zoomTransform.y}) scale(${zoomTransform.k})`);

//might add this back in
    // zoomedContainer.selectAll('.node').remove();
    // zoomedContainer.selectAll('.link').remove();
    // d3.selectAll('.link').remove();

    // linksRef.current = links
    const link = zoomedContainer.selectAll('.link') // Select links within the zoomed group
      // .data(linksRef.current)
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .attr('stroke', '#2c294b')
      .attr('stroke-opacity', 0.6)
      .attr('stroke-width', d => Math.sqrt(d.value));
  
  const counts = graphNodes
      .filter((node) => node.id !== "begin" && node.id !== "end" && node.id !== "Empty" && node.id !== undefined)
      .map((node) => {
      return UniqueURL[node.id] || UniqueEventDesc[node.id] || 0;
      });
      // Find the min and max counts

  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);
  console.log('MinCount:', minCount);
  console.log('maxCount:', maxCount);

  rScaleRef.current = d3.scaleLinear()
      .domain([minCount, maxCount])
      .range([5, 70]);  // Range of circle radius, adjust as needed

  console.log('Counts:', counts);

  // Update nodesRef with the new nodes data
  nodesRef.current = [...nodes];
// Create the D3 selection\


const node = zoomedContainer.selectAll('.node')
.data(nodesRef.current, d => d ? d.id : null)
.join(
  enter => enter.append('circle').attr('class', 'node'),
  update => update,
  exit => exit.remove()
)
    .attr('class', d => `node ${selectedNodeIds.includes(d.id) ? 'selected-node' : ''}`)
    .attr('stroke', '#7174b0')
    .attr('stroke-width', 1.5)
    .attr('r', d => {
        const count = UniqueURL[d.id] || UniqueEventDesc[d.id] || 0;
        return rScaleRef.current(count);
    })
    .attr('fill', '#3b3484')
    .on('click', (event, d) => {
      if(linksRef.current && linksRef.current.length > 0) {
        console.log('LinksRef at the time of click:', linksRef.current);
        handleNodeClick(event, d, linksRef.current);
      } else {
        console.error('Links data is not available or not populated yet');
      }
    })
    // .on('click', (event, d) => {
    //   if(linksRef.current) {
    //     handleNodeClick(event, d, linksRef.current);
    //   } else {
    //     console.error('Links data is not available');
    //   }
    // })
    .call(d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
    .on('end', dragended))

// Add titles to the circle elements
node.append('title').text(d => d.id);

// node.attr('fill', d => selectedNodeIds.includes(d.id) ? '#c5316a' : '#3b3484')
// .attr('opacity', d => {
//     if (selectedNodeIds.length === 2) {
//         return selectedNodeIds.includes(d.id) ? 1 : 0.2;
//     }
//     return 1;
// });

node.attr('fill', d => {
  if (selectedNodeIds.length === 2) {
    return selectedNodeIds.includes(d.id) ? '#c5316a' : '#dbdbde';
  }
  return '#3b3484';
});

// Exit old nodes
node.exit().remove();

console.log("Updated nodesRef.current: ", nodesRef.current);


function ticked() {
  link
    .attr('x1', d => d.source.x)
    .attr('y1', d => d.source.y)
    .attr('x2', d => d.target.x)
    .attr('y2', d => d.target.y);

  node
    .attr('cx', d => d.x)
    .attr('cy', d => d.y);
}

const nodeIds = new Set(nodesRef.current.map(d => d.id));

console.log("nodeIds:", nodeIds);
console.log("Nodes:", nodesRef.current.map(d => d.id));
console.log('Links:', links);
console.log('LinksRef:', linksRef);

// Check if each source and target exists in nodes
links.forEach((link, index) => {
const sourceExists = nodesRef.current.some(node => node.id === link.source.id);
const targetExists = nodesRef.current.some(node => node.id === link.target.id);

if (!sourceExists || !targetExists) {
  console.log(`Invalid link at index ${index}: `, link);
}
});

console.log("Using nodesRef.current in simulation 5: ", nodesRef.current);

// plan b
if (simulationRef.current) {
  console.log('Is simulation an instance of d3.forceSimulation: ', simulationRef.current instanceof d3.forceSimulation);

  simulationRef.current.nodes(nodesRef.current);


// // Create new simulation or update existing one
// if (simulationRef.current) {
//   console.log('Is simulation an instance of d3.forceSimulation: ', simulationRef.current instanceof d3.forceSimulation);

//   const chargeForce = simulationRef.current.force('charge');
//   console.log('Charge force:', chargeForce);

//   if (typeof chargeForce.nodes === 'function') {
//     chargeForce.nodes(nodesRef.current);
//   } else {
//     console.error('Charge force does not have a nodes method. Charge force is:', chargeForce);
  // }


// if (simulationRef.current) {
//   console.log("Type of simulationRef.current", typeof simulationRef.current);
//   console.log("Content of nodesRef.current", nodesRef.current);

//   if (typeof simulationRef.current.force('charge').nodes === 'function') {
//     simulationRef.current.force('charge').nodes(nodesRef.current);
//   } else {
//     console.error('Charge force does not have a nodes method. Charge force is:', simulationRef.current.force('charge'));
//   }



// was lower

// // Create new simulation or update existing one
// if (simulationRef.current) {
//   console.log("Type of simulationRef.current", typeof simulationRef.current);
//   console.log("Content of nodesRef.current", nodesRef.current);
//   simulationRef.current.force('charge').nodes(nodesRef.current);  // Updated to use nodesRef.current
//   console.log("Is simulation an instance of d3.forceSimulation: ", simulationRef.current instanceof d3.forceSimulation);
// simulationRef.current.force('link').links(links);
//   simulationRef.current.alpha(1).restart();  // Reheat and restart the simulation
//   if (!simulationRef.current.force('charge')) {
//     console.error("Charge force is not defined");
// }
//   console.log("Forces before simulation start:", {
//     link: simulationRef.current.force('link'),
//     charge: simulationRef.current.force('charge'),
//     center: simulationRef.current.force('center')
//   });

  simulationRef.current.force('link').links(links);
  simulationRef.current.alpha(1).restart();  // Reheat and restart the simulation
  console.log("Is simulation an instance of d3.forceSimulation: ", simulationRef.current instanceof d3.forceSimulation);
  
  if (!simulationRef.current.force('charge')) {
    console.error("Charge force is not defined");
  }

  console.log("Forces before simulation start:", {
    link: simulationRef.current.force('link'),
    charge: simulationRef.current.force('charge'),
    center: simulationRef.current.force('center')
  });

} else {
  console.log("at Simulation : links", links)
  console.log("at Simulation : nodesRef.current", nodesRef.current)  // Shouldn't be empty now
  simulationRef.current = d3.forceSimulation(nodesRef.current)  // Initialize with nodesRef.current
  .force('link', d3.forceLink(links).id(d => d.id).distance(50))
  .force('charge', d3.forceManyBody().strength(-15))
  .force("center", d3.forceCenter(dimensions.width / 2, (dimensions.height / 2 - 20)))
  .on('tick', ticked);
    // .force('x', d3.forceX().strength(-40))
    // .force('y', d3.forceY().strength(-25))

  //   .force('link', d3.forceLink(links).id(d => d.id).distance(180))
  // .force('charge', d3.forceManyBody().strength(-40))
  // .force("center", d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
  // .force('horizontal', horizontalForce().strength(0.8))
  // .force('y', d3.forceY(dimensions.height / 2).strength(0.3)); 
}
console.log("Type of charge force", typeof simulationRef.current.force('charge'));
console.log("Value of charge force", simulationRef.current.force('charge'));

console.log("NodesRed after simulation 6:", nodesRef.current);

console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));

// simulationRef.current.force('charge', d3.forceManyBody().strength(-50));

console.log("log all methods force('charge'",Object.keys(simulationRef.current.force('charge')));

console.log("Is charge an instance of d3.forceManyBody: ", simulationRef.current.force('charge') instanceof d3.forceManyBody);

const charge = simulationRef.current.force('charge');

console.log("log all methods force('charge'):", Object.keys(charge));
console.log("Is charge an instance of d3.forceManyBody: ", charge instanceof d3.forceManyBody);

console.log('charge prototype:', Object.getPrototypeOf(charge));
console.log('d3.forceManyBody prototype:', Object.getPrototypeOf(d3.forceManyBody()));
console.log('Are they equal:', Object.getPrototypeOf(charge) === Object.getPrototypeOf(d3.forceManyBody()));



// // Apply the zoom transform to nodes
// node.attr('transform', d => `translate(${d.x}, ${d.y}) scale(${zoomTransform.k})`);

// // Apply the zoom transform to links
// link.attr('transform', `scale(${zoomTransform.k})`);








  // // Apply the zoom transform to nodes and links
  // node.attr('transform', d => `translate(${d.x}, ${d.y}) scale(${zoomTransform.k})`);
  // link.attr('transform', d => `scale(${zoomTransform.k})`);
  // zoomedContainer.attr('transform', `translate(${zoomTransform.x}, ${zoomTransform.y}) scale(${zoomTransform.k})`);

    // Create text elements for labels on edges
    const linkLabels = zoomedContainer.selectAll('.link-label')
      .data(links)
      .enter()
      .append('text')
      .text(d => `from ${d.source.id} to ${d.target.id}`)
      .attr('class', 'link-label')
      .style('visibility', 'hidden');

    // Create text elements for labels on nodes
    const nodeLabels = zoomedContainer.selectAll('.node-label')
      .data(nodes)
      .enter()
      .append('text')
      .text(d => d.id)
      .attr('class', 'node-label')
      .style('visibility', 'hidden')
      .attr('x', d => d.x) // Position relative to node's scaled position
      .attr('y', d => d.y - rScaleRef.current(UniqueURL[d.id] || UniqueEventDesc[d.id] || 0) - 5); // Adjust position

    function dragstarted(event) {
      if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulationRef.current.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    
      // Only stopPropagation if the node was actually dragged
      if (event.sourceEvent.type === 'drag') {
        event.sourceEvent.stopPropagation();
      }
    }

    node.on('mouseenter', function (event, d) {
      d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDesc[d.id] || 0) + 2);
      nodeLabels.filter(labelData => labelData.id === d.id)
        .style('visibility', 'visible');
    })
    .on('mouseleave', function (event, d) {
      d3.select(this).attr('r', rScaleRef.current(UniqueURL[d.id] || UniqueEventDesc[d.id] || 0));
      nodeLabels.filter(labelData => labelData.id === d.id)
        .style('visibility', 'hidden');
    })
    .on('click', (event, d) => {
      if(linksRef.current && linksRef.current.length > 0) {
        console.log('LinksRef at the time of click:', linksRef.current);
        handleNodeClick(event, d, linksRef.current);
      } else {
        console.error('Links data is not available or not populated yet');
      }
    
    // .on('click', (event, d) => {
    //   if(linksRef.current) {
    //     handleNodeClick(event, d, linksRef.current);
    //   } else {
    //     console.error('Links data is not available');
    //   }
    
    link.on('mouseenter', function () {
      linkLabels.style('visibility', 'visible');
    })
    .on('mouseleave', function () {
      linkLabels.style('visibility', 'hidden');
    });

    // Clean-up function
    return () => simulationRef.current.stop();
    });
    setIsLoading(false);
}, [dataset, status, graphNodes, graphEdges, dimensions, UniqueEventDesc, UniqueURL, zoomTransform, selectedNodeIds, sources, handleNodeClick]);

    // // Create the zoom behavior
    // const zoom = d3.zoom()
    // .scaleExtent([0.1, 10]) // Set the minimum and maximum zoom levels
    // .on('zoom', (event) => {
  
    //   if (!simulationRef.current) {
    //     console.error('Simulation is not initialized');
    //     return;
    //   }
      
    //   console.log('Current simulation:', simulationRef.current);
    //   console.log('Current zoom level:', event.transform.k);

    //   if (!simulationRef.current.force('charge') || !simulationRef.current.force('link')) {
    //     console.error('Simulation forces are not properly initialized');
    //     return;
    //   }
      
    //   setZoomTransform(event.transform);

    //   const zoomLevel = event.transform.k;
    //   const newDistance = 75 * (zoomLevel + 1);
    //   simulationRef.current.force("link").distance(newDistance);

    //   // Increase repulsion strength to make nodes move further apart
    //   const newStrength = -10 * (zoomLevel + 1);
    //   simulationRef.current.force("charge").strength(newStrength);
      
    //   if (simulationRef.current.force('charge') && simulationRef.current.force('link')) {
    //     simulationRef.current.force("link").distance(newDistance);
    //     simulationRef.current.force("charge").strength(newStrength);
    //     simulationRef.current.alpha(1).restart();
    //   }
  
    // // });
    // const zoom = d3.zoom()
    // .scaleExtent([0.1, 10])
    // .on('zoom', (event) => {
    //   // Apply the zoom transform directly to the container group
    //   zoomedContainer.attr('transform', event.transform);
  
    //   if (simulationRef.current) {
    //     // Log the current simulation and zoom level
    //     console.log('Current simulation:', simulationRef.current);
    //     console.log('Current zoom level:', event.transform.k);
  
    //     // Adjust the link force distance based on the zoom level
    //     const newDistance = 75 * (event.transform.k + 1);
    //     simulationRef.current.force("link").distance(newDistance);
  
    //     // Increase the repulsion strength based on the zoom level
    //     const newStrength = -10 * (event.transform.k + 1);
    //     simulationRef.current.force("charge").strength(newStrength);
  
    //     // Restart the simulation with new forces
    //     simulationRef.current.alpha(1).restart();
    //   } else {
    //     console.error('Simulation is not initialized');
    //   }
    // });
  
    // useEffect(() => {
    //   // Assuming svgRef is a ref to your SVG element
    //   const svg = d3.select(svgRef.current);
      
    //   // Make sure this 'g.zoomable' selector matches your actual markup
    //   // The 'zoomedContainer' should be defined within the same effect where you set up the zoom behavior
    //   const zoomedContainer = svg.select('g.zoomable').node()
    //     ? svg.select('g.zoomable') // If 'g.zoomable' exists, select it
    //     : svg.append('g').classed('zoomable', true); // If it doesn't exist, create it and apply the class
    
    //   // Define the zoom behavior within the useEffect hook
    //   const zoomBehavior = d3.zoom()
    //     .scaleExtent([0.1, 10])
    //     .on('zoom', (event) => {
    //       // Apply the zoom transform directly to the zoomedContainer
    //       zoomedContainer.attr('transform', event.transform);
    //       // ... the rest of your zoom function
    //     });
    
    //   // Apply the zoom behavior to the SVG container
    //   svg.call(zoomBehavior);
    
    //   // Cleanup function to remove zoom behavior when the component is unmounted
    //   return () => {
    //     svg.on('.zoom', null);
    //   };
    // }, []); // Make sure the dependency array is correct for your use case
    useEffect(() => {
      // Assuming svgRef is a ref to your SVG element
      if (!svgRef.current) {
        console.log("SVG element not yet available.");
        return;
      }
      console.log("SVG Element:", svgRef.current);
      const svg = d3.select(svgRef.current);
      console.log("Establishing Zoom context:");

      // Define the zoom behavior
      const zoom = d3.zoom()
        .scaleExtent([0.5, 20])
        .on('zoom', (event) => {
          event.preventDefault(); // Prevent default zooming
          // Apply the zoom transform directly to the zoomedContainer
          const zoomedContainer = svg.select('g.zoomable');
          zoomedContainer.attr('transform', event.transform);
          console.log("Zoom event:", event.transform);
        });
    
      // Apply the zoom behavior to the SVG element
      svg.call(zoom);
    
      // Cleanup function to remove zoom behavior when the component is unmounted
      return () => {
        svg.on('.zoom', null);
      };
    }, []); // Empty dependency array to apply the effect once on mount
    
    // useEffect(() => {
    //   // Assuming svgRef is a ref to your SVG element
    //   const svg = d3.select(svgRef.current);
      
    //   // Select the group element that you will apply the transformations to
    //   const zoomedContainer = svg.select('g.zoomable');
      
    //   // Define the zoom behavior
    //   const zoom = d3.zoom()
    //     .scaleExtent([0.1, 10])
    //     .on('zoom', (event) => {
    //       // Apply the zoom transform directly to the zoomedContainer
    //       zoomedContainer.attr('transform', event.transform);
    //       // The rest of the zoom handling logic...
    //     });
    
    //   // Apply the zoom behavior to the SVG element
    //   svg.call(zoom);
    
    //   // Cleanup function to remove zoom behavior when the component is unmounted
    //   return () => {
    //     svg.on('.zoom', null);
    //   };
    // }, []); // Make sure the dependency array is correct based on your use case
    
    useEffect(() => {
      console.log('NodeBox1 updated:', nodeBoxContent1);
    }, [nodeBoxContent1]);
    
    useEffect(() => {
      console.log('NodeBox2 updated:', nodeBoxContent2);
    }, [nodeBoxContent2]);
    
    useEffect(() => {
      console.log('NodeBox1 Visibility:', isNodeBox1Visible);
    }, [isNodeBox1Visible]);
    
    useEffect(() => {
      console.log('NodeBox2 Visibility:', isNodeBox2Visible);
    }, [isNodeBox2Visible]);
    
    if (status === 'loading') {
      return <LoadingSpinner />;
    }

  if (status === 'error') {
    return <div>Error loading data.</div>;
  }

  return (
    
    <>
    <div className="bg-white bg-opacity-80" style={{ width: '100%', height: '100%' }}>
            {isLoading ? <LoadingSpinner /> : null} 
        <>
          {isNodeBox1Visible && (
            <NodeBox
              isVisible={isNodeBox1Visible}
              content={{ title: nodeBoxContent1.title, description: nodeBoxContent1.description }}
              onClose={() => setIsNodeBox1Visible(false)}
              className="nodebox"
            />
          )}
          {isNodeBox2Visible && (
            <NodeBox
              isVisible={isNodeBox2Visible}
              content={{ title: nodeBoxContent2.title, description: nodeBoxContent2.description }}
              onClose={() => setIsNodeBox2Visible(false)}
              className="nodebox2"
            />
          )}
        </>
            <h1 className="h1 banner">Path Analyzer</h1>
      {/* <svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
      <g className="zoomable" width={dimensions.width} height={dimensions.height} transform={zoomTransform}>

      {nodesRef.current.map((node) => (
  <circle
    key={node.id}
    className={`node ${selectedNodeIds.includes(node.id) ? 'selected-node' : ''}`}
    onClick={(event) => {
      handleNodeClick(event, node, linksRef.current);
    }}
  />
))}
</g> */}
    <svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
      <g className="zoomable">
        {nodesRef.current.map((node) => (
          <circle
            key={node.id}
            className={`node ${selectedNodeIds.includes(node.id) ? 'selected-node' : ''}`}
            onClick={(event) => {
              handleNodeClick(event, node, linksRef.current);
            }}
          />
        ))}
      </g>
    </svg>
    <div>
    <SEO
      title="Path Analyzer"
      description="Click two nodes to see the shortest path between them"
      url="/pathanalyzer"
    />
    </div>
    </div>
      <InfoBox title={'Path Analyzer'} description={'Analyze how your users move through your application. Click on two nodes to see the shortest route.'} />
</>
  );
};

export default PathAnalyzer;