import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NodeBox = ({ isVisible, content, onClose, className }) => {
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div 
                className={`${className}`}
                initial={{ opacity: 0, scale: 0.7 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.7 }}
                    transition={{ duration: 0.2 }}
                >
                    <button onClick={onClose} className="nodebox-close">
                        <span className="text-lg">X</span>
                    </button>
                    {content && (
                        <>
                            <motion.h4
                                className="nodebox-title"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.3 }}
                            >
                                {content.title}
                            </motion.h4>
                            <motion.div
                                className="nodebox-description"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5 }}
                            >
                                {Array.isArray(content.description) ? 
                                    content.description.map((item, index) => (
                                        <React.Fragment key={index}>{item}<br/></React.Fragment>
                                    )) : content.description
                                }
                            </motion.div>
                        </>
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default NodeBox;
