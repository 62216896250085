import React, { useState } from 'react';

class PriorityQueue {
    constructor() {
        this.values = [];
    }

    enqueue(val, priority) {
        this.values.push({ val, priority });
        this.bubbleUp();
    }

    bubbleUp() {
        let idx = this.values.length - 1;
        const element = this.values[idx];

        while (idx > 0) {
            let parentIdx = Math.floor((idx - 1) / 2);
            let parent = this.values[parentIdx];

            if (element.priority >= parent.priority) break;
            this.values[parentIdx] = element;
            this.values[idx] = parent;
            idx = parentIdx;
        }
    }

    dequeue() {
        const min = this.values[0];
        const endNode = this.values.pop();
        if (this.values.length > 0) {
            this.values[0] = endNode;
            this.sinkDown();
        }
        return min;
    }

    sinkDown() {
        let idx = 0;
        const length = this.values.length;
        const element = this.values[0];

        while (true) {
            let leftChildIdx = 2 * idx + 1;
            let rightChildIdx = 2 * idx + 2;
            let leftChild, rightChild;
            let swap = null;

            if (leftChildIdx < length) {
                leftChild = this.values[leftChildIdx];
                if (leftChild.priority < element.priority) {
                    swap = leftChildIdx;
                }
            }

            if (rightChildIdx < length) {
                rightChild = this.values[rightChildIdx];
                if (
                    (swap === null && rightChild.priority < element.priority) ||
                    (swap !== null && rightChild.priority < leftChild.priority)
                ) {
                    swap = rightChildIdx;
                }
            }

            if (swap === null) break;
            this.values[idx] = this.values[swap];
            this.values[swap] = element;
            idx = swap;
        }
    }

    isEmpty() {
        return this.values.length === 0;
    }
}

function createAdjacencyList(links) {
    console.log('Received links:', links);
    console.log('Type of links:', typeof links);
    console.log('Type of links:', Array.isArray(links) ? 'array' : 'not an array'); // This should log 'array'

    if (!Array.isArray(links)) {
        console.error('Expected an array but received:', links);
      }

    const adjacencyList = {};
    links.forEach(link => {
      let sourceId = link.source.id;
      let targetId = link.target.id;
  
      if (!adjacencyList[sourceId]) {
        adjacencyList[sourceId] = [];
      }
      if (!adjacencyList[targetId]) {
        adjacencyList[targetId] = [];
      }
  
      // Calculate the Euclidean distance between source and target as the weight
      let weight = Math.sqrt(
        Math.pow(link.target.x - link.source.x, 2) +
        Math.pow(link.target.y - link.source.y, 2)
      );
  
      adjacencyList[sourceId].push({ node: targetId, weight: weight });
      // If it's an undirected graph, you might also want to add the reverse link
      // adjacencyList[targetId].push({ node: sourceId, weight: weight });
    });
  
    return adjacencyList;
  }
  
  
  function DijkstraPath(links, startNode, endNode) {
    const queue = new PriorityQueue();
    queue.enqueue(startNode, 0);
    const distances = {};
    const previous = {};
    let path = []; // to return at endNode
    let smallest;
  
    console.log('Dijkstra -- links:', links);
    console.log('Type of links:', typeof links);
    console.log('Dijkstra -- startNode:', startNode);
    console.log('Dijkstra -- endNode:', endNode);
    
    const adjacencyList = createAdjacencyList(links.current)

    console.log('Dijkstra -- adjacencyList:', adjacencyList);
    
    
    // Initial distances to Infinity and previous to null
    for (let vertex in adjacencyList) {
        distances[vertex] = vertex === startNode ? 0 : Infinity;
        previous[vertex] = null;
        if (vertex === startNode) {
          console.log(`Starting node '${startNode}' set with distance 0`);
        } else {
          console.log(`Node '${vertex}' set with initial distance Infinity`);
        }
      }
    
    //   if (adjacencyList[startNode] && adjacencyList[startNode].some(node => node.node === endNode)) {
    //     // If the start node has a direct connection to the end node, return early.
    //     return [];
    // }
  
      while (!queue.isEmpty()) {
        smallest = queue.dequeue().val;
        console.log(`Dequeued '${smallest}' with distance ${distances[smallest]}`);
    
        if (smallest === endNode) {
          console.log(`Reached endNode: '${endNode}'. Starting to build path.`);
          // We are done, build up path to return at endNode
          while (previous[smallest]) {
            path.push(smallest);
            smallest = previous[smallest];
          }
          break;
        }
        if (smallest || distances[smallest] !== Infinity) {
            for (let nextNode of adjacencyList[smallest]) {
              console.log(`Looking at neighbor '${nextNode.node}'`);
              // Calculate new distance to neighboring node
              let candidate = distances[smallest] + nextNode.weight;
              let nextNeighbor = nextNode.node;
              console.log(`New candidate distance for '${nextNeighbor}' is ${candidate}`);
          
            if (candidate < distances[nextNeighbor]) {
              // Updating new smallest distance to neighbor
              distances[nextNeighbor] = candidate;
              // Updating previous - How we got to neighbor
              previous[nextNeighbor] = smallest;
              // enqueue in priority queue with new priority
              queue.enqueue(nextNeighbor, candidate);
              console.log(`Updated distance for '${nextNeighbor}' to ${candidate} and enqueued`);
            }
          }
        }
      }
    
      // Build the full path including the startNode node
        path = [startNode].concat(path).reverse();
        console.log(`Final path including startNode node: ${path}`);

        // Handling the path when startNode and endNode nodes are directly connected
        if (path.length <= 2) {
            console.log(`Nodes '${startNode}' and '${endNode}' are directly connected.`);
            return [];
        } else {
            // Return the path without the startNode and endNode nodes
            return path.slice(1, -1);
        }
        }
  
  export default DijkstraPath;