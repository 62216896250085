import React, { useState, useEffect, useRef } from 'react';
import SanitizedInput from '../components/SanitizedInput';
import TokenManager from '../components/TokenManager';
// import DatePicker from '../components/DatePicker';
// import DateMonthPicker from '../components/DatePicker';
import { askOracle, tellOracle } from '../components/AskOracle';

const Admin = () => {
  const [orgName, setOrgName] = useState('');
  const [orgDomain, setOrgDomain] = useState('');
  const [orgSubscription, setOrgSubscription] = useState('');
  const [orgID, setOrgID] = useState('');
  const [modelDate, setModelDate] = useState(new Date('2023-02-01'));
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMesssage] = useState('');
  const [lastEventReceived, setLastEventReceived] = useState('');
  
  
  // Ref to store initial values
  const initialDataRef = useRef({
    orgName: '',
    orgDomain: '',
    orgSubscription: '',
    modelDate: new Date('2024-02-01'),
    startPage: '',
    endPage: '',
    lastEventReceived: '',
  });

  useEffect(() => {
    const activeOrgID = localStorage.getItem('ActiveOrgID');
    const organizations = JSON.parse(localStorage.getItem('Organizations') || "[]");
    const matchedOrg = organizations.find(org => org.OrgID === activeOrgID);

    if (matchedOrg) {
      setOrgName(matchedOrg.OrgName);
      setOrgDomain(matchedOrg.OrgDomain);
      setOrgSubscription(matchedOrg.OrgSubscription);
      setLastEventReceived(matchedOrg.LastEventReceived);
      setStartPage(matchedOrg.StartPage);
      setEndPage(matchedOrg.EndPage);
      setModelDate(new Date(matchedOrg.ModelDate || '2023-02-01'));
      setOrgID(matchedOrg.OrgID); 

      // Update initialDataRef with the fetched data
      initialDataRef.current = {
        orgName: matchedOrg.OrgName,
        orgDomain: matchedOrg.OrgDomain, 
        orgSubscription: matchedOrg.OrgSubscription,
        modelDate: new Date(matchedOrg.ModelDate || '2023-02-01'),
        startPage: matchedOrg.StartPage,
        endPage: matchedOrg.EndPage,
        lastEventReceived: matchedOrg.LastEventReceived,
      };
    }
  }, []);


// Create a function to update and send organization data
const updateOrganizationData = () => {
  const activeOrgID = localStorage.getItem('ActiveOrgID');

    // Update initialDataRef with current state values
    const currentData = {
      OrgID: activeOrgID, // Ensure this is the correct field name as expected by the Go backend
      orgName,
      orgDomain,
      orgSubscription,
      startPage,
      endPage,
      lastEventReceived,
    };

    // Check if any data values have changed
    if (JSON.stringify(currentData) !== JSON.stringify(initialDataRef.current)) {
      const dataToSend = {};
        for (let key in currentData) {
            if (currentData[key] !== initialDataRef.current[key]) {
                dataToSend[key] = currentData[key];
            }
        }
      tellOracle('oracle/UpdateMyOrg', dataToSend)
        .then(response => {
          setSuccessMessage('Organization updated successfully!');
          setErrorMesssage('');
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
          initialDataRef.current = currentData; // Update the ref with new values
        })
        .catch(error => {
          setErrorMesssage('Failed to update the organization. Please try again.');
          setSuccessMessage('');
        });
    }
  };

  const updateModelDate = () => {
    const activeOrgID = localStorage.getItem('ActiveOrgID'); // Retrieve ActiveOrgID from localStorage

    if (!activeOrgID) {
      setErrorMesssage('Active organization ID not found. Please select an organization.');
      return;
  }

    const dateData = {
      OrgID: activeOrgID,
      modelDate: modelDate
  };

   tellOracle('oracle/UpdateModelDate', dateData)
        .then(response => {
            setSuccessMessage('Model date updated successfully!');
            setErrorMesssage(''); // Clear any previous errors
            // Other success handling logic...
        })
        .catch(error => {
            // Provide more specific error message based on error response
            const errorMessage = error.response && error.response.data ? error.response.data : 'Failed to update the model date. Please try again.';
            setErrorMesssage(errorMessage);
            setSuccessMessage(''); // Clear any previous success message
            // Other error handling logic...
        });
};

  useEffect(() => {
    console.log("Updated State values:", { orgName, orgDomain, orgSubscription, startPage, endPage, modelDate, orgID });
  }, [orgName, orgDomain, orgSubscription, startPage, endPage, modelDate, orgID]);
  
return (
  <div className="admin-section">
    <h1 className="h1">Admin Dashboard</h1>
    {/* Success and Error Messages */}
    {/* Organization Name */}
    <div className="admin-section">
      <label className="label">Organization Name:</label>
      <SanitizedInput value={orgName} onChange={(e) => setOrgName(e.target.value)} />
    </div>
    {/* Domain */}
    <div className="admin-section">
      <label className="label">Domain:</label>
      <SanitizedInput value={orgDomain} onChange={(e) => setOrgDomain(e.target.value)} />
    </div>
    {/* Subscription Tier */}
    <div className="admin-section">
      <label className="label">Subscription Tier:</label>
      <SanitizedInput value={orgSubscription} onChange={(e) => setOrgSubscription(e.target.value)} />
    </div>
    {/* Happy Path Start */}
    <div className="admin-section">
      <label className="label">Happy Path Start:</label>
      <SanitizedInput value={startPage} onChange={(e) => setStartPage(e.target.value)} />
    </div>
    {/* Happy Path Outcome */}
    <div className="admin-section">
      <label className="label">Happy Path Outcome:</label>
      <SanitizedInput value={endPage} onChange={(e) => setEndPage(e.target.value)} />
    </div>
           {/* Save Button */}
           <div className="admin-section">
        <button onClick={updateOrganizationData} className="save-button">Save Changes</button>
      </div>
      <div className="admin-section">
        <label className="jsscript label">JavaScript Install Script:</label>
        <code for="jsscript" className="code-block">{'<script defer src="https://api.teraace.com/happypath.js" data-org-id="' + orgID + '"></script>'}</code>
      </div>
      <div className="admin-section">
        <label className="modelRange label">Model Date Range:</label>
        <p>Use data from this date to today</p>
     {/* <DateMonthPicker /> */}
     {/* needs to send to updateModelDate */}
      </div>

      <div className="admin-section tokenManager"> 
      <TokenManager />
      </div>
    </div>
  );
}

export default Admin;


{/* <script>
  window.myCustomData = {
    UserID: 'user123',
    SubscriptionTier: 'premium'
  };
</script>

<script type="text/javascript" src="url.com" data-id="101-324873-17324afh-2834-hfd"></script> */}
