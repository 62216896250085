import { useQuery } from 'react-query';
import axios from 'axios';
import LocalData from './LocalData';

axios.defaults.withCredentials = true;

// const csrfToken = localStorage.getItem('csrfToken');

// axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

const GO_BASE = process.env.REACT_APP_GO_BASE;

const defaultHeaders = {
  'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming the token is stored in local storage
  'OrgID': `${localStorage.getItem('ActiveOrgID')}` // Assuming the token is stored in local storage
};

const fetchDataset = () => {
  console.log('Fetching dataset...');
  const headers = { ...defaultHeaders };
  console.log('headers in fetchDataset:', headers);

  return (
    axios.get(`${GO_BASE}/${'api/model-latest'}`, { headers }, { withCredentials: true })

    // axios
    //   .get(
    //     `https://api.teraace.com/api/model-latest`,
    //     { headers }, { withCredentials: true }
        // ) // Use the variable in the URL
      // return axios.get(`${GO_BASE}/api/model-latest`) // Use the variable in the URL
      .then((res) => {
        console.log('Data fetched successfully:', res.data);
        return res.data; // Return data directly without transformation
      })
      .catch((error) => {
        console.log('Error fetching data:', error);
        throw error;
      })
  );
};

export const useDataset = (shouldFetch = true) => {
  const fetchFn = shouldFetch ? fetchDataset : () => {
    const localDataset = LocalData.getItem('dataset');
    if (localDataset) {
      return Promise.resolve(localDataset);
    } else {
      // If there's no data in LocalData, fetch it anyway
      return fetchDataset();
    }
  };
  const queryInfo = useQuery('dataset', fetchFn, {
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // Update LocalData with the fresh dataset
      LocalData.setItem('dataset', data);
    }
  });
  
  console.log('useDataset called export:');
  console.log('Query Info:', queryInfo);
  console.log('Data inside useDataset:', queryInfo.data); // Add this line

  return queryInfo;
};
