export const getOrganizationModelDate = () => {
    // Retrieve ActiveOrgID and Organizations from localStorage
    const activeOrgID = localStorage.getItem('ActiveOrgID');
    console.log("Got activeOrgID from local:", activeOrgID);

    const organizations = JSON.parse(localStorage.getItem('Organizations'));
    console.log("Got organizations from local:", organizations);

    // Find the organization with the matching OrgID
    const activeOrganization = organizations.find(org => org.OrgID === activeOrgID);
    console.log("Got activeOrganization from local:", activeOrganization);

    console.log("Got activeOrganization from local:", activeOrganization.ModelDate);

    // Return the ModelDate of the active organization
    return activeOrganization ? activeOrganization.ModelDate : 'All time';
};

function isValidQuery(query) {
    // Check if the query is not null and has at least one defined property relevant to your application
    if (!query) return false;

    // Check for at least one valid property - adjust these based on your application's requirements
    const queryKeys = ['page', 'list', 'event', 'outcome', 'PageFrom']; // Add other relevant keys
    return queryKeys.some(key => query[key] !== undefined && query[key] !== null);
}


export const queryDatasets = (message, datasets) => {

    if (!isValidQuery(message)) {
        return { results: {}, logs: "Invalid or empty query received" };
    }

    console.log("queryDatasets called with message:", message, "and datasets:", datasets);
    // console.log("queryDatasets 2called with message:", message, "and stringify datasets:", JSON.stringify(datasets, null, 2));

    const { page, list, event, outcome, PageFrom, PageTo } = message;
    let results = {};
    let logs = {};

    console.log("Data datasets after merge:", datasets);

    console.log("queryDatasets page:", page, "list", list, "event", event, "outcome", outcome, "PageFrom", PageFrom, "PageTo", PageTo);
    

    if (list) {
        switch(list.toLowerCase()) {
            case 'pages':
                results.listPages = list(datasets.data.UniqueURL, 'pages');
                logs.listPages = results.listPages.status === 'success' ? `Pages listed: ${results.listPages.data}` : `No pages found`;
                break;
            case 'events':
                results.listEvents = list(datasets.data.UniqueEventDesc, 'events');
                logs.listEvents = results.listEvents.status === 'success' ? `Events listed: ${results.listEvents.data}` : `No events found`;
                break;
            case 'referrer':
                results.listReferrer = list(datasets.data.UniqueReferrer, 'referrer');
                logs.listReferrer = results.listReferrer.status === 'success' ? `Referrers listed: ${results.listReferrer.data}` : `No referrers found`;
                break;
            case 'querystring':
                results.listQueryString = list(datasets.data.UniqueQueryString, 'querystring');
                logs.listQueryString = results.listQueryString.status === 'success' ? `Query strings listed: ${results.listQueryString.data}` : `No query strings found`;
                break;
            default:
                results.listError = `Unknown list type: ${list}`;
                logs.listError = `Invalid list query: ${list}`;
        }
    }

    const modelDate = getOrganizationModelDate();
    
    console.log("Got modelDate from local:", modelDate);

        if (datasets.data.UniqueEventDesc && event && Object.keys(datasets.data.UniqueEventDesc).some(key => key.includes(event))) {
            const numberOfEvents = datasets.data.UniqueEventDesc[event];

            results.UniqueEventDesc = {
                data: `<p>${numberOfEvents} events since ${modelDate}</p>`,
                status: 'success' // Add the status as a part of the object
            };
            logs.UniqueEventDesc = `Page check: ${!!event}, Key match: ${!!(event && Object.keys(datasets.data.UniqueEventDesc).some(key => key.includes(event)))}`;
            console.log("Started UniqueEventDesc:", results.UniqueEventDesc);
        } else {
            results.UniqueEventDesc = {
                data: event ? 'No data found for the specified page in UniqueEventDesc' : 'UniqueEventDesc data available but no page specified',
                status: 'failure'
            };
            logs.UniqueEventDesc = `Page check: ${!!event}, Dataset check: ${!!datasets.data.UniqueEventDesc}`;
        }

        if (datasets.data.UniqueURL && page && Object.keys(datasets.data.UniqueURL).some(key => key.includes(page))) {
            // First assign the number of sessions to results.UniqueURL
            const numberOfSessions = datasets.data.UniqueURL[page];
        
            // Now create your formatted result using numberOfSessions
            results.UniqueURL = {
                data: `<p>${numberOfSessions} sessions since ${modelDate}</p>`,
                status: 'success'
            };
            logs.UniqueURL = `Page check: ${!!page}, Key match: ${!!(page && Object.keys(datasets.data.UniqueURL).some(key => key.includes(page)))}`;
            console.log("Started UniqueURL:", results.UniqueURL);
        } else {
            results.UniqueURL = {
                data: page ? 'No data found for the specified page in UniqueURL' : 'UniqueURL data available but no page specified',
                status: 'failure'
            };
            logs.UniqueURL = `Page check: ${!!page}, Dataset check: ${!!datasets.data.UniqueURL}`;
        }
        


// Query FrequentPatterns
if (datasets.data && page && datasets.data.FrequentPatterns) {
    let jsonString = datasets.data.FrequentPatterns;
    jsonString = jsonString.replace(/Infinity/g, '"Infinity"');

    try {
        const FrequentPatternsObject = JSON.parse(jsonString);

        if (Array.isArray(FrequentPatternsObject?.data)) {
            const filteredData = FrequentPatternsObject.data.filter(row => {
                const itemsets = Array.isArray(row[1]) ? row[1].join(" ") : "";

                const matchesPage = page ? itemsets.includes(page) : true;
                const matchesPageFrom = PageFrom ? itemsets.includes(PageFrom) : true;
                const matchesPageTo = PageTo ? itemsets.includes(PageTo) : true;

                return matchesPage && matchesPageFrom && matchesPageTo;
            });

            if (filteredData.length > 0) {
                console.log("Filtered data found in FrequentPatterns");
            
                // Slice the first 10 rows for initial display
                const initialData = filteredData.slice(0, 10).map(row => {
                    let resultObj = {};
                    const itemsets = Array.isArray(row[1]) ? row[1].join(", ") : "";
                    const support = row[0] ? `${(row[0] * 100).toFixed(2)}%` : "0%";
                    resultObj[itemsets] = support;
                    return resultObj;
                });
            
                // Slice the remaining data
                const remainingData = filteredData.slice(10).map(row => {
                    let resultObj = {};
                    const itemsets = Array.isArray(row[1]) ? row[1].join(", ") : "";
                    const support = row[0] ? `${(row[0] * 100).toFixed(2)}%` : "0%";
                    resultObj[itemsets] = support;
                    return resultObj;
                });
            
                // Store both slices in the results
                results.FrequentPatterns = {
                    initialData: initialData,
                    remainingData: remainingData,
                    status: 'success',
                    id: `${page}-frequentpatterns`
                };
                logs.FrequentPatterns = `Data check: true, Items found: ${results.FrequentPatterns.length}`;
            } else {
                results.FrequentPatterns = {
                    data: 'No data found for the specified criteria in FrequentPatterns',
                    status: 'failure'
                };
                logs.FrequentPatterns = `Data check: true, Array check: true, Criteria check: Page=${!!page}, PageFrom=${!!PageFrom}, PageTo=${!!PageTo}`;
            }
        } else {
            throw new Error("FrequentPatterns data is not in an expected format.");
        }
    } catch (error) {
        console.error("Error parsing FrequentPatterns JSON:", error);
        results.FrequentPatterns = 'Error in processing FrequentPatterns data';
        logs.FrequentPatterns = `JSON parse error: ${error.message}`;
    }
} else {
    results.FrequentPatterns = 'FrequentPatterns data not available';
    logs.FrequentPatterns = 'Data unavailable';
}
const aggregateMarkovData = (data) => {
    const aggregatedData = {};

    data.forEach((row, index) => {
        const fromField = row[0] || '';
        const toField = row[1] || '';
        const probability = parseFloat(row[2]) || 0;

        const key = `${fromField}-${toField}`;
        if (!aggregatedData[key]) {
            aggregatedData[key] = {
                From: fromField,
                To: toField,
                TotalProbability: 0,
                count: 0
            };
        }

        aggregatedData[key].TotalProbability += probability;
        aggregatedData[key].count += 1;
    });

    // Convert aggregated data back to an array and calculate average probability
    return Object.values(aggregatedData).map(aggregatedRow => ({
        From: aggregatedRow.From,
        To: aggregatedRow.To,
        Probability: (aggregatedRow.TotalProbability / aggregatedRow.count * 100).toFixed(2)
    }));
};


// Query MarkovChain
if (datasets.data && page && datasets.data.MarkovChain) {
    let jsonString = datasets.data.MarkovChain;
    jsonString = jsonString.replace(/Infinity/g, '"Infinity"');

    try {
        const MarkovChainObject = JSON.parse(jsonString);

        if (Array.isArray(MarkovChainObject?.data)) {
            const filteredData = MarkovChainObject.data.filter(row => {
                const fromField = row[0] || '';
                const toField = row[1] || '';

                const matchesPage = page ? (fromField.includes(page) || toField.includes(page)) : true;
                const matchesPageFrom = PageFrom ? (fromField.includes(PageFrom) || toField.includes(PageFrom)) : true;
                const matchesPageTo = PageTo ? (fromField.includes(PageTo) || toField.includes(PageTo)) : true;

                return matchesPage && matchesPageFrom && matchesPageTo;
            });

            if (filteredData.length > 0) {
                const aggregatedData = aggregateMarkovData(filteredData);
                // Sort the array by descending probability
                aggregatedData.sort((a, b) => parseFloat(b.Probability) - parseFloat(a.Probability));
            
                // Slice the first 10 rows for initial display
                const initialData = aggregatedData.slice(0, 5).map(row => {
                    return `<div className='ChatWithModel'><img src="/assets/icons/icon-froma.svg" className='iconFromTo-sm' alt='From'> ${row.From} <img src="/assets/icons/icon-toa.svg" className='iconFromTo-sm' alt='To'> ${row.To} is ${row.Probability}%</div><br/>`;
                });
            
                // Slice the remaining data
                const remainingData = aggregatedData.slice(5).map(row => {
                    return `<div className='ChatWithModel'><img src="/assets/icons/icon-froma.svg" className='iconFromTo-sm' alt='From'> ${row.From} <img src="/assets/icons/icon-toa.svg" className='iconFromTo-sm' alt='To'> ${row.To} is ${row.Probability}%</div><br/>`;
                });
            
                // Store both slices in the results
                results.MarkovChain = {
                    initialData: initialData,
                    remainingData: remainingData,
                    status: 'success',
                    id: `${page}-markovchain`
                };
                logs.MarkovChain = `Data check: true, No items found`;
            }
        } else {
            throw new Error("MarkovChain data is not in an expected format.");
        }
    } catch (error) {
        console.error("Error parsing MarkovChain JSON:", error);
        results.MarkovChain = 'Error in processing MarkovChain data';
        logs.MarkovChain = `JSON parsing error: ${error.message}`;
    }
} else {
    results.MarkovChain = 'MarkovChain data not available or not in the expected format';
    logs.MarkovChain = `Data check: false`;
}

// Query FPGrowth
if (datasets.data && datasets.data.FPGrowth) {
    let jsonString = datasets.data.FPGrowth;
    jsonString = jsonString.replace(/Infinity/g, '"Infinity"');

    try {
        const FPGrowthObject = JSON.parse(jsonString);

        if (Array.isArray(FPGrowthObject?.data)) {
            const filteredData = FPGrowthObject.data.filter(row => {
                const antecedents = Array.isArray(row[0]) ? row[0].join(" ") : "";
                const consequents = Array.isArray(row[1]) ? row[1].join(" ") : "";

                const matchesPage = page ? (antecedents.includes(page) || consequents.includes(page)) : true;
                const matchesPageFrom = PageFrom ? antecedents.includes(PageFrom) : true;
                const matchesPageTo = PageTo ? consequents.includes(PageTo) : true;

                return matchesPage && matchesPageFrom && matchesPageTo;
            });

            if (filteredData.length > 0) {
                // Sort by Confidence in descending order
                filteredData.sort((a, b) => b[5] - a[5]);
            
                // Format the entire data
                const formattedFullData = filteredData.map(row => {
                    let resultObj = {};
                    FPGrowthObject.columns.forEach((column, index) => {
                        resultObj[column] = index > 1 ? `${(row[index] * 100).toFixed(2)}%` : Array.isArray(row[index]) ? row[index].join(" ") : row[index];
                    });
                    return `<p className='ChatWithModel'>
                                <strong><img src="/assets/icons/icon-froma.svg" className='iconFromTo-sm' alt='From'> <span style='color:#762861'>${resultObj.antecedents}</span> with antecedent support of ${resultObj['antecedent support']}</strong>  |  <strong><img src="/assets/icons/icon-toa.svg" className='iconFromTo-sm' alt='To'> <span style='color:#762861'>${resultObj.consequents}</span> with consequent support of ${resultObj['consequent support']}</strong>
                                <br/>
                                <strong><em>Confidence: ${resultObj.confidence}</em></strong>, Support: ${resultObj.support}, Conviction: ${resultObj.conviction},
                                Lift: ${resultObj.lift}, Leverage: ${resultObj.leverage}, Zhang's Metric: ${resultObj.zhangs_metric}
                            </p><br/>`;
                });
            
                // Slice the first 10 rows for initial display
                const initialData = formattedFullData.slice(0, 4);
                // Slice the remaining data
                const remainingData = formattedFullData.slice(4);
            
                // Store both slices in the results
                results.FPGrowth = {
                    initialData: initialData,
                    remainingData: remainingData,
                    status: 'success',
                    id: `${page}-fpgrowth`
                };
                logs.FPGrowth = `Data check: true, Items found: ${filteredData.length}`;
            } else {
                results.FPGrowth = {
                    data: 'No data found for the specified criteria in FPGrowth',
                    status: 'failure'
                };
                logs.FPGrowth = `Data check: true, Array check: true, Criteria check: Page=${!!page}, PageFrom=${!!PageFrom}, PageTo=${!!PageTo}`;
            }
        } else {
            throw new Error("FPGrowth data is not in an expected format.");
        }
    } catch (error) {
        console.error("Error parsing FPGrowth JSON:", error);
        results.FPGrowth = {
            data: 'Error parsing FPGrowth data',
            status: 'error'
        };
        logs.FPGrowth = `JSON parse error: ${error.message}`;
    }
} else {
    results.FPGrowth = {
        data: 'FPGrowth data not available',
        status: 'failure'
    };
    logs.FPGrowth = 'No FPGrowth data found in datasets';
}

// Query RandomForest
if (datasets.data && datasets.data.RandomForest) {
    let jsonString = datasets.data.RandomForest;
    jsonString = jsonString.replace(/Infinity/g, '"Infinity"');

    try {
        const RandomForestObject = JSON.parse(jsonString);

        if (RandomForestObject && typeof RandomForestObject === 'object') {
            const featureImportances = RandomForestObject.feature_importances || {};
            const filteredKeys = Object.keys(featureImportances).filter(key => key.includes(`${page}`));
            console.log("filteredKeys:", filteredKeys);

            // If filteredKeys is not empty, it means we found a match
            if (filteredKeys.length > 0) {
                console.log("Match found in RandomForest feature_importances for URL:", page);
                
                // Format the entire data
                const formattedFullData = filteredKeys.map(key => `${key}: ${featureImportances[key].toFixed(2)}`);
            
                // Slice the first 5 rows for initial display
                const initialData = formattedFullData.slice(0, 5);
                // Slice the remaining data
                const remainingData = formattedFullData.slice(5);
            
                // Store both slices in the results
                results.RandomForest = {
                    initialData: initialData,
                    remainingData: remainingData,
                    status: 'success',
                    id: `${page}-randomforest`

                };
            } else {
                results.RandomForest = 'No match found in RandomForest feature_importances for the specified URL';
                logs.RandomForest = 'No matching data found';
            }
        } else {
            throw new Error("RandomForest data is not in an expected format.");
        }
    } catch (error) {
        console.error("Error parsing RandomForest JSON:", error);
        results.RandomForest = 'Error in processing RandomForest data';
        logs.RandomForest = `JSON parsing error: ${error.message}`;
    }
} else {
    results.RandomForest = 'RandomForest data not available or not in the expected format';
    logs.RandomForest = `Data check: false`;
}

return { results, logs };
};

export default queryDatasets;
