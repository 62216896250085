import React, { useEffect, useContext, useState } from 'react';
import { ChatProvider } from '../components/ChatContext';
import Chatbot from '../components/Chatbot';
import { useDataset } from '../components/DataFetcher';
import { DimensionContext } from '../components/ResponsiveWrapper';
import SEO from '../components/SEO';
import InfoBox from '../components/InfoBox';

function Chat() {
    const datasets = useDataset();
    const dimensions = useContext(DimensionContext);

    useEffect(() => {
        console.log("Chat component mounted.");
    }, []);
        
    return (
        <>
        <div className="flex h-full">
        <ChatProvider datasets={datasets}>
            <div className="ChatProvider">
                <Chatbot datasets={datasets} dimensions={dimensions}/>
            </div>
        </ChatProvider>
        </div>
           <InfoBox title={"Conversations"} description={'Ask for the specific info you need right now without having to look any further. Tag a metric to create a Spark Trend to see a graph of results over time.'} />
           <SEO
                title="Conversations"
                description="Talk to your data to learn more"
                url="/conversations"
              />
        </>
    );
}

export default Chat;