import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import OrganizationPicker from './components/OrganizationPicker';

const Sidebar = ({ pages, orgs, activeOrgID, handleOrgChange, onNavigate, canAccessFeature }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // State to manage login status
  const navigate = useNavigate(); // Hook to manage redirection
  console.log("Sidebar: orgs:", orgs, "activeOrgID:", activeOrgID);

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove the authToken
    sessionStorage.removeItem('sessionId'); // Remove the sessionId
    setIsLoggedIn(false); // Set isLoggedIn to false
    console.log('Logged Out');

    navigate('/auth'); // Redirect to /auth
  };

  const roleRequirements = {
    "/onboarding": ["superadmin"],
    // Add other paths and their required roles as needed
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-logo">
        <img src="/logo-Teraace-250.png" alt="logo"></img>
      </div>
      {pages
          .filter(page => page.route !== "/auth")
          .filter(page => page.route !== "/signup")
          .filter(page => {
            const requiredRoles = roleRequirements[page.route];
            // Use canAccessFeature directly now that it's properly included in props
            return !requiredRoles || canAccessFeature(requiredRoles);
          })
          .map((page, index) => (
        <NavLink 
          key={index}
          to={page.route}
          className="sidebar-button"
          activeClassName="filter-active"
          onClick={() => onNavigate(page.route.replace('/', ''))}
        >
          {page.name}
        </NavLink>
      ))}
      
      <button className="auth-logout-btn" onClick={handleLogout}>Logout</button>

      <div className="orgpicker">
      <h5 className="orgpicker">Switch Orgs</h5>
      <OrganizationPicker orgs={orgs} activeOrgID={activeOrgID} handleOrgChange={handleOrgChange} />
      </div>
    </div>
  );
};

export default Sidebar;
