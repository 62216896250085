// TreeExplorer.jsx
import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from '@mantine/core';
import ReactFlow, { MiniMap, Background, Controls, Handle } from 'react-flow-renderer';
import LoadingSpinner from '../components/LoadingSpinner';
import { DimensionContext } from '../components/ResponsiveWrapper';
import { useDataset } from '../components/DataFetcher';
import { setGraph, updateGraph, setGraphElements, addGraphElement, removeGraphElement, updateGraphElement, setSelectedNodesByLayer, addSelectedNode, removeSelectedNode, setExpandedNodes, setCurrentLayer, selectExpandedNodes, selectCurrentLayer, selectGraphElements } from '../redux/slices/treeExplorerSlice';
import { index } from 'd3';
// import UndoRedoControls from '../components/UndoRedoControls';


const TreeExplorer = () => {
    const [selectedNode, setSelectedNode] = useState(null);
    const [graphElements, setGraphElements] = useState([]);
    const [expandedNodes, setExpandedNodes] = useState(new Set());
    const [selectedElements, setSelectedElements] = useState([]);
    const { data, status, error } = useDataset();
    const dispatch = useDispatch();
    const dimensions = useContext(DimensionContext);
    const [selectedNodesByLayer, setSelectedNodesByLayer] = useState({});
    const [currentLayer, setCurrentLayer] = useState(0);
    const [graph, setGraph] = useState([]);
    const [possibleValues, setPossibleValues] = useState([]);
    const [hasNodeBeenSelected, setHasNodeBeenSelected] = useState(false);
    const lastProcessedDataRef = useRef();


    const processedData = useMemo(() => {
        console.log("processData has begun");

        if (!data || !data.MarkovChain) return null;

        try {
            const markovChainData = JSON.parse(data.MarkovChain).data;
            if (!Array.isArray(markovChainData)) {
                throw new Error('Parsed data is not an array');
            }

            // const urlList = data?.UniqueURL ? Object.keys(data.UniqueURL).map(key => ({ value: key, label: key })) : [];
            // const eventDescList = data?.UniqueEventDesc ? Object.keys(data.UniqueEventDesc).map(key => ({ value: key, label: key })) : [];
            // const setPossibleValues = [...urlList, ...eventDescList];

            const setPossibleValues = [
                ...(data?.UniqueURL ? Object.keys(data.UniqueURL).map(key => ({ value: key, label: key })) : []),
                ...(data?.UniqueEventDesc ? Object.keys(data.UniqueEventDesc).map(key => ({ value: key, label: key })) : [])
            ];
            console.log("Processing with markovChainData", markovChainData);
            console.log("Processing with setPossibleValues", setPossibleValues);
            console.log("This should only appear once");

            // const processedData = markovChainData && setPossibleValues

            // return processedData ;

            return { markovChainData, possibleValues: setPossibleValues };

        } catch (error) {
            console.error("Error parsing MarkovChain data:", error);
            return null;
        }
    }, [data]);

    useEffect(() => {
        console.log("useEffect processedData has begun");

        if (status === 'success' && processedData?.markovChainData) {
            console.log("Data processing and graph generation has begun");
    
            const nodes = generateNodes(processedData.markovChainData);
            const edges = generateEdges(processedData.markovChainData);
            
            // Creating a structured graph object
            const graph = {
                nodes: nodes,
                edges: edges.reduce((acc, edge) => {
                    // Assuming edge.id is of the form 'source to target'
                    const [source, target] = edge.id.split(' | ');
                    if (source && target && source !== target) {
                        const probabilityStr = edge.label.split(':')[1].replace('%', '').trim();
                        const probability = parseFloat(probabilityStr);
                    if (!isNaN(probability)) {
                        if (!acc[source]) acc[source] = [];
                        acc[source].push({ target, probability });
                    }
                }

        return acc;
                }, {})
            };
    
            setGraph(graph); // Assuming setGraphElements expects this structured graph
        }
    }, [processedData]);

    const generateNodes = useCallback((markovChainData) => {
        console.log("Started to generateNodes with ", markovChainData);
        if (!markovChainData) {
            console.log("No valid data or MarkovChain data available.");
            return [];
        }
    
        const nodes = [];
        const nodeNames = new Set(markovChainData.map(([source]) => source));
        console.log("generateNodes nodeNames ", nodeNames);
    
        nodeNames.forEach((name, index) => {
            nodes.push({
                id: name,
                type: 'node',
                data: { label: name },
                position: { x: 10, y: 100 }
            });
        });
        console.log("generateNodes nodes ", nodes);
    
        return nodes;
    }, []); // Dependencies array is empty if the function does not depend on any state or props
    
    const generateEdges = useCallback((markovChainData) => {
        console.log("generateEdges has begun");
    
        if (!markovChainData) {
            console.log("No valid data or MarkovChain data available.");
            return [];
        }
    
        const edges = [];
        markovChainData.forEach(([source, target, probability]) => {
            if (source !== target) {
                edges.push({
                    id: `${source} | ${target}`,
                    type: 'default',
                    source,
                    target,
                    label: `Probability: ${(probability * 100).toFixed(1)}%`
                });
            }
        });
    
        console.log("generateEdges", edges);
        return edges;
    }, []); // Dependencies array is empty if the function does not depend on any state or props
    
    // const generateNodes = (markovChainData) => {
    //     console.log("Started to generateNodes with ", markovChainData);
    //     if (!markovChainData) {
    //         console.log("No valid data or MarkovChain data available.");
    //         return [];
    //     }
    
    //     const nodes = [];
    //     const nodeNames = new Set(markovChainData.map(([source]) => source));
    //     console.log("generateNodes nodeNames ", nodeNames);

    //     nodeNames.forEach((name, index) => {
    //         nodes.push({
    //             id: name,
    //             type: 'node',
    //             data: { label: name },
    //             position: { x: 10, y: 100 }
    //         });
    //     });
    //     console.log("generateNodes nodes ", nodes);

    //     return nodes;
    // };

    // const generateEdges = (markovChainData) => {
    //     console.log("generateEdges has begun");
    
    //     if (!markovChainData) {
    //         console.log("No valid data or MarkovChain data available.");
    //         return [];
    //     }
    
    //     const edges = [];
    //     markovChainData.forEach(([source, target, probability]) => {
    //         if (source !== target) {
    //             edges.push({
    //                 id: `${source} | ${target}`,
    //                 type: 'default',
    //                 source,
    //                 target,
    //                 label: `Probability: ${(probability * 100).toFixed(1)}%`
    //             });
    //         }
    //     });
    
    //     console.log("generateEdges", edges);
    //     return edges;
    // };
    
    const CustomNode = ({ data, id }) => {
        console.log("CustomNode has begun");

        return (
            <div onDragStart={(e) => e.stopPropagation()} onClick={(e) => onSelectionChange(e, { id, ...data })}>
                <div>{data.label}</div>
                {data.hasMore && <button onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering drag or node click
                    onSelectionChange(e, { id: `more-${id}`, ...data });
                }}>+ More</button>}
            </div>
        );
    };

// const newFunctionForEdgeHiding(){
//     Object.keys(connectionMap).forEach((source) => {
//         const connections = connectionMap[source].sort((a, b) => b.probability - a.probability);
//         const hiddenConnections = connections.slice(5);

//         connections.slice(0, 5).forEach(({ target, probability }) => {
//             edges.push({
//                 id: `${source} to ${target}`,
//                 type: 'edge',
//                 source: source,
//                 target: target,
//                 label: `Probability: ${(probability * 100).toFixed(1)}%`
//             });
//         });

//         if (hiddenConnections.length > 0) {
//             const moreNodeId = `more-${source}`;
//             hiddenConnections.forEach(({ target, probability }) => {
//                 edges.push({
//                     id: `${source} to ${target}`,
//                     source: moreNodeId,
//                     type: 'hiddenEdges',
//                     target: target,
//                     label: `Probability: ${(probability * 100).toFixed(1)}%`
//                 });
//             });
//         }
//         console.log("generateEdges connections", connections);
//         console.log("generateEdges hiddenConnections", hiddenConnections);
//         console.log("generateEdges edges", edges);
//     });

// }


    // Redux reducer
// const treeExplorerSlice = createSlice({
//     // ... [existing slice setup]
//     reducers: {
//         // ... [existing reducers]
//         setExpandedNodes: (state, action) => {
//             state.expandedNodes = action.payload;
//         },
//         setCurrentLayer: (state, action) => {
//             state.currentLayer = action.payload;
//         },
//         // ... [other reducers]
//     },
// });

// To dispatch actions:
// dispatch(setExpandedNodes(new Set())); // Example of setting expandedNodes
// dispatch(setCurrentLayer(1)); // Example of setting currentLayer



// Use this function in your useEffect


    // useEffect(() => {
    //     console.log("useEffect for selectedNode has begun");

    //     if (selectedNode) {
    //         handleLayeredNodeInteraction(selectedNode);
    //     }
    // }, [selectedNode, data, dispatch]);

    // Function to Remove Unselected Nodes from Previous Layers (if needed)
    // useEffect(() => {
    //     // I don't know what this is trying to do, but I don't think it does anything. 
    //     console.log("useEffect for graphElements has begun");

    //     if (graphElements && graphElements.length > 0) {
    //         console.log("graphElements in useEffect", graphElements);

    //     const newGraphElements = graphElements.filter(element => {
    //         // Extract layer from element ID if your ID structure is "node-1-layer-2"
    //         const layer = element.id.split("-")[2]; 
    //         console.log("newGraphElements in useEffect", newGraphElements);
    //         return selectedNodesByLayer[layer]?.has(element.id);
    //     });
    //     setGraphElements(newGraphElements)
        
    //     // I took this out when we kept having errors.
    //     // dispatch(setGraphElements(newGraphElements));
    // }
    // }, [selectedNodesByLayer, graphElements, dispatch]);

    const getConnectedElements = (selectedNodeId, graph, dimensions) => {
    

        console.log("getConnectedElements called for nodeId", selectedNodeId);
        console.log("graph passed to getConnectedElements", graph);
        console.log("dimensions for getConnectedElements", dimensions);
    
            const connectedNodes = [{ 
                id: selectedNodeId, 
                type: 'node', 
                data: { label: selectedNodeId }, 
                position: { x: 50 , y: 50 },
                // position: { x: dimensions.width / 2 - 50 , y: 20 },
                layer: 1, 
                color: '#7174b0',
            }];
            const connectedEdges = [];
        
            if (graph.edges && graph.edges[selectedNodeId]) {
                graph.edges[selectedNodeId].forEach(({ target, probability }, index) => {
                    // Add edge
                    connectedEdges.push({
                        id: `${selectedNodeId} | ${target}`,
                        type: 'default',
                        source: selectedNodeId,
                        target,
                        label: `Probability: ${probability}%`,
                        layer: 2,

                    });
        
                    // Add target node if it's not the same as the selected node
                    if (target !== selectedNodeId) {
                        connectedNodes.push({
                            id: target,
                            type: 'node',
                            data: { label: target },
                            position: { 
                                x: (dimensions.width / 10 * (index + 2)) - 200, 
                                y: (index % 2 === 0) ? 200 : 275 // If index is even, y is 200, otherwise 250
                            },
                            layer: 2,
                        });
                    }
                });
            } else {
                console.log("No connected edges found for nodeId", selectedNodeId);
            }

            console.log("getConnectedElements connectedNodes", connectedNodes);
            console.log("getConnectedElements connectedEdges", connectedEdges);

            return [...connectedNodes, ...connectedEdges];
        };
   
    const handleNodeSelect = (nodeId) => {
            console.log("handleNodeSelect has begun", nodeId);
            console.log("Current graph in handleNodeSelect", graph);
        
            setSelectedNode(nodeId);
            const connectedElements = getConnectedElements(nodeId, graph, dimensions);
            console.log("connectedElements in handleNodeSelect", connectedElements);
        
            // Only update if the elements are actually different to avoid unnecessary re-renders
            setGraphElements(prevElements => {
                const newElements = JSON.stringify(connectedElements);
                const oldElements = JSON.stringify(prevElements);
                if (newElements !== oldElements) {
                    return connectedElements;
                }
                return prevElements;
            });
        
            console.log("setHasNodeBeenSelected has begun", hasNodeBeenSelected);
            // This seems to be intended to delay something by 5 seconds, not 500 milliseconds as the comment suggests
            setTimeout(() => {
                setHasNodeBeenSelected(true);
            }, 5000);
        };

    const calculateNewNodePositions = (newNodes, dimensions, existingNodesLength) => {
        console.log("calculateNewNodePositions has begun");

        // Calculates positions for new nodes based on the total number of existing nodes
        return newNodes.map((node, index) => {
            let x = ((existingNodesLength + index) % 5) * (dimensions.width / 5) + 100; // Adjust grid size as needed
            let y = Math.floor((existingNodesLength + index) / 5) * 150 + 100; // Vertical spacing between layers
            return { ...node, position: { x, y } };
        });
    };
    
    // const onSelectionChange = ({ nodes }) => {
    //     console.log("onSelectionChange has begun");

    //     if (nodes.length > 0) {
    //         const nodeId = nodes[0].id;
    //         console.log(`Selected node: ${nodeId}`);
    
    //         // Determine the layer based on the selected node and update it
    //         const layer = getCurrentLayer(nodeId);
    //         console.log(`Updating layer to: ${layer}`);
    //         setCurrentLayer(layer); // Update the current layer in the state
    
    //         // Fetch connected elements for the selected node
    //         const connectedElements = getConnectedElements(nodeId, graph, dimensions);
    
    //         setGraphElements((prevElements) => {
    //             // Identify new nodes by checking if they are not already present in prevElements
    //             const newNodes = connectedElements.filter(el => !prevElements.some(pe => pe.id === el.id));
    
    //             // Calculate positions for new nodes
    //             const newNodesWithPositions = calculateNewNodePositions(newNodes, dimensions, prevElements.length);
    
    //             // Merge new nodes with updated positions back into the existing elements array
    //             return [...prevElements, ...newNodesWithPositions];
    //         });
    
    //         setSelectedNode(nodeId);
    //     }
    // };
    
    const onSelectionChange = useCallback(({ nodes }) => {
        if (nodes.length > 0) {
            const nodeId = nodes[0].id;
            const layer = getCurrentLayer(nodeId);
            setCurrentLayer(layer);
    
            const connectedElements = getConnectedElements(nodeId, graph, dimensions);
    
            setGraphElements((prevElements) => {
                const existingElements = new Set(prevElements.map(el => el.id));
                const newElements = connectedElements.filter(el => !existingElements.has(el.id));
    
                if (newElements.length > 0) {
                    // Calculate positions for new nodes without moving existing ones
                    const newElementsWithPositions = calculateNewNodePositions(newElements, dimensions, prevElements.length);
                    return [...prevElements, ...newElementsWithPositions];
                }
    
                return prevElements; // Return unchanged if no new elements
            });
    
            setSelectedNode(nodeId);
        }
    }, [graph, dimensions]);
    
    useEffect(() => {
        console.log("graphElements updated", graphElements);
    }, [graphElements]);

    useEffect(() => {
        console.log("hasNodeBeenSelected updated", hasNodeBeenSelected);
    }, [hasNodeBeenSelected]);


    // const handleNodeClick = (element) => {
    //     console.log(`handleNodeClick initiated for element ID: ${element.id}`);
    
    //     if (element.id.startsWith('more-')) {
    //         console.log(`Expanding more nodes for base node ID: ${element.id}`);
    //         const baseNodeId = element.id.replace('more-', '');
    //         const additionalElements = generateNextLayerNodes(baseNodeId, graph, expandedNodes);
    
    //         console.log(`Generated ${additionalElements.length} additional elements for expansion.`);
    //         setExpandedNodes(prevExpandedNodes => {
    //             const newExpandedNodes = new Set([...prevExpandedNodes, ...additionalElements.map(el => el.id)]);
    //             console.log(`Updated expandedNodes state to include new elements.`);
    //             return newExpandedNodes;
    //         });
    
    //         setGraphElements(prevElements => {
    //             const updatedElements = [...prevElements, ...additionalElements];
    //             console.log(`Updated graphElements to include additional elements.`);
    //             return updatedElements;
    //         });
    //     } else {
    //         console.log(`Regular node click detected for node ID: ${element.id}`);
    //         const layer = getCurrentLayer(element.id);
    //         const updatedSelection = new Set([...(selectedNodesByLayer[layer] || []), element.id]);
    
    //         console.log(`Updating selectedNodesByLayer for layer ${layer}.`);
    //         setSelectedNodesByLayer(prevSelectedNodesByLayer => {
    //             const updatedSelectedNodesByLayer = { ...prevSelectedNodesByLayer, [layer]: updatedSelection };
    //             console.log(`selectedNodesByLayer updated for layer ${layer}.`);
    //             return updatedSelectedNodesByLayer;
    //         });
    
    //         const newElements = generateNextLayerNodes(element.id, graph, expandedNodes);
    //         console.log(`Generated ${newElements.length} new elements for regular node selection.`);
    
    //         setGraphElements(prevElements => {
    //             // Optional: filter logic for previous layers could go here
    //             const updatedElements = [...prevElements, ...newElements];
    //             console.log(`Graph elements updated to reflect new selection.`);
    //             return updatedElements;
    //         });
    //     }
    // };
    

// Assuming node ID format is "node-layer-uniqueId"
const getCurrentLayer = (nodeId) => {
    console.log("getCurrentLayer has begun with nodeId:", nodeId);
    
    // Use a regular expression to extract layer information
    // This regex assumes the format "node-layer-uniqueId"
    const layerMatch = nodeId.match(/node-(\d+)-[\w\d]+/);

    if (layerMatch && layerMatch.length > 1) {
        // Successfully extracted layer number from the node ID
        const layer = parseInt(layerMatch[1], 10);
        console.log(`Extracted layer: ${layer} from nodeId: ${nodeId}`);
        return layer;
    } else {
        // Log an error or handle the unexpected format gracefully
        console.error(`getCurrentLayer: Unable to extract layer from nodeId: ${nodeId}`);
        // Return a default layer or handle this scenario appropriately in your application
        return 0; // Assuming layer 0 as a fallback might not be ideal for all applications
    }
};


// // Function to Generate Next Layer Nodes
// const generateNextLayerNodes = (selectedNodeId, graph, expandedNodes) => {
//     console.log("generateNextLayerNodes has begun");

//     if (!graph || !graph[selectedNodeId]) {
//         return [];
//     }

//     // Get connections for the selected node
//     const connections = graph[selectedNodeId];

//     // Generate nodes and edges for these connections
//     const newNodes = connections.map((conn, index) => ({
//         id: `${conn.target}`,
//         type: 'node',
//         data: { label: conn.target },
//         position: { x: 300 * index, y: 200 } // Adjust positioning as needed
//     }));

//     const newEdges = connections.map((conn) => ({
//         id: `${selectedNodeId} | ${conn.target}`,
//         source: selectedNodeId,
//         target: `${conn.target}`,
//         label: `Probability: ${(conn.probability * 100).toFixed(1)}%`
//     }));

//     return [...newNodes, ...newEdges];
// };

const handleNodesChange = useCallback((changes) => {
    console.log("handleNodesChange has begun");

    setGraphElements((els) => {
        // Flag to check if any element has been updated
        let updated = false;
        const newEls = els.map((el) => {
            const change = changes.find((c) => c.id === el.id);
            if (change && change.position && (change.position.x !== el.position.x || change.position.y !== el.position.y)) {
                updated = true; // Mark as updated if positions differ
                return { ...el, position: change.position };
            }
            return el;
        });

        // Only update state if there was an actual update to positions
        return updated ? newEls : els;
    });
}, []);

// // Function to handle node changes (e.g., position changes)
// const handleNodesChange = (nodes) => {
//     console.log("handleNodesChange has begun");
//     console.log("hasNodeBeenSelected:", hasNodeBeenSelected); // Debugging the value
//     if (!hasNodeBeenSelected) return; // Correct way to check if a node has been selected
//     console.log("Updated nodes:", nodes); // Log the nodes array to inspect its contents

//     // Update graphElements with the new positions of nodes
//     setGraphElements(prevElements => prevElements.map(el => {
//         const foundNode = nodes.find(n => n.id === el.id);
//         if (foundNode) {
//             // If the node is found, update its position
//             return { ...el, position: foundNode.position };
//         } else {
//             // If the node is not found, return the element as is
//             return el;
//         }
//     }));
// };

// // Function to handle edge changes
// const handleEdgesChange = (edges) => {
//     console.log("handleEdgesChange has begun");
//     // Here you might want to update the edges in your graphElements state
//     setGraphElements(prevElements => {
//         // Separate nodes and existing edges
//         const existingNodes = prevElements.filter(e => e.id.startsWith('node'));
//         const existingEdges = prevElements.filter(e => e.id.startsWith('edge'));

//         // Update edges with the new edges data
//         const updatedEdges = edges.map(edge => {
//             const existingEdge = existingEdges.find(e => e.id === edge.id);
//             return existingEdge ? { ...existingEdge, ...edge } : edge;
//         });

//         return [...existingNodes, ...updatedEdges];
//     });
// };

// Function to handle new connections/edges
const handleConnect = (connection) => {
    console.log("handleConnect has begun");

    setGraphElements(prevElements => [
        ...prevElements,
        {
            id: `e-${connection.source}-${connection.target}`,
            source: connection.source,
            target: connection.target,
            // Add any additional properties you need for the edge
            // For example, a label with default value or based on some logic
            label: 'New Connection',
            type: 'default', // or any other type you are using
        }
    ]);
};

// const handleLayeredNodeInteraction = (nodeId) => {
//     if(graphElements){
//     console.log("handleLayeredNodeInteraction has begun");

//     // Determine the layer of the node
//     const layer = getCurrentLayer(nodeId);
//     console.log("nodeId in handleLayeredNodeInteraction", nodeId);
//     console.log("layer in handleLayeredNodeInteraction", layer);

//     const nextLayerNodes = generateNextLayerNodes(nodeId, data);
//     console.log("nextLayerNodes in handleLayeredNodeInteraction", nextLayerNodes);

//     setGraphElements(prevElements => [...prevElements, ...nextLayerNodes]);
//     console.log("graphElements in handleLayeredNodeInteraction", graphElements);

//     // Update Redux store as needed
//     dispatch(setCurrentLayer(layer));
// }
// };



// const setCurrentLayer = (layer) => ({
//     type: 'treeExplorer/setCurrentLayer',
//     payload: layer,
// });
// console.log("setCurrentLayer has begun");


// // const loadMoreNodes = useCallback(async () => {
// //     console.log("loadMoreNodes has begun");
// //     try {
// //       // Fetch additional nodes and edges asynchronously
// //       const additionalNodes = await fetchAdditionalNodes(); // Replace with your data fetching function
// //       const additionalEdges = await fetchAdditionalEdges(); // Replace with your data fetching function
  
// //       // Update the state to render new nodes/edges
// //       setGraphElements(prevElements => [
// //         ...prevElements,
// //         ...additionalNodes,
// //         ...additionalEdges,
// //       ]);
// //     } catch (error) {
// //       console.error('Error fetching additional nodes and edges:', error);
// //       // Handle the error as needed
// //     }
// //   }, [graphElements]); // Add any dependencies needed for data fetching, e.g., fetchAdditionalNodes and fetchAdditionalEdges
  
// // Function to fetch additional nodes asynchronously
// const fetchAdditionalNodes = async () => {
// console.log("fetchAdditionalNodes has begun");
// try {
//   if (!graph) {
//     console.log("No valid data or MarkovChain data available.");
//     return [];
//   }

//   // Replace this with your logic to extract node names and connections
//   const nodeNames = Object.keys(graph);

//   // Simulate fetching additional nodes based on the selectedNode
//   const additionalNodes = nodeNames.map((name, index) => ({
//     id: name,
//     type: 'node',
//     data: { label: name },
//     position: { x: 100 * index, y: 100 },
//   }));

//   // Process the data if needed

//   return additionalNodes;
// } catch (error) {
//   console.error('Error fetching additional nodes:', error);
//   // Handle the error as needed
//   throw error; // Re-throw the error to propagate it
// }
// };

// // Function to fetch additional edges asynchronously
// const fetchAdditionalEdges = async () => {
// console.log("fetchAdditionalEdges has begun");
// try {
//   if (!graph) {
//     console.log("No valid data or MarkovChain data available.");
//     return [];
//   }

//   // Replace this with your logic to extract node names and connections
// //   markovChainData = data;
//   const nodeNames = Object.keys(graph);

//   // Simulate fetching additional edges based on the selectedNode
//   const additionalEdges = [];
//   nodeNames.forEach((source) => {
//     const connections = graph[source] || [];
//     const sortedConnections = connections.sort((a, b) => b.probability - a.probability);

//     sortedConnections.slice(0, 5).forEach(({ target, probability }) => {
//       if (source !== target) {
//         const probabilityPercentage = (probability * 100).toFixed(1);
//         additionalEdges.push({
//           id: `${source} | ${target}`,
//           type: 'default',
//           source,
//           target,
//           label: `Probability: ${probabilityPercentage}%`,
//         });
//       }
//     });
//   });

//   // Process the data if needed

//   return additionalEdges;
// } catch (error) {
//   console.error('Error fetching additional edges:', error);
//   // Handle the error as needed
//   throw error; // Re-throw the error to propagate it
// }
// };

// const handleNodesChange = useCallback((changes) => {
//     console.log("handleNodesChange has begun");

//     setGraphElements((els) => els.map((el) => {
//         // Find the change object for the current element
//         const change = changes.find((c) => c.id === el.id);
//         // Safely check if there's a matching change and it has a valid position before updating
//         if (change && change.position) {
//             return { ...el, position: change.position };
//         }
//         // If there's no matching change or position is not valid, return the element unchanged
//         return el;
//     }));
// }, []);



// Implementing keyboard navigation for the dropdown
// const handleDropdownKeyDown = useCallback((event) => {
//     console.log("handleDropdownKeyDown has begun");

//     switch (event.key) {
//       case 'ArrowUp':
//         // Handle Up Arrow key press
//         break;
//       case 'ArrowDown':
//         // Handle Down Arrow key press
//         break;
//       // Add more key handling cases as needed
//       default:
//         // Handle other key presses
//     }
//   }, []);
  

        if (status === 'loading') return <LoadingSpinner />;
        if (status === 'error') return <div>Error: {error.message}</div>;
        if (data.length === 0) {
            console.log('Still loading from data.length:', data);
            return <LoadingSpinner />; // Display a loading spinner or loading indicator
        }
        if (processedData.markovChainData === "") {
            console.log('Still loading from processData.markovChainData:', processedData.markovChainData);
            return <LoadingSpinner />; // Display a loading spinner or loading indicator
        }
        if (processedData?.possibleValues === "") {
        console.log('Still loading from processData.possibleValues:', processedData.possibleValues);
        return <LoadingSpinner />; // Display a loading spinner or loading indicator
          }

        if (!processedData.possibleValues === "") {
            console.log('possibleValues have loaded:', processedData.possibleValues);
        }
        if (currentLayer !== null) {
            console.log('CurrentLayer is:', currentLayer);
        } else {
            console.log('Current Layer is null.');
            return <LoadingSpinner />;
          }
          console.log("At the Return");
        //   console.log("Current Graph Elements:", graphElements);

return (
            <div className='TreeExplorer-Container'>
            <h1 className="h1 banner">Tree Explorer</h1>
        <Select
            data={processedData?.possibleValues || []}
            onChange={handleNodeSelect}
            placeholder="Select a node"
            searchable
            nothingFound="No options"
        />
        <div className="flow-container">
        <ReactFlow
            elements={graphElements}
            defaultEdges={graphElements.filter(e => e.type === 'default')}
            defaultNodes={graphElements.filter(e => e.type === 'node')}
            onNodesChange={handleNodesChange}
            // onKeyDown={handleDropdownKeyDown}
            // onEdgesChange={handleEdgesChange}
            onConnect={handleConnect} // put this back in
            // onElementClick={handleNodeClick} // Integrating the node click handler
            // onElementClick={(event, element) => handleNodeClick(event, element)}
            onNodeDragStart={(event, node) => console.log(`Dragging node: ${node.id}`)}
            onSelectionChange={onSelectionChange} // Use the onSelectionChange prop here
            nodeTypes={{ customNode: CustomNode }}
            className="reactflow"
            minZoom={0.5}
            maxZoom={2}
            panOnScroll={true}
            panOnDrag={true}
        >
            <Background variant="dots" gap={26} size={2} color={'#ebe5df'}/>
            <Controls />
            <MiniMap
                nodeStrokeColor={() => '#2c294b'}
                nodeColor={() => '#fff'}
                nodeBorderRadius={2}    
            />
            <Handle type="target" position="left" />
            <Handle type="source" position="right" />
            {/* <UndoRedoControls /> */}
        </ReactFlow>
    </div>
    </div>
);
}

export default TreeExplorer;

