import React, { useState, useEffect } from 'react';

const OrganizationPicker = () => {
  const [orgs, setOrgs] = useState([]);
  const [activeOrgID, setActiveOrgID] = useState(null);

  const handleLocalStorageUpdate = () => {
    const orgsData = JSON.parse(localStorage.getItem('Organizations') || '[]');
    setOrgs(orgsData);

    if (orgsData.length > 0 && !activeOrgID) {
      const initialActiveOrgID = orgsData[0].OrgID;
      localStorage.setItem('ActiveOrgID', initialActiveOrgID);
      setActiveOrgID(initialActiveOrgID);
    }
  };

  useEffect(() => {
    // Initially fetch data
    handleLocalStorageUpdate();

    // Listen for updates
    window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);

    // Cleanup
    return () => {
      window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
    };
  }, [activeOrgID]); // Adding activeOrgID as dependency to re-run when it changes

  const handleOrgChange = (newOrgID) => {
    localStorage.setItem('ActiveOrgID', newOrgID);
    setActiveOrgID(newOrgID);
  };

  if (!Array.isArray(orgs) || orgs.length === 0) {
    return <p>Loading organizations...</p>;
  }

  return (
    <select className='orgpicker' value={activeOrgID} onChange={e => handleOrgChange(e.target.value)}>
      {orgs.map(org => (
        <option key={org.OrgID} value={org.OrgID}>
          {org.OrgName}
        </option>
      ))}
    </select>
  );
};

export default OrganizationPicker;

// import React, { useState, useEffect } from 'react';

// const OrganizationPicker = () => {
//   const initialOrgs = JSON.parse(localStorage.getItem('Organizations') || '[]');
//   const [orgs, setOrgs] = useState(initialOrgs);
//   const [activeOrgID, setActiveOrgID] = useState(null);

//   useEffect(() => {
//     const orgsData = JSON.parse(localStorage.getItem('Organizations') || '[]');
//     console.log("JustUpdated. useEffect 1: Fetched orgsData:", orgsData);

//     if (JSON.stringify(orgsData) !== JSON.stringify(orgs)) {
//       console.log("useEffect 1: Updating orgs state.");
//       setOrgs(orgsData);
//     }
//   }, [orgs]);

//   useEffect(() => {
//     console.log("useEffect 2: orgs:", orgs, "activeOrgID:", activeOrgID);
//     if (orgs.length > 0 && activeOrgID === null) {
//       const initialActiveOrgID = orgs[0].OrgID;
//       console.log("useEffect 2: Setting initialActiveOrgID:", initialActiveOrgID);
//       localStorage.setItem('ActiveOrgID', initialActiveOrgID);
//       setActiveOrgID(initialActiveOrgID);
//     }
//   }, [orgs, activeOrgID]);

//   // In the component where you update localStorage after successful login
//     const updateLocalStorage = (data) => {
//         localStorage.setItem('Organizations', JSON.stringify(data));
//         window.dispatchEvent(new Event('localStorageUpdate'));
//     };
  
//   // In the OrganizationPicker component
//   useEffect(() => {
//     const handleLocalStorageUpdate = () => {
//       const orgsData = JSON.parse(localStorage.getItem('Organizations') || '[]');
//       setOrgs(orgsData);
//       // Logic for setting activeOrgID...
//     };
    
//     // Initially fetch data
//     handleLocalStorageUpdate();
  
//     // Listen for updates
//     window.addEventListener('localStorageUpdate', handleLocalStorageUpdate);
  
//     // Cleanup
//     return () => {
//       window.removeEventListener('localStorageUpdate', handleLocalStorageUpdate);
//     };
//   }, []);

//   const handleOrgChange = (newOrgID) => {
//     console.log("handleOrgChange: Setting new Org ID:", newOrgID);
//     localStorage.setItem('ActiveOrgID', newOrgID);
//     setActiveOrgID(newOrgID);
//   };

//   console.log("Render: orgs:", orgs, "activeOrgID:", activeOrgID);

//   if (!Array.isArray(orgs) || orgs.length === 0) {
//     return <p>Loading organizations...</p>;
//   }

//   return (
//     <select value={activeOrgID} onChange={e => handleOrgChange(e.target.value)}>
//       {orgs.map(org => (
//         <option key={org.OrgID} value={org.OrgID}>
//           {org.OrgName}
//         </option>
//       ))}
//     </select>
//   );
// };

// export default OrganizationPicker;