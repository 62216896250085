import React, { useState, useEffect, useRef, useCallback } from 'react';
// import React, { useState, useEffect, StrictMode, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryProvider } from './components/QueryProvider';
import Sidebar from './Sidebar';
import ErrorBoundary from './components/ErrorBoundary';
import Auth from './components/Auth';
import SignUp from './components/SignUp';
import ProtectedRoute from './components/ProtectedRoute'; // Import your ProtectedRoute component

// import { AuthProvider } from './components/AuthContext';
import SEO from './components/SEO';
import { ResponsiveWrapper } from './components/ResponsiveWrapper';
import { MantineProvider } from '@mantine/core';

// import OutcomesAndMilestones from './pages/OutcomesAndMilestones';
// import Experiments from './pages/Experiments';
// import Admin from './pages/Admin';
// import Tagging from './pages/Tagging';
import HappyPath from './pages/HappyPath';
import ModelOutput from './pages/ModelOutput';
import PathAnalyzer from './pages/PathAnalyzer';
// import Path from './pages/PathAnalyzer';
// import BetaRequest from './pages/BetaRequest';
import Benchmarks from './pages/Benchmarks';
import FeatureInsights from './pages/FeatureInsights';
import Onboarding from './pages/Onboarding';
import Admin from './pages/Admin';
import Conversations from './pages/Conversations';
import JourneyFlow from './pages/JourneyFlow';
import { Provider } from 'react-redux';
import store from './redux/store';
import TreeExplorer from './pages/TreeExplorer';
import Funnel from './pages/Funnel';
// import axios from 'axios';
import SankeyDiagram from './pages/Sankey';
import Weekly from './pages/Weekly';



const routeConfig = {
  auth: { component: Auth, title: 'Authentication', description: 'User authentication page', url: '/auth' },
  signup: { component: SignUp, title: 'SignUp', description: 'Create a new Teraace Account', url: '/signup' },
  // modelOutput: { component: ModelOutput, title: 'Model Data', description: 'Description for Seeing Data', url: '/modeloutput' },
  benchmarks: { component: Benchmarks, title: 'Benchmarks', description: 'Description for Benchmarks', url: '/benchmarks' },
  weekly: { component: Weekly, title: 'Weekly', description: 'Description for Weekly', url: '/weekly' },
  funnel: { component: Funnel, title: 'Funnel', description: 'Conversion Funnel of Critical Pages', url: '/funnel' },
  happyPath: { component: HappyPath, title: 'Happy Path', description: 'Description for Happy Path', url: '/happypath' },
  treeExplorer: { component: TreeExplorer, title: 'Tree Explorer', description: 'Description for Tree Explorer', url: '/treeexplorer' },
  conversations: { component: Conversations, title: 'Conversations', description: 'Conversations', url: '/conversations' },
  pathAnalyzer: { component: PathAnalyzer, title: 'Path Analyzer', description: 'Description for Path Analyzer', url: '/pathanalyzer' },
  featureInsights: { component: FeatureInsights, title: 'Feature Insights', description: 'Description for Feature Insights', url: '/featureinsights' },
  journeyFlow: { component: JourneyFlow, title: 'Journey Flow', description: 'Description for Journey Flow', url: '/journeyflow' },
  // sankey: { component: SankeyDiagram, title: 'Sankey', description: 'Description for Sankey', url: '/sankey' },
  onboarding: { component: Onboarding, title: 'Onboarding', description: 'Description for Onboarding', url: '/onboarding' },
  admin: { component: Admin, title: 'Admin', description: 'Description for Admin', url: '/admin' },
  // betaRequest: { component: BetaRequest, title: 'Beta Request', description: 'Description for Beta Request', url: '/betarequest' },
};

const Content = React.memo(() => {
  // console.log("Content component rerendered");
  // const [currentPage, setCurrentPage] = useState('happyPath'); // Example page state
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', ''));
  const { title, description, url } = routeConfig[currentPage] || {};
  const pages = Object.keys(routeConfig).map(key => ({
    name: routeConfig[key].title,
    route: routeConfig[key].url,
  }));
  const [userRole, setUserRole] = useState('');

  // const [orgs, setOrgs] = useState([]);
  // const [activeOrgID, setActiveOrgID] = useState(undefined);

  // console.log('App component rendering'); // Add this log
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const prevImageNumbersRef = useRef(new Set());

  const getRandomBackgroundImage = () => {
    // console.log('getRandomBackgroundImage called');
  
    let imageNumber;
    let attempts = 0; // To prevent infinite loop in case all images are already shown
  
    do {
      imageNumber = Math.floor(Math.random() * 137) + 1;
      attempts++;
    } while (prevImageNumbersRef.current.has(imageNumber) && attempts < 137);
  
    // If we've shown all images, reset the Set
    if (attempts === 137) {
      prevImageNumbersRef.current.clear();
    }
  
    prevImageNumbersRef.current.add(imageNumber);
    // console.log('prevImageNumbersRefs', prevImageNumbersRef);

    const paddedNumber = String(imageNumber).padStart(3, '0');
    return `/assets/bg/Teraace-bg-${paddedNumber}.webp`;
  };

  const [backgroundImage, setBackgroundImage] = useState(() => getRandomBackgroundImage());
  const [nextBackgroundImage, setNextBackgroundImage] = useState('');

  const backgroundImageStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  };

  const onNavigate = (route) => {
    // console.log('onNavigate called');
    console.log('onNavigate called with route:', route);
    // console.log(`Old Background image set to: ${backgroundImage}`);
    setCurrentPage(route);
    // console.log('Current page after:', currentPage);
    setBackgroundImage(getRandomBackgroundImage()); // Update background image on navigation
    // console.log(`New Background image set to: ${backgroundImage}`);
  };

  const navigate = useNavigate();

  const preloadImage = (url) => {
    // console.log(`Preloading: ${url}`);
    const img = new Image();
    img.src = url;
    img.onload = () => console.log(`Image preloaded: ${url}`);
    img.onerror = () => console.log(`Error preloading: ${url}`);
  };
  
  // const [nonce, setNonce] = useState('');

  // const fetchNonce = async () => {
  //     try {
  //       console.log('Fetching new NONCE from:', window.NONCE);
  //       const response = await axios.get('/get-nonce');
  //         console.log('Getting new NONCE:', response);
  //         const newNonce = response.data.nonce;
  //         console.log('Getting new newNonce:', newNonce);
  //         setNonce(newNonce);
  //         updateNonceMetaTag(newNonce);
  //         window.NONCE = newNonce; // Set the nonce on the window object
  //         console.log('Updated window.NONCE:', window.NONCE);
  //     } catch (error) {
  //         console.error('Error fetching nonce:', error);
  //     }
  // };

  // const updateNonceMetaTag = (newNonce) => {
  //     const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
  //     console.log('Getting new nonceMetaTag:', nonceMetaTag);
  //     if (nonceMetaTag) {
  //         nonceMetaTag.setAttribute('content', newNonce);
  //         console.log('Setting nonceMetaTag:', newNonce);
  //     }
  // };

  // useEffect(() => {
  //   console.log('Fetch new Nonce');
  //     fetchNonce();
  // }, [setCurrentPage]); // Dependency array can be adjusted based on when you need to fetch the nonce


  const preloadNextBackground = useCallback(() => {
    // console.log('preloadNextBackground called');

    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        const nextBg = getRandomBackgroundImage();
        preloadImage(nextBg);
        setNextBackgroundImage(nextBg);
        console.log(`nextBackgroundImage set to: ${nextBg}`);
      });
    } else {
      const nextBg = getRandomBackgroundImage();
      preloadImage(nextBg);
      setNextBackgroundImage(nextBg);
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect triggered to set BackgroundImage'); // Debug line
    // console.log(`Background image set to: ${backgroundImage}`);
    console.log(`currentPage: ${currentPage}, nextBackgroundImage: ${nextBackgroundImage}`); // Debug line
    // console.log('currentPage changed:', currentPage);

    // Set the current background to the preloaded one only if nextBackgroundImage is not empty
    if (nextBackgroundImage) {
      setBackgroundImage(nextBackgroundImage);
    }
  
    // Preload the subsequent image for the next route
    preloadNextBackground();
    // console.log('backgroundImage after set:', backgroundImage);
    // console.log('nextbackgroundImage after set:', nextBackgroundImage);

  }, [currentPage]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    // console.log('Token from localStorage:', token);
    // console.log('isLoggedIn before:', isLoggedIn);
    if (token || isLoggedIn) {
      setIsLoggedIn(true);
  
      if (location.pathname === '/auth') {
        navigate("/happypath");
      }
    } else {
      // If the user is not logged in and doesn't have a token, redirect to /auth
      if (location.pathname !== '/signup') {
        navigate("/auth");
      }
    }
    // console.log('isLoggedIn after:', isLoggedIn);

  }, [location.pathname, navigate]);
  
  useEffect(() => {
    return () => {
      console.log('App component unmounted');
    };
  }, []);

    // useEffect(() => {
    //   // Directly log what's in localStorage for Organizations
    //   const orgsRawData = localStorage.getItem('Organizations');
    //   console.log("Directly from localStorage:", orgsRawData);
      
    //   // Fetch and parse organizations from localStorage
    //   const orgsData = JSON.parse(orgsRawData || '[]');
    //   console.log("Parsed Organizations from localStorage:", orgsData);
      
    //   // Update state and log
    //   setOrgs(orgsData);
    //   console.log("After setOrgs: ", orgs);
      
    //   // Directly log what's in localStorage for ActiveOrgID
    //   const activeOrgRawData = localStorage.getItem('ActiveOrgID');
    //   console.log("Directly from localStorage:", activeOrgRawData);
    
    //   // Logic to set activeOrgID
    //   let initialActiveOrgID = activeOrgRawData;
    //   if (!initialActiveOrgID && orgsData.length > 0) {
    //     initialActiveOrgID = orgsData[0].OrgID;
    //     localStorage.setItem('ActiveOrgID', initialActiveOrgID);
    //   }
      
    //   // Update state and log
    //   setActiveOrgID(initialActiveOrgID);
    //   console.log("After setActiveOrgID: ", activeOrgID);
    // }, []);
    
    // // This runs only once when the component mounts
    // useEffect(() => {
    //   const orgsData = JSON.parse(localStorage.getItem('Organizations') || '[]');
    //   setOrgs(orgsData);
    // }, []);

    // // This runs whenever `orgs` change
    // useEffect(() => {
    //   if (orgs.length > 0 && !activeOrgID) {
    //     const firstOrgID = orgs[0].OrgID;
    //     localStorage.setItem('ActiveOrgID', firstOrgID);
    //     setActiveOrgID(firstOrgID);
    //   }
    // }, [orgs, activeOrgID]);

    const canAccessFeature = (allowedRoles) => {
      const userRole = localStorage.getItem('UserRole');
      const access = allowedRoles.includes(userRole);
      console.log(`Access to feature for ${userRole}: ${access}`);
      return access;
    };
    

return (
    <div className="relative min-h-screen scroll" style={backgroundImageStyle}>
      <div className="outerwrapper">
    <div className="flex-container">
    {/* orgs={orgs} activeOrgID={activeOrgID} handleOrgChange={handleOrgChange} */}
        {isLoggedIn && <Sidebar canAccessFeature={canAccessFeature} className="sidebar" pages={pages} onNavigate={onNavigate}/>}
        <div className="innerwrapper">
        {isLoggedIn ? (
            <>
                {console.log('Is user logged in:', isLoggedIn)}
                {console.log('Rendering logged-in components')}
                <ResponsiveWrapper>
                    <SEO title={title} description={description} url={url} />
                    <Routes>
                    {console.log('Rendering defined routes')}
                        <Route path="/auth" element={<Auth />} nonce={window.NONCE} />
                        {/* <Route path="/modeloutput" element={<ModelOutput />} /> */}
                        <Route path="/benchmarks" element={<Benchmarks />} />
                        <Route path="/weekly" element={<Weekly />} />
                        <Route path="/funnel" element={<Funnel />} />
                        <Route path="/happypath" element={<HappyPath />} />
                        <Route path="/conversations" element={<Conversations />} />
                        <Route path="/treeexplorer" element={<TreeExplorer />} />
                        <Route path="/pathanalyzer" element={<PathAnalyzer />} />
                        <Route path="/featureinsights" element={<FeatureInsights />} />
                        <Route path="/journeyflow" element={<JourneyFlow />} />
                        {/* <Route path="/sankey" element={<SankeyDiagram />} /> */}
                        <Route path="/admin" element={<Admin />} />
                        {/* {canAccessFeature(['SuperAdmin']) && (
        <Route path="/onboarding" element={<ProtectedRoute allowedRoles={['SuperAdmin']}><Onboarding /></ProtectedRoute>} />
      )} */}
      {canAccessFeature(['superadmin']) && (
                        <Route path="/onboarding" element={<Onboarding />} />
                        )}

                        {/* <Route path="/betarequest" element={<BetaRequest />} /> */}
                        <Route path="*" element={<Navigate to="/happypath" />} />
                    </Routes>
                </ResponsiveWrapper>
            </>
        ) : (
            <>
                {console.log('Is user logged in:', isLoggedIn)}
                {console.log('Rendering logged-out components')}
                <ResponsiveWrapper>
                    <Routes>
                    {/* {console.log('Rendering /betarequest')} */}
                        {/* <Route path="/betarequest" element={<BetaRequest />} /> */}
                        {console.log('Rendering /auth')}
                        <Route path="/signup" element={<SignUp />} nonce={window.NONCE} />
                        <Route path="/auth" element={<Auth />} nonce={window.NONCE} />
                        {console.log('Rendering *')}
                        <Route path="*" element={<Navigate to="/auth" />} />
                    </Routes>
                </ResponsiveWrapper>
            </>
        )}
        </div>
      </div>          
      </div> 
    </div>
  );
 });

const App = () => {
  
  return (
    // <StrictMode>
    <ErrorBoundary>
    <Provider store={store}>
    <QueryProvider>
    <MantineProvider theme={{ /* your theme settings */ }}>
    {/* <AuthProvider> */}
    <Router>
    {/* <Router onStateChange={({ location, action }) => console.log(action, location)}> */}
        <Content />
        </Router>
    {/* </AuthProvider> */}
    </MantineProvider>
    </QueryProvider>
    </Provider>
    </ErrorBoundary>
    // </StrictMode>
  );
};

export default App;
